.notification-modal {
  .modal-dialog {
    margin: 8px auto;
    width: calc(100% - 16px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.3);

    .modal-content {
      border-radius: 0;
      border: none;

      .modal-header {
        padding: 20px 24px;
        border-bottom: 1px solid #979797;

        .modal-title {
          font-family: inherit;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          color: $red-monza;
          margin: auto;

          .modal-title-text {
            word-break: break-word;
          }
        }
      }

      .modal-body {
        overflow: auto;
        padding: 24px;
        font-family: inherit;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $darkest-grey;
        text-align: center;

        .modal-body-text {
          display: inline-block;
          text-align: left;
          width: 100%;
          word-break: break-word;
        }
      }

      .modal-footer {
        border: none;
        background-color: $light-grey;

        .notification-modal__close-button {
          width: 100%;
          height: 48px;
          color: $asphalt;
          font-family: inherit;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.4px;
          text-align: center;
          background-color: $color-sparkasse-concrete;
          border: 2px solid $asphalt;

          @media (min-width: $breakpoint-mobile-landscape) {
            width: 160px;
          }
        }

        .notification-modal__confirm-button {
          width: 100%;
          height: 48px;
          color: $color-sparkasse-monza;
          font-family: inherit;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.4px;
          text-align: center;
          background-color: $color-sparkasse-concrete;
          border: 2px solid $color-sparkasse-monza;

          @media (min-width: $breakpoint-mobile-landscape) {
            width: 160px;
          }
        }

        .no-border_button {
          border: none;
        }

        .contenitore-pulsanti {
          flex: 1;
          margin: 0;
        }
      }
    }
  }
}

.pop-up {
  &.pop-up-secondary {
    .modal-header {
      .modal-title {
        color: $darkest-grey !important;
      }
    }
  }

  &.pop-up-primary {
    .modal-header {
      .modal-title {
        color: $red-monza !important;
      }
    }
  }
}

.confirmModal-width {
  max-width: 625px;
}

.modal-footer-buttons-space-between {
  .modal-footer {
    justify-content: space-between;
  }
}

.modal-header-primary {
  .modal-header {
    background-color: $color-sparkasse-monza;

    .modal-close-icon {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 44px;
      height: 44px;
    }

    .modal-title-text {
      margin: 0 44px;
      color: $white;
    }
  }
}