.footerView-section-movement {
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  color: #707070;
}

.button-section-movement {
  display: flex;
  flex-direction: column;
  // border: 1px solid #707070;
  height: 70%;
  width: 30%;
  align-items: flex-end;
  justify-content: center;
  border-radius: 4px;
  margin-right: 10px;
}

.txtButton-onRight {
  align-items: start;
}
