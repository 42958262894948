.multiSca-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $darkest-grey;
  margin-bottom: 5px;
}

.multiSca-message {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $darkest-grey;
  margin-bottom: 10px;
}

.multiSca-section {
  margin-left: 5px;
}

.message-left {
  margin-left: 43px;
}