.contact-divider {
  margin-top: 1%;
  font-weight: bold;
  color: $red-monza;
  margin-bottom: 1%;
}

.rubrica {
  .card-contact-view--body-view--iban {
    display: flex;
    flex-direction: row;
    width: 50%;
  }

  .card-contact-view--body-view--beneficiario {
    display: flex;
    flex-direction: row;
  }

  .card-contact-view--body-view--description {
    display: flex;
    flex-direction: row;
    flex: 3;
    margin-left: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .card-contact-view--description-icon {
    display: flex;
    flex-direction: column;
    padding: 0 1%;
  }

  .card-view--body-view--additional-info-value-rubrica {
    color: $black;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
  }

  .margin-card {
    margin-left: 16px;

    @media (min-width: $breakpoint-mobile-landscape) {
      margin-left: 0px;
    }
  }

  .badge-width {
    width: 44px;
    margin-top: 20px;
    margin-left: 16px;
    margin-right: 16px;
    display: none;

    @media (min-width: $breakpoint-mobile-landscape) {
      display: block;
    }
  }

  .detail-width {
    flex: 1;
  }

  .beneficiario-icon-centered {
    padding: 15%;
  }

  .card-view-contact--body-view--full-row {
    margin-right: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &>div {
      width: 100%;
      margin-bottom: 16px;

      @media (min-width: $breakpoint-mobile-landscape) {
        width: calc(50% - 24px);
        margin-right: 24px;
      }
    }
  }

  .card-contact-view--body-view--telefono {
    display: flex;
    flex-direction: row;
  }

  .vertical-center {
    margin: auto 0;
  }

  .d-flex-wrap {
    display: block;

    @media (min-width: $breakpoint-mobile-landscape) {
      display: flex;
    }
  }

  .card-view--body-view--full-row--inner div {
    flex: none;

    @media (min-width: $breakpoint-mobile-landscape) {
      width: 100%;
    }
  }

  .card-view--body-view--full-row--inner-rubrica div {
    margin-bottom: 1%;
    margin-right: 5px;

    @media (min-width: $breakpoint-tablet) {
      width: 100%;
    }

    @media (min-width: $breakpoint-mobile-landscape) {
      width: 100%;
    }
  }

  .card-contact-view--body-view--vertical-cta {
    background-color: $light-grey;
    margin-top: -4px !important;
    justify-content: center;
    max-width: 44px;

    .vertical-cta-items {
      display: flex;
      flex-direction: column;

      .vertical-cta-item {
        margin: 6px 0;
        cursor: pointer;
        display: flex;
        border: none;
        background-color: inherit;

        img {
          width: 32px;
        }

        svg {
          margin: auto;
        }
      }

      .vertical-cta-item.vertical-cta-item-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .card-bozze-wrapper-margin-bottom {
    overflow: hidden;
    margin-bottom: 3%;
    -webkit-box-shadow: $medium-shadow;
    -moz-box-shadow: $medium-shadow;
    box-shadow: $medium-shadow;
    max-width: calc(100vw - 32px);
  }

  .font-size-16 {
    font-size: 16px;
  }

  .justify-space-between {
    justify-content: space-between;
  }

  .w-80 {
    width: 80%;
  }
}

.contact-row {
  flex-wrap: wrap;

  &>div {
    flex: 1 0 48%;
    //max-width: 50%;
  }
}