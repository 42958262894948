.custom-table {
  .table {
    thead {
      th {
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: $darkest-grey;
      }

      tr {
        border-bottom: 1px solid #979797;

        .compact-header {
          padding: 10px 10px 10px 0;
        }
      }
    }

    tbody {
      tr {
        height: 48px;
        border-bottom: 1px solid $medium-dark-grey;

        .compact {
          padding: 0;
        }

        &.rata {
          height: 44px;
          border-left: 4px solid transparent;

          &.pagata {
            border-left-color: $chartVariant11;
          }

          &.mora {
            border-left-color: $chartVariant6;
          }

          &.residua {
            border-left-color: $asphalt;
          }

          &.avvisata {
            border-left-color: $chartVariant16;
          }

          &.ant {
            border-left-color: #2165f9;
          }
        }
      }

      .no-items {
        td {
          padding: 8px;
          text-align: center;
        }
      }

      .no-items:hover {
        background-color: transparent;
      }
    }

    td {
      vertical-align: middle;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
      padding-top: 0px;
      padding-bottom: 0px;

      .compact {
        height: auto;
        width: auto;
      }
    }
  }

  .shrink {
    word-break: break-word;
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;

    .input-box {
      justify-content: flex-end;
    }
  }

  .bold-text {
    font-weight: bold !important;
  }

  .small-header {
    font-size: 1px !important;
  }
}

.accordion-row {
  .custom-table {
    margin: -14px -10px 0 -14px;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.fondi-paperless-intestazione {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: $black;
}

.fondi-paperless-rapporti {
  margin-left: 12px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.33;
  color: $black;
}