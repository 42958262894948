.box-upload {
  background-color: $light-grey-alternative;
  border: dashed 2px $darker-grey !important;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;

  &.box-upload-disabled {
    opacity: 0.5;
  }

  &.dragged-over {
    border: 2px solid;
  }

  #box-upload-label {
    position: relative;

    &.dragged-over {
      border: 1px solid $light-grey-alternative;
    }

    #box-upload-file {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      display: block;
      cursor: pointer;

      &:disabled {
        cursor: unset;
      }
    }
  }
}

.upload-area {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.box-upload-label {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 10px;
  margin-bottom: unset;
}