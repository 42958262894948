// import Colors from './generated/colors'

.generalView-1ClickExpanded {
  color: $white;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  // marginLeft: 90;
  border: 1px solid black;
}

.modalView-1ClickExpanded {
  color: $white;
  background-color: $black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.containerView-1ClickExpanded {
  color: $black;
  background-color: $white;
  border-radius: 2;
  width: 380px;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  // border: 1px solid black;
  box-shadow: .5px .5px .5px .5px #A9A9A9;
  right: 0;
  top: 70;
}

.bodyView-1ClickExpanded {
  color: $black;
  margin-top: -30px;
  width: 100%;
}