.autoCompleteContainer {
  position: relative;
  text-align: left;
  width: 100%;
  border-radius: 0;
}

.autoComplete-searchWrapper {
  position: relative;
  border: none;
  padding: 0;
  border-radius: 0;
  min-height: 28px;
}

.input-field-search-box {
  width: auto;
}

.autoCompleteContainer input {
  border: none;
  margin-top: 3px;
  background: transparent;
}

.autoCompleteContainer input:focus {
  outline: none;
}

.closeIcon {
  font-size: 12px;
  float: right;
  margin-left: 2px;
  cursor: pointer;
  width: 15px;
}

.autoComplete-optionListContainer {
  position: absolute;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  z-index: 2;
}

.autoCompleteContainer ul {
  display: block;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: hidden;
}

.autoCompleteContainer li {
  padding: 10px 10px;
}

.autoCompleteContainer li:hover {
  background: #f6f6f6;
  color: #333333;
  cursor: pointer;
}

.autoComplete-highlightOption {
  background: #e4e4e4;
  color: #333333;
}

.autoComplete-selectedOption {
  background: #f6f6f6;
  color: #333333;
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}

.notFound {
  padding: 10px;
  display: block;
}

.singleSelect {
  padding-right: 20px;
}

.autoComplete-optionsContainer {
  display: flex;
  flex-direction: column;
}

.autoComplete-optionsContainer .bold {
  font-weight: 500;
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.autoComplete-optionsContainer .light-text {
  color: #9d9d9d;
}

//Per impedire a voiceOver di leggere i marker della lista
ul.groupped-options-list li::marker {
  list-style: none;
}

.groupped-options-list {
  display: block;
  margin: 0;
  padding: 0;
  max-height: calc(70vh - 60px - 64px);
  overflow-y: auto;
}

.groupped-options-list-header {
  padding: 8px 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: $color-sparkasse-monza;
}

.groupped-options-container {
  display: flex;
  flex-direction: column;

  &+.groupped-options-container {
    margin-top: 8px;
  }
}

.groupped-options-header {
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 60px;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  background-color: $mercury;
}

.groupped-options-item {
  display: flex;
  flex-direction: row;
  margin: 2px 0 0 0;
  padding: 12px 8px;
  background-color: #ffffff;

  &:last-child {
    box-shadow: $light-shadow;
  }

  &:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }

  &.selectedOption {
    background: #f6f6f6;
    color: #333333;
  }

  .groupped-options-item-icon {
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: 44px;
  }

  .groupped-options-item-values {
    flex: 1;

    &>span {
      display: block;

      &:first-child {
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        color: $dark-grey-accessibility;
      }

      &:not(:first-child) {
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        color: #181818;
      }
    }
  }

  .groupped-options-item-arrow {
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: 44px;
  }
}

.groupped-options-container .item:last-child {
  border-bottom: none;
}

.groupped-options-container .item:hover {
  background: #f6f6f6;
  color: #333333;
  cursor: pointer;
}

.groupped-options-container .disabled {
  cursor: default !important;
  background: #ffffff !important;
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;

  &.bg-grey {
    background-color: #e3e3e3 !important;
  }
}

.groupped-options-container>span {
  padding: 10px;
  border-bottom: 2px solid #f6f6f6;
  height: 60px;
}

.groupped-options-container .bold {
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.groupped-options-container .icon {
  display: flex;
  flex-direction: column;
}

.groupped-options-container .icon img {
  height: 35px !important;
  width: auto;
  position: inherit !important;
}

.red {
  font-weight: 600;
  color: #e00125;
}

.autoComplete-search-view {
  display: flex;
  flex-direction: column;
  height: 44px;
  width: 100%;
  justify-content: center;

  @media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
    margin-left: 0px !important;
  }

  @media (min-width: $breakpoint-tablet) {
    margin-right: 0;
  }

  &--input-group.input-group {
    height: 100%;

    .autoComplete-search-view--form-control.form-control {
      border-radius: 0;
      display: inherit;
      color: #ffffff;

      background: url(../../assets/icons/search-white.svg) no-repeat;
      padding-right: 50px;
      background-position: right 10px center;
      background-size: 30px;
      background-color: #e00125;
      border: 1px solid #e00125;
      height: 44px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      @media (min-width: 639px) and (max-width: 768px) {
        margin-left: 0px !important;
      }

      @media (min-width: 769px) and (max-width: 1023px) {
        margin-left: 0px !important;
      }
    }

    .autoComplete-search-view--form-control.form-control:focus {
      outline: none !important;
      border: 1px solid #e00125;
      box-shadow: none;
    }

    .custom-placeholder::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #ffffff;
    }

    .custom-placeholder:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ffffff;
      opacity: 1;
    }

    .custom-placeholder::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ffffff;
      opacity: 1;
    }

    .custom-placeholder:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ffffff;
    }

    .input-group-append {
      margin-left: auto;

      img {
        height: 40px;
        margin-left: 10px;
      }

      display: inherit;

      @media (min-width: $breakpoint-desktop) {
        display: none;
        margin-left: inherit;
      }
    }
  }

  &--input-group.force-display {
    .autoComplete-search-view--form-control.form-control {
      display: inherit;
      background: transparent;
      color: #ffffff;
      z-index: 1;
      height: 47px;
      margin-left: -80px;

      @media (min-width: $breakpoint-mobile-landscape) {
        margin-left: -330px;
      }
    }
  }
}

.autoComplete-popOver {
  width: 400px;
  max-width: calc(100vw - 32px - 44px);
  border: 0;
  border-radius: 0 !important;
  box-shadow: $heavy-shadow;

  .popover-header::before {
    display: none;
  }

  &[x-placement^="top"] {
    .arrow::after {
      border-top-color: #e00125;
    }
  }

  &[x-placement^="right"] {
    .arrow::after {
      border-right-color: #e00125;
    }
  }

  &[x-placement^="bottom"] {
    .arrow::after {
      border-bottom-color: #e00125;
    }
  }

  &[x-placement^="left"] {
    .arrow::after {
      border-left-color: #e00125;
    }
  }
}

.autoComplete-popOver .header {
  border: none;
  border-radius: 0 !important;
  background-color: #e00125;
}

.autoComplete-popOver .triangle-container {
  display: inline-block;
  margin: 13px 0 0 -10px;
  vertical-align: middle;
  position: absolute;

  @media (min-width: 639px) and (max-width: 768px) {
    right: -10px;
  }

  @media (min-width: 375px) and (max-width: 637px) {
    right: -10px;
  }

  @media (min-width: 320px) and (max-width: 374px) {
    right: 0px;
  }
}

.autoComplete-popOver .triangle-caret {
  width: 10px;
  height: 10px;
  border-right: solid 10px #e00125;
  border-bottom: solid 10px transparent;
  border-top: solid 10px transparent;

  @media (min-width: 769px) and (max-width: 1023px) {
    border-right: solid 10px #e00125;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-left: none !important;
  }

  @media (min-width: 639px) and (max-width: 768px) {
    border-left: solid 10px #e00125;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-right: none !important;
  }

  @media (max-width: 638px) {
    border-left: solid 10px #e00125;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-right: none !important;
  }

  @media (min-width: 375px) and (max-width: 637px) {
    border-left: solid 10px #e00125;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-right: none !important;
  }

  @media (min-width: 200px) and (max-width: 374px) {
    display: none;
  }
}

.autoComplete-popOver .body {
  background-color: #f6f6f6;
}

.custom-label-error {
  color: #b2001c;
}

.avoid-inheritance {
  position: relative !important;
  height: auto !important;
  top: auto !important;
  right: auto !important;
}

.h-min-content {
  height: min-content;
}

.ml-15 {
  margin-left: 15%;
}

.c-white {
  color: white;
}