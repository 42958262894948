.itemView-item-esito {
  border-radius: 2px;
  margin: 7px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.txtItem-item-esito {
  flex: 1;
  vertical-align: center;
  color: $black;
  font-weight: bold;
  margin: 10px;
}

.iconView-item-esito {
  align-items: center;
  display: flex;
  flex-direction: column;
}