.widget-tuoConsulente-container {
  position: relative;
  display: flex;
  flex-direction: row;
  line-height: .8;
  font-weight: 400;
  letter-spacing: normal;
  font-style: normal;

  font-size: 16px;

  &>div>p.title {
    padding-top: 111px;
  }

  &>div>p.subTitle {
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 33px;
  }

  &>div>footer {
    font-weight: 700;
    font-size: 1.1em;
    position: absolute;
    bottom: 45px;
    right: 27px;

    &>img {
      filter: grayscale(100%)
    }
  }

  >img {
    flex-grow: 0;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    width: 240px;
    padding-top: 65px;
  }

  >div {
    padding-left: 30px;

    flex-grow: 1;

  }
}
