.modal-dialog {
  max-width: 624px;
}

.modal-preferiti-modal {
  .modal-content {
    border-radius: 0;

    .modal-header {
      border: none;

      .modal-title {
        // padding-left: 9px;
        font-family: inherit;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: $darkest-grey;
      }
    }

    .modal-body {
      font-family: inherit;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $darkest-grey;
      padding: 0 16px 16px 16px;

      .input-control-text {
        .input-control-text-icon {
          position: absolute;
          top: 14px;
          right: 0;
          width: 32px;
          cursor: pointer;
        }
      }

      .lista-preferiti {
        max-height: 264px;
        min-height: 264px;
        overflow-y: auto;

        .lista-preferiti-item {
          display: flex;
          justify-content: space-between;
        }

        .alternate-row {
          background-color: $light-grey;
        }
      }

      .quick-operation {
        text-align: center;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);

        &:hover {
          cursor: pointer;
        }

        .txtWidgetView-item-quickOpr {
          margin: 0 15px 15px 15px;
          font-weight: normal;
          font-size: 16px;
          line-height: 1.14;
          max-height: 32px;
          overflow: hidden;
        }
      }

      .preferito-elimina {
        background-color: $red-monza;
        border-radius: 25px;
        fill: white;
        position: absolute;
        right: 0;
      }

      .quick-operation.quick-operation-missing {
        border: 2px dashed $dark-grey-bottom;
        background-color: $light-grey;

        .preferito-elimina {
          display: none;
        }

        .iconView-item-quickOpr {
          margin-top: 20px;
        }

        .txtWidgetView-item-quickOpr {
          display: none;
        }

        svg {
          fill: $dark-grey-bottom;
          color: $dark-grey-bottom;
        }
      }
    }

    .modal-frequenti {
      display: none;
      background-color: $mercury;
      padding: 16px;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
      color: $black;

      &-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        color: $darkest-grey;
      }
    }

    .modal-footer {
      border: none;

      &.justify {
        justify-content: flex-end;
      }

      .modal-preferiti-modal__close-button {
        width: 220px;
        height: 48px;
        padding: 8px 0;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.4px;
        font-family: inherit;
        background: inherit;
        outline-style: none;

        &.left {
          color: $asphalt;
          border: none;
        }

        &.right {
          color: #e2001a;
          border: 2px solid #e2001a;
        }

        &.button-disable {
          opacity: 0.5;
        }
      }
    }
  }
}