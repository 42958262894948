.header-bollettino {
  height: 44px;
  background-color: $medium-dark-grey;
  display: flex;
}

.header-spacing {
  margin: auto 2%;
}

.line1Col1-step1 {
  flex-direction: row;
}

.line1Col2-step1 {
  display: flex;
  flex-direction: row;
  height: 45px;
}

.line1FullCol-step1 {
  height: 45px;
}

.line1ColRight-step1 {
  flex-basis: 70%;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-grow-2 {
  flex-grow: 2;
}

.horizontal-margin-4 {
  margin: 0 4%;
}

.line1-step1 {
  margin: 3% 0;
}

.flex-basis-16 {
  flex: none;
  flex-basis: 16%;
}

.black-divider {
  border-top: 1px solid $black;
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

// Box EURO symbol
.euro-box {
  width: 44px;
  margin-right: 5%;
  display: flex;
  height: 44px;
  text-align: center;
  background: $black;
  align-items: center;
}

.info-tooltip-trigger {
  cursor: default !important;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding-top: 15px;
  padding-left: 15px;
}

.euro-box>div {
  line-height: 24px;
  font-size: 36px;
  margin: auto;
  color: $white;
  font-weight: bold;
}

.mb-4 {
  margin-bottom: 4%;
}

.ml-3pc {
  margin-left: 3%;
}

// Height of a single column
.height-45 {
  height: 45px;
}

.mt-min-15 {
  margin-top: -15px;
}

.input-text {
  text-align: right !important;
}

@media screen and (min-width: 1024px) {
  .bollettino-cc {
    display: flex;
  }
}