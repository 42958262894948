.paragraph-container {
  max-width: 788px;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.row-alternative-color {
  background-color: $light-grey-alternative;
}

.clickable {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.background-inherit {
  background-color: inherit;
}

.items-centerd {
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.nexi-portale-accedi {
  align-items: center;
  justify-content: space-between;
}

.max-width-450 {
  max-width: 450px;
}

.full-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.make-invisible {
  visibility: hidden !important;
}

.max-width-766 {
  max-width: 766px;
}

.text-align-right {
  text-align: right;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-right-16 {
  margin-right: 16px;
}

.max-50-tablet {
  max-width: 100%;

  @media (min-width: $breakpoint-tablet) {
    max-width: 50%;
  }
}

.riepilogo {
  .riepilogo-intestazione {
    color: $dark-grey-accessibility;
    margin-top: 24px;
    line-height: 16px;
    font-weight: bold;
    font-size: 16px;
  }

  .riepilogo-section {
    position: relative;
    display: flex;
    flex-direction: column;

    margin-top: 16px;
    background-color: $light-grey;
    padding: 16px;
  }

  .riepilogo-row {
    display: flex;
    flex-direction: column;
    flex: 1;

    &+.riepilogo-col,
    &+.riepilogo-row {
      margin-top: 16px;
    }

    .riepilogo-col {
      display: flex;
      flex-direction: column;

      &:not(.shrink) {
        flex: 1;
      }

      &+.riepilogo-row,
      &+.riepilogo-col {
        margin-top: 16px;
        margin-left: 0;
      }

      .riepilogo-label {
        font-size: 16px;
        line-height: 16px;
        font-weight: 300;
        color: $darkest-grey;
        margin: 0 0 4px;
      }

      .riepilogo-img {
        margin: 14px 0 16px 0;
        text-align: center;

        img {
          max-height: 192px;
          max-width: 100%;
        }
      }

      .riepilogo-profilo::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        width: 16px;
        height: 2px;
        background-color: $darker-grey;
      }

      .riepilogo-profilo-a::after {
        background-color: $graph-profile-a;
      }

      .riepilogo-profilo-b::after {
        background-color: $graph-profile-b;
      }

      .riepilogo-profilo-c::after {
        background-color: $graph-profile-c;
      }

      .riepilogo-profilo-d::after {
        background-color: $graph-profile-d;
      }

      .riepilogo-profilo-e::after {
        background-color: $graph-profile-e;
      }
    }

    @media (min-width: $breakpoint-mobile-landscape) {
      flex-direction: row;

      .riepilogo-col+.riepilogo-col,
      .riepilogo-row+.riepilogo-col,
      .riepilogo-col+.riepilogo-row {
        margin-top: 0;
        margin-left: 16px;
      }
    }
  }

  .riepilogo-editable-label-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .form-field {
      margin-bottom: 0;
    }

    .form-field__label {
      left: 0;
      font-size: 16px;
      line-height: 16px;
      font-weight: 300;
      color: $dark-grey-accessibility;
    }

    .form-field__input {
      padding-left: 0;

      &.form-field__input__disable {
        margin-bottom: 1px;
      }
    }

    svg {
      margin-bottom: 8px;
    }
  }
}

.riepilogo-value {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: $black;
  word-break: break-word;

  svg {
    margin-left: 8px;
    margin-bottom: 8px;
  }

  .riepilogo-key-value {
    display: flex;
    flex-direction: row;
    margin-top: 8px;

    .riepilogo-keys {
      display: flex;
      flex-direction: column;

      .riepilogo-k {
        margin-top: 4px;
      }
    }

    .riepilogo-values {
      display: flex;
      flex-direction: column;
      margin-left: 32px;
      align-items: flex-end;

      .riepilogo-v {
        margin-top: 4px;
      }
    }
  }

  .riepilogo-value-table {
    overflow: auto;
  }

  table {
    width: 100%;

    thead {
      border-bottom: 1px solid #979797;
    }

    tbody {
      tr {
        &:first-child {
          td {
            padding-top: 16px;
          }
        }

        &:last-child {
          td {
            padding-bottom: 16px;
          }
        }
      }
    }

    tfoot {
      border-top: 1px solid #979797;
    }

    th {
      padding: 8px 16px 8px 0;
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: $black;

      &:last-child {
        padding-right: 0;
      }
    }

    td {
      padding: 4px 16px 4px 0;
      font-size: 16px;
      line-height: 16px;
      color: $black;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.back-button-detail {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin: 5px;
  text-align: center;
}

.back-button-detail:hover {
  color: $black;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

.text-icon-left {
  max-width: 248px;
  display: inline-flex;
  align-items: center;
  margin-top: 32px;
}

.contenitore-pulsanti {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin: 32px 0;

  .pulsanti-destra+.pulsanti-destra,
  .pulsanti-sinistra+.pulsanti-sinistra,
  .pulsanti-sinistra+.pulsanti-destra {
    margin-bottom: 16px;
  }

  .pulsanti-sinistra,
  .pulsanti-destra {
    min-height: 48px;

    button {
      min-height: 48px;
      width: 100%;
    }

    button+button {
      margin-bottom: 16px;
    }
  }

  .pulsanti-sinistra {
    display: flex;
    flex-direction: column-reverse;
  }

  .pulsanti-destra {
    display: flex;
    flex-direction: column-reverse;
  }

  @media (min-width: $breakpoint-mobile-landscape) {
    .pulsanti-destra {
      align-self: flex-end;
    }

    .pulsanti-sinistra+.pulsanti-destra {
      align-self: inherit;
    }

    .pulsanti-sinistra,
    .pulsanti-destra {
      flex-direction: row;

      button {
        width: 248px;
        flex: 1;
      }

      button+button {
        margin-bottom: 0px;
        margin-left: 16px;
      }
    }
  }

  @media (min-width: $breakpoint-tablet-landscape) {
    flex-direction: row;

    .pulsanti-sinistra,
    .pulsanti-destra {
      button {
        flex: auto;
      }
    }

    .pulsanti-destra {
      justify-content: flex-end;
    }

    .pulsanti-destra+.pulsanti-destra,
    .pulsanti-sinistra+.pulsanti-sinistra,
    .pulsanti-sinistra+.pulsanti-destra {
      margin-bottom: 0px;
      margin-left: 16px;
    }

    button:not(:only-child) {
      width: 200px;
    }

    .pulsanti-destra:only-child {
      margin-left: auto;
    }
  }
}