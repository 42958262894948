.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.breadcrumbs-inner {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding-right: 16px;
  overflow: hidden;

  .breadcrumb-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    &:last-child {
      overflow: hidden;
    }

    .text-item-breadcrumbs {
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 8px;

      &-selected {
        color: $black;
      }

      &-unselected {
        color: $dark-grey-accessibility;
        margin-bottom: 10px;
      }

      &-active:hover {
        color: $black;
        cursor: pointer;
      }
    }

    .separator {
      display: none;
    }

    &:not(:last-child) {
      display: none;
    }

    @media (min-width: $breakpoint-tablet) {
      .separator {
        display: inherit;
      }

      &:not(:last-child) {
        display: inherit;
      }
    }
  }

  .breadcrumb-back {
    display: none;
    margin-right: 8px;
  }

  .breadcrumb-home {
    display: none;
  }

  @media (min-width: $breakpoint-tablet) {
    .breadcrumb-back {
      display: inherit;
    }

    .breadcrumb-home {
      display: inherit;
    }
  }
}

.breadcrumb-side {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    display: none;
    height: 20px;

    @media (min-width: $breakpoint-mobile-landscape) {
      display: inherit;
    }
  }
}