.headerF24 {
  margin-top: 16px;
  display: flex;
  position: relative;
  height: 68px;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;

  &>img:first-child {
    width: 170px;
    height: 40px;
    align-content: flex-start;
  }

  >h3 {
    width: 100%;
    height: 24px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $darkest-grey;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
  }

  &>img:last-child {
    width: 90px;
    height: 24px;
    align-content: flex-end;
  }
}

.label-inputBox {
  text-align: end;
  padding-right: 10px;
}

.f24-saldo-finale-label {
  background-color: $white;
  border-bottom: solid 1px $dark-grey-bottom;
  width: 100%;
  padding-right: 10px;
  text-align: end;
  align-self: flex-end;
}

.f24-black-box {
  display: block;
  height: 24;
  width: 24;
  background-color: $darkest-grey;

  &:hover {
    cursor: pointer;
    background-color: $black;
    box-shadow: $medium-shadow;

    svg {
      fill: $red-monza;
      color: $red-monza;
    }
  }
}

.f24-box-ricerca-absolute {
  position: absolute;
  top: 44px;
  right: 2px;
}

.f24-body {
  background: #ebf7fa;

  .form-field__label {
    color: $dark-grey-accessibility-f24;
  }

  .riepilogo-intestazione {
    margin-top: 0 !important;
  }

  .riepilogo-section {
    margin-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: transparent !important;
  }

  >.f24-title-tab {
    background: $backgroundIntestazioneF24;
    height: 44px;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $black;
    padding: 14px 16px 14px 21px;
    text-transform: uppercase;
    justify-content: space-between;

    >img {
      align-items: flex-end;
      align-content: flex-end;
      position: relative;
      width: 44px;
      height: 44px;
      top: -13px;

      &:hover {
        cursor: pointer;
      }
    }

    >img.reverse {
      transform: scale(-1);
    }
  }

  >.f24-row {
    padding: 14px 16px 14px 21px;
    transition: all 0.2s;

    >label {
      height: 16px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: $dark-grey-accessibility-f24;
      text-transform: uppercase;
    }
  }

  >.f24-row-resize {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  .f24-ordinario-cf {
    >span:first-child {
      grid-area: codiceFiscale;
    }

    >span:nth-child(2) {
      grid-area: checkbox;
      text-align: right;
      display: flex;
      align-items: flex-end;

      >label {
        margin-right: 11px;
      }

      >div {
        position: relative;
        top: -5px;
        margin-left: 5px;
      }
    }

    display: grid;
    column-gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-template-areas: "codiceFiscale checkbox";
  }
}

.f24-subSection {
  border-top: solid 2px $backgroundIntestazioneF24;
}

.f24-ordinario-datiAnagrafici {
  >span:first-child {
    grid-area: cognome;
  }

  >span:nth-child(2) {
    grid-area: nome;
  }

  >span:nth-child(3) {
    grid-area: dataNascita;
  }

  >span:nth-child(4) {
    grid-area: sesso;
  }

  >span:nth-child(5) {
    grid-area: comune;
  }

  >span:nth-child(6) {
    grid-area: prov;
  }

  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "cognome cognome cognome cognome nome nome nome nome "
  "dataNascita dataNascita sesso comune comune comune comune prov";
}

.f24-ordinario-domicilioFiscale {
  >span:first-child {
    grid-area: comune;
  }

  >span:nth-child(2) {
    grid-area: prov;
  }

  >span:nth-child(3) {
    grid-area: via;
  }

  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "comune comune comune prov via via via";
}

.f24-coobligato {
  >span:first-child {
    grid-area: codiceFiscale;
  }

  >span:nth-child(2) {
    grid-area: codiceIdentificativo;
  }

  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "codiceFiscale codiceIdentificativo";
}

.f24-row-grid {
  >div.header>span {
    min-height: 32px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $darkest-grey;
  }
}

.f24-imposteDirette {
  span {
    min-height: 32px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $darkest-grey;
  }

  >span:first-child {
    grid-area: codice;
  }

  >span:nth-child(2) {
    grid-area: rateazione;
  }

  >span:nth-child(3) {
    grid-area: anno;
  }

  >span:nth-child(4) {
    grid-area: importo;
  }

  >span:nth-child(5) {
    grid-area: compensati;
  }

  >span:nth-child(6) {
    grid-area: compensati;
  }

  >span:nth-child(7) {
    grid-area: compensati;
  }

  >span:nth-child(8) {
    grid-area: compensati;
  }

  >span:nth-child(9) {
    grid-area: compensati;
  }

  >span:nth-child(10) {
    grid-area: compensati;
  }

  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "codice rateazione . anno importo compensati";
}

.bg-detrazioni {
  background-color: $medium-dark-grey;
}

.f24-accordion {
  margin-top: 16px;
}

.f24-input-list {
  >span:first-child {
    grid-area: first;
  }

  >span:nth-child(2) {
    grid-area: second;
  }

  >span:nth-child(3) {
    grid-area: third;
  }

  >span:nth-child(4) {
    grid-area: forth;
  }

  >span:nth-child(5) {
    grid-area: fifth;
  }

  display: grid;
  column-gap: 9px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "first second second third forth fifth";
}

.f24-grid-lastRow {
  margin-top: 12px;

  >span:first-child {
    grid-area: ufficio;
  }

  >span:nth-child(2) {
    grid-area: atto;
  }

  >span:nth-child(3) {
    grid-area: totA;

    >div {
      background: $white;
    }
  }

  >span:nth-child(4) {
    grid-area: totB;

    >div {
      background: $white;
    }
  }

  >span:nth-child(5) {
    grid-area: saldo;

    >div {
      background: $white;
    }
  }

  display: grid;
  column-gap: 9px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "ufficio ufficio ufficio ufficio atto atto atto atto atto atto atto atto . . . . . totA totA totA totA totB totB totB totB "
  ". . . . . . . . . . . . . . . . . saldo saldo saldo saldo saldo saldo saldo saldo ";
}

.f24-input-list-INPS,
.f24-inps>.header {
  >span:first-child {
    grid-area: sede;
  }

  >span:nth-child(2) {
    grid-area: contributo;
  }

  >span:nth-child(3) {
    grid-area: matricola;
  }

  >span:nth-child(4) {
    grid-area: periodo1;
  }

  >span:nth-child(5) {
    grid-area: periodo2;
  }

  >span:nth-child(6) {
    grid-area: importoDebiti;
  }

  >span:nth-child(7) {
    grid-area: importoCrediti;
  }

  display: grid;
  column-gap: 9px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "sede sede sede contributo contributo contributo matricola matricola matricola matricola matricola matricola matricola periodo1 periodo1 periodo1 periodo2 periodo2 periodo2 importoDebiti importoDebiti importoDebiti importoCrediti importoCrediti importoCrediti";
}

.f24-row-grid>.lastRow {
  >span:nth-child(1) {
    grid-area: totE;

    >div {
      background: $white;
    }
  }

  >span:nth-child(2) {
    grid-area: totF;

    >div {
      background: $white;
    }
  }

  >span:nth-child(3) {
    grid-area: saldo;

    >div {
      background: $white;
    }
  }

  display: grid;
  column-gap: 9px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: ". . . . . . . . . . . . . . . . . totE totE totE totE totF totF totF totF "
  ". . . . . . . . . . . . . . . . . saldo saldo saldo saldo saldo saldo saldo saldo ";
}

.riepilogo {
  .f24-row-grid {
    span {
      display: flex;
      height: 40px;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 8px;
    }
  }
}

.f24-saldo-finale {
  >span:nth-child(1) {
    grid-area: data;
  }

  >span:nth-child(2) {
    grid-area: saldo;

    >div {
      background: $white;
    }
  }

  display: grid;
  column-gap: 9px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: " data data data data data data data data data data data data . . . . . saldo saldo saldo saldo saldo saldo saldo saldo ";
}

.f24-regioni>.header,
.f24-input-list-regioni {
  >span:first-child {
    grid-area: regione;
  }

  >span:nth-child(2) {
    grid-area: tributo;
  }

  >span:nth-child(3) {
    grid-area: rateazione;
  }

  >span:nth-child(4) {
    grid-area: anno;
  }

  >span:nth-child(5) {
    grid-area: importo;
  }

  >span:nth-child(6) {
    grid-area: compensati;
  }

  display: grid;
  column-gap: 9px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "regione regione regione regione . . . tributo tributo tributo tributo  rateazione rateazione rateazione anno anno anno importo importo importo importo compensati compensati compensati compensati ";
}

.regioni {
  >span:first-child {
    grid-area: tot1;

    >div {
      background: $white;
    }
  }

  >span:nth-child(2) {
    grid-area: tot2;

    >div {
      background: $white;
    }
  }

  >span:nth-child(3) {
    grid-area: saldo;

    >div {
      background: $white;
    }
  }

  display: grid;
  column-gap: 9px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: ". . . . . . . . . . . . . . . . . tot1 tot1 tot1 tot1 tot2 tot2 tot2 tot2 "
  ". . . . . . . . . . . . . . . . . saldo saldo saldo saldo saldo saldo saldo saldo ";
}

.f24-imu {

  >.header,
  .body-inside>div>div {
    >span:first-child {
      grid-area: codice;
    }

    >span:nth-child(2) {
      grid-area: R;
    }

    >span:nth-child(3) {
      grid-area: IV;
    }

    >span:nth-child(4) {
      grid-area: A;
    }

    >span:nth-child(5) {
      grid-area: S;
    }

    >span:nth-child(6) {
      grid-area: NI;
    }

    >span:nth-child(7) {
      grid-area: tributo;
    }

    >span:nth-child(8) {
      grid-area: rateazione;
    }

    >span:nth-child(9) {
      grid-area: anno;
    }

    >span:nth-child(10) {
      grid-area: debito;
    }

    >span:nth-child(11) {
      grid-area: credito;
    }

    display: grid;
    column-gap: 8px;
    grid-template-columns: repeat(25, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-template-areas: "codice codice codice R IV A S NI NI tributo tributo tributo rateazione rateazione rateazione anno anno anno debito debito debito debito credito credito credito credito";
  }
}

.f24-INPS-lastRow {
  >span:nth-child(1) {
    grid-area: totG;

    >div {
      background: $white;
    }
  }

  >span:nth-child(2) {
    grid-area: totH;

    >div {
      background: $white;
    }
  }

  >span:nth-child(3) {
    grid-area: saldo;

    >div {
      background: $white;
    }
  }

  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: ". . . . . . . . . . . . . . . . . totG totG totG totG totH totH totH totH"
  ". . . . . . . . . . . . . . . . . saldo saldo saldo saldo saldo saldo saldo saldo ";
}

.f24-imu-lastRow {
  margin-top: 12px;

  >span:first-child {
    grid-area: detrazione;
  }

  >span:nth-child(2) {
    grid-area: totG;

    >div {
      background: $white;
    }
  }

  >span:nth-child(3) {
    grid-area: totH;

    >div {
      background: $white;
    }
  }

  >span:nth-child(4) {
    grid-area: saldo;

    >div {
      background: $white;
    }
  }

  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "detrazione detrazione detrazione detrazione detrazione detrazione detrazione detrazione . . . . . . . . . totG totG totG totG totH totH totH totH"
  ". . . . . . . . . . . . . . . . . saldo saldo saldo saldo saldo saldo saldo saldo ";
}

.f24-subSection-lastRow {
  >span:nth-child(1) {
    grid-area: totG;

    >div {
      background: $white;
    }
  }

  >span:nth-child(2) {
    grid-area: totH;

    >div {
      background: $white;
    }
  }

  >span:nth-child(3) {
    grid-area: saldo;

    >div {
      background: $white;
    }
  }

  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: ". . . . . . . . . . . . . . . . . totG totG totG totG totH totH totH totH"
  ". . . . . . . . . . . . . . . . . saldo saldo saldo saldo saldo saldo saldo saldo ";
}

>span:nth-child(5) {
  grid-area: saldo;

  >div {
    background: $white;
  }
}

.f24-altri {

  >.header,
  >.body-inside>div>div {
    >span:nth-child(1) {
      grid-area: codice;
    }

    >span:nth-child(2) {
      grid-area: ditta;
    }

    >span:nth-child(3) {
      grid-area: cc;
    }

    >span:nth-child(4) {
      grid-area: numeroRiferimento;
    }

    >span:nth-child(5) {
      grid-area: causale;
    }

    >span:nth-child(6) {
      grid-area: debiti;
    }

    >span:nth-child(7) {
      grid-area: credito;
    }

    display: grid;
    column-gap: 8px;
    grid-template-columns: repeat(25, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-template-areas: "codice codice codice codice ditta ditta ditta ditta cc cc  numeroRiferimento numeroRiferimento numeroRiferimento causale causale causale causale debiti debiti debiti debiti credito credito credito credito  ";
  }
}

.f24-altri-lastRow {
  >span:nth-child(1) {
    grid-area: totG;

    >div {
      background: $white;
    }
  }

  >span:nth-child(2) {
    grid-area: totH;

    >div {
      background: $white;
    }
  }

  >span:nth-child(3) {
    grid-area: saldo;

    >div {
      background: $white;
    }
  }

  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: ". . . . . . . . . . . . . . . . . totG totG totG totG totH totH totH totH"
  ". . . . . . . . . . . . . . . . . saldo saldo saldo saldo saldo saldo saldo saldo ";
}

.f24-subSection {
  padding: 14px 16px 14px 21px;
}

.f24-subSection>.header,
.f24-subSection>.body-inside>div>div.f24-input-list-subSection {
  >span:nth-child(1) {
    grid-area: ente;
  }

  >span:nth-child(2) {
    grid-area: sede;
  }

  >span:nth-child(3) {
    grid-area: causale;
  }

  >span:nth-child(4) {
    grid-area: posizione;
  }

  >span:nth-child(5) {
    grid-area: periodo1;
  }

  >span:nth-child(6) {
    grid-area: periodo2;
  }

  >span:nth-child(7) {
    grid-area: importo;
  }

  >span:nth-child(8) {
    grid-area: credito;
  }

  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(19, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "ente ente sede sede causale causale posizione posizione posizione periodo1 periodo1 periodo2 periodo2 importo importo importo credito credito credito";
}

/********************************-  Riepilogo -****************************************/

.textOnRight>div>div>div>input {
  text-align: right;
  font-weight: normal;
}

.textOnRight>div>div>div>label {
  left: 0;
  padding-right: 12px;
  width: 100%;
  text-align: right;
}

.isRiepilogoLastRow {
  margin-top: 24px;
}

.isRiepilogoHeader>span {
  padding: 0px 12px 8px 8px;
}

.f24-accordion-riepilogo {
  div.f24-row {
    background: $light-grey;
  }

  >div.f24-title-tab {
    background: white !important;
    padding-left: 0;
    color: $dark-grey-accessibility;
  }
}

.f24-accordion-open {
  border-bottom: solid 1px black;
}

.f24-title-tab>span {
  position: relative;
  height: 44px;
  top: -12px;
}

.f24-title-tab>span:hover {
  cursor: pointer;
}

.captionView-F24Ordinario {
  width: 84px;
  height: 16px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $dark-grey-bottom;
}

.section-F24Ordinario {
  width: 784px;
  height: 80px;
  background-color: $light-grey;
  padding: 16px;
}

.line1-F24Ordinario {
  display: flex;
  justify-content: space-between;

  >div:nth-child(1) {
    justify-content: flex-start;
    justify-items: flex-start;
  }

  >div:nth-child(2) {
    justify-content: flex-end;
    justify-items: flex-end;
  }
}

.f24-ordinario-ordinante-riepilogo {
  >div:nth-child(1) {
    grid-area: cf;
  }

  >div:nth-child(2) {
    grid-area: cogn;
  }

  >div:nth-child(3) {
    grid-area: nome;
  }

  >div:nth-child(4) {
    grid-area: nascita;
  }

  >div:nth-child(5) {
    grid-area: sesso1;
  }

  >div:nth-child(6) {
    grid-area: comune;
  }

  >div:nth-child(7) {
    grid-area: sesso2;
  }

  >div:nth-child(8) {
    grid-area: comuneDomicilio;
  }

  >div:nth-child(9) {
    grid-area: provincia;
  }

  >div:nth-child(10) {
    grid-area: via;
  }

  >div:nth-child(11) {
    grid-area: cfcoobligato;
  }

  >div:nth-child(12) {
    grid-area: codiceId;
  }

  div>div>div>label {
    white-space: nowrap;
    width: 100%;
  }

  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "cf cf cf . . . . . . ."
  "cogn cogn cogn cogn cogn nome nome nome nome nome"
  "nascita nascita nascita sesso1 sesso1 comune comune comune sesso2 sesso2"
  "comuneDomicilio comuneDomicilio comuneDomicilio provincia provincia via via via . ."
  "cfcoobligato cfcoobligato cfcoobligato cfcoobligato cfcoobligato cfcoobligato . . codiceId codiceId ";
}

.F24-riepilogo-saldoEUR {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  >label {
    margin-top: 10px;
    margin-right: 40px;
  }

  >p {
    font-weight: bold;
    margin-top: 10px;
  }
}

/************************ search bar ************************/

.f24-searchComponent-fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}

.f24-searchComponent-wrapper {
  position: absolute;
  width: 400px;
  height: 300px;
  background: $red;
  z-index: 1;
}

.f24-input-list-accise,
.f24-accise>.header {
  >span:first-child {
    grid-area: ente;
  }

  >span:nth-child(2) {
    grid-area: provincia;
  }

  >span:nth-child(3) {
    grid-area: tributo;
  }

  >span:nth-child(4) {
    grid-area: identificativo;
  }

  >span:nth-child(5) {
    grid-area: rateazione;
  }

  >span:nth-child(6) {
    grid-area: mese;
  }

  >span:nth-child(7) {
    grid-area: annoRiferimento;
  }

  >span:nth-child(8) {
    grid-area: importoDebito;
  }

  display: grid;
  column-gap: 9px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "ente ente provincia provincia tributo tributo tributo identificativo identificativo identificativo identificativo rateazione rateazione rateazione mese mese annoRiferimento annoRiferimento annoRiferimento importoDebito importoDebito importoDebito";
}

.f24-input-list-elide,
.f24-elide>.header {
  >span:first-child {
    grid-area: tipo;
  }

  >span:nth-child(2) {
    grid-area: identificativo;
  }

  >span:nth-child(3) {
    grid-area: tributo;
  }

  >span:nth-child(4) {
    grid-area: anno;
  }

  >span:nth-child(5) {
    grid-area: importoDebito;
  }

  display: grid;
  column-gap: 9px;
  grid-template-columns: repeat(25, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "tipo tipo identificativo identificativo identificativo identificativo identificativo identificativo identificativo identificativo identificativo identificativo tributo tributo tributo tributo anno anno anno anno importoDebito importoDebito importoDebito importoDebito importoDebito";
}

table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0cap;
}

td {
  padding: 4px;
}

th {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.33;
  align-content: flex-start;
}

.importo-debito,
.importo-credito {
  min-width: 70px;
}

.f24-motivoPagamento>.header,
.f24-input-list-motivoPagamento {
  >span:first-child {
    grid-area: sezione;
  }

  >span:nth-child(2) {
    grid-area: tributo;
  }

  >span:nth-child(3) {
    grid-area: ente;
  }

  >span:nth-child(4) {
    grid-area: rravvedimento;
  }

  >span:nth-child(5) {
    grid-area: immobiliareVariati;
  }

  >span:nth-child(6) {
    grid-area: acconto;
  }

  >span:nth-child(7) {
    grid-area: saldo;
  }

  >span:nth-child(8) {
    grid-area: numeroImmobiliari;
  }

  >span:nth-child(9) {
    grid-area: rate;
  }

  >span:nth-child(10) {
    grid-area: anno;
  }

  >span:nth-child(11) {
    grid-area: detrazione;
  }

  >span:nth-child(12) {
    grid-area: importoDebito;
  }

  >span:nth-child(13) {
    grid-area: importoCredito;
  }

  width: -webkit-fill-available;
}

.cutom-label {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $dark-grey-accessibility;
}

// Show F24 responsive image for mobile width
.responsive-f24-image-blocker {
  display: flex;
  flex-direction: column;
  background-color: $color-sparkasse-concrete;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 32px 16px;
}

// Hide responsive image for desktop width
@media (min-width: $breakpoint-tablet) {
  .responsive-f24-image-blocker {
    display: none;
  }
}

// Hide F24 form for mobile width
.form-desktop {
  display: none;
}

// Show F24 form for desktop width
@media (min-width: $breakpoint-tablet) {
  .form-desktop {
    display: inherit;
  }
}

.responsive-image {
  max-width: 400px;
  width: 100%;
}

.responsive-text-header {
  margin: 16px 16px 0 16px;
  max-width: 336px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: $color-sparkasse-asphalt;
}

.responsive-text-subtitle {
  margin: 16px 16px 0 16px;
  max-width: 336px;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  color: $darkest-grey;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

/*******************
 F24 SEMPLIFICATO
*******************/

.body-dispositiva-semplificato {

  .img-selection-icon,
  svg.selection-icon,
  .resize-search-icon {
    height: 70% !important;
    top: 10px !important;
  }

  .main-step2 {
    .f24-input-list-motivoPagamento {
      .form-field__input {
        padding: 16px 4px 8px 0;
      }
    }
  }
}

.body-dispositiva-semplificato,
.body-dispositiva-accise,
.body-dispositiva-identificativi,
.body-dispositiva-standard {
  .form-field__control_multiselect {
    padding-top: 15px;

    input.searchBox-small {
      margin-bottom: 5px;
    }
  }
}

.f24-input-list-motivoPagamento .input-control .input-control-error {
  font-size: 9px;
  margin-top: -10px;
  padding: 0px;
  line-height: 12px;
}

.f24-qr-modal {
  .modal-dialog {
    max-width: 768px;
  }

  .modal-body {
    padding: 32px 64px;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    padding: 0 64px 32px 64px;
    background-color: transparent !important;

    .f24-qr-modal-button {
      flex: 1;
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;

      &:not(:first-child) {
        margin-left: 0;
        padding-left: 0;

        @media (min-width: $breakpoint-tablet-landscape) {
          margin-left: 64px;
          padding-left: 64px;
        }
      }
    }

    .f24-qr-modal-close-button {
      width: 250px;
      height: 48px;
      color: $black;
      font-family: inherit;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.4px;
      text-align: center;
      background-color: transparent;
      border: 2px solid $black;
    }

    @media (min-width: $breakpoint-tablet-landscape) {
      flex-direction: row;
    }
  }
}

.f24-qr-modal-loader {
  width: 150px;
  height: 150px;
}

.f24-qr-modal-desc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 8px 0;
    width: 250px;
    font-size: 16px;
    line-height: 20px;
    color: $black;
  }
}

.f24-qrcode-container {
  opacity: 1;
  height: auto;
  max-height: 100%;
  overflow: hidden;

  &.hide {
    opacity: 0;
    max-height: 0;
  }

  p {
    margin: 4px 0;
  }

  .f24-qrcode-title {
    margin: 8px 0;

    svg {
      margin: 0 8px 0 0;
    }

    span {
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      color: $darkest-grey;
    }
  }

  .qrcode {
    margin: 16px 0 32px 0;
    padding: 0 32px;
    width: 100%;
  }
}

// .body-dispositiva-standard {
//   .input-box-enabled {
//     &:focus {
//       box-shadow: inset 0 0 0 1px #181818;
//       border-color: #181818;
//     }
//   }

//   .form-field__input {
//     &:focus {
//       box-shadow: inset 0 -1px 0 0 #181818;
//       border-color: #181818;
//     }
//   }

//   .form-field__control_multiselect {
//     &:focus-within {
//       box-shadow: inset 0 -1px 0 0 #181818;
//       border-color: #181818;
//     }
//   }
// }