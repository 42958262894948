.orario-paragraph {
  padding: 0px 12px 8px 8px;

  .paragraph-label {
    pointer-events: none;
    font-size: 1rem;
    line-height: 20px;
    display: block;
    left: 8px;
    margin: 0;
    color: $dark-grey-accessibility;
  }
}