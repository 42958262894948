.form-line {
  display: flex;
  flex-direction: column;
  flex: 1 0 10px;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
  }

  &-control {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (min-width: $breakpoint-tablet) {
      margin-left: 20px;
    }
  }

  &-control:first-child {
    margin-left: 0;
  }
}

.form-simple-label {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: $black;
}

.form-field {
  display: flex;
  flex-direction: row;
  width: auto;
  margin-bottom: 16px;
}

.DayPicker-Day--selected {
  background-color: $red-monza !important;
  border-radius: 0 !important;
}

.DayPicker-Month--nextSelected {
  border-radius: 0 !important;
  color: #f0f8ff;
  background-color: rgba(223, 0, 37, 0.6);
}

.horizontal-margin-2 {
  margin: 0 2%;
}

.form-field__label {
  pointer-events: none;
  font-size: 1rem;
  line-height: 20px;
  -webkit-transform: translateY(-18px);
  transform: translateY(-18px);
  display: block;
  // font-weight: normal;
  left: 8px;
  margin: 0;
  top: 16px;
  position: absolute;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: $dark-grey-accessibility;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
}

@media screen and (max-device-width: 768px) {
  .form-field__label {
    left: 0 !important;
  }
}

.form-field__label_error {
  color: $bright-red;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker-wrapper:focus {
  border-bottom: 2px solid $black;
  -webkit-transform: scaleX(150);
  transform: scaleX(150);
}

.form-field__control {
  // background: #eee;
  overflow: hidden;
  position: relative;
  width: 100%;
}

// .form-field__control>input {
//   border-bottom: solid 1px black
// }

.form-field__control_datepicker {
  // background: #eee;
  position: relative;
  width: 100%;
}

.form-field__control_date {
  margin-top: -2px;
}

.form-field__control_date2 {
  // margin-top: -1px;
  width: 10px;
  z-index: auto !important;
  border-bottom: 2px solid $black;
}

.form-field__control_multiselect {
  overflow: inherit;
  padding: 14px 8px 0 8px; // 16px 40px 0 8px
  border-bottom: 1px solid #999;
  border-radius: 0;

  &.disabled {
    border-bottom: 0px;
  }

  input.searchBox {
    margin-bottom: 8px;
    margin-top: 0;
    line-height: 1rem;
    vertical-align: middle;
    cursor: text;
    max-width: 20em;
    width: 100%;
  }

  input.searchBox:active {
    // border: 0.5px dotted;
  }

  input.searchBox-custom {
    margin-bottom: 8px;
    margin-top: 0;
    line-height: 1rem;
    vertical-align: middle;
    cursor: text;
    max-width: 15em;
  }

  input.searchBox-small {
    margin-bottom: 8px;
    margin-top: 0;
    line-height: 1rem;
    vertical-align: middle;
    cursor: text;
    width: 120%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  div.optionListContainer {
    border-radius: 0;

    ul.optionContainer {
      border-radius: 0;
      display: contents;
      align-items: center;
      padding: 10px;

      li.option {
        color: #333333;

        input[type="checkbox"] {
          margin-left: 10px;
          cursor: pointer;
          border: none;
          width: 30px;
          height: 15px;
          visibility: hidden;
        }

        input[type="checkbox"]:after {
          content: " ";
          border-radius: 0;
          display: inline-block;
          color: $white;
          height: 1rem;
          width: 1rem;
          visibility: visible;
          background-color: $white;
          border: solid 1px rgba(0, 0, 0, 0.16);
        }

        input[type="checkbox"]:checked:after {
          background-color: $red-monza;
          content: "\2713";
          line-height: 1rem;
          vertical-align: top;
          border: none;
          padding-left: 1px;
          padding-top: 1px;
        }
      }

      li.option:hover {
        background-color: $light-grey;
      }
    }
  }
}

.form-field__bar {
  border-bottom: 2px solid $black;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  width: 1%;
}

.form-field__bar__disable {
  border-bottom: 0px solid #999;
}

.form-field__input,
.form-field__textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #999;
  color: #333;
  display: block;
  font-size: 16px;
  line-height: 20px;
  // margin-top: 24px;
  outline: 0;
  padding: 16px 12px 8px 8px;
  width: 100%;
}

.form-field__input__disable,
.form-field__textarea__disable {
  border-bottom: 0px solid #999;
  text-overflow: ellipsis;
}

.form-field__input_btn,
.form-field__textarea_bnt {
  padding-right: 40px; // da utilizzare per inserire l'icona su riga
}

/* .form-field__input:-ms-input-placeholder~.form-field__label,
.form-field__textarea:-ms-input-placeholder~.form-field__label {
  font-size: 1.2rem;
  transform: translateY(0);
}

.form-field__input:placeholder-shown~.form-field__label,
.form-field__textarea:placeholder-shown~.form-field__label {
  font-size: 1rem;
  -webkit-transform: translateY(0);
  transform: translateY(0);
} */
.form-field__label {
  // SPKON-5873 - disabilitazione floating label per persone con disabilità cognitiva
  // font-size: 0.91rem;
  // -webkit-transform: translateY(0);
  // transform: translateY(0);
}

.form-field__input_focus~.form-field__label,
.form-field__textarea_focus~.form-field__label {
  // color: #000;
  font-size: 1rem;
  -webkit-transform: translateY(-18px);
  transform: translateY(-18px);
}

.form-field__input_focus~.form-field__bar,
.form-field__textarea_focus~.form-field__bar {
  border-bottom: 2px solid $black;
  -webkit-transform: scaleX(150);
  transform: scaleX(150);
}

input[type="date"] {
  position: relative;
  // z-index: 2;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
  content: url("../assets/icons/form/data.svg");
  margin-right: -15px;
  margin-top: -30px;
  height: 25px;
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
  color: #bf1400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 90%;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
  z-index: -99;
  color: transparent;
  background: transparent;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
  z-index: -99;
  color: transparent;
  background: transparent;
}

.flip-icon {
  transform: scaleX(-1);
}

.form-field__label_focus {
  // color: #000;
  font-size: 1rem;
  -webkit-transform: translateY(-18px);
  transform: translateY(-18px);
}

.no-border-bottom {
  border-bottom: none;
}

.input-date-month {
  width: 50%;
}

@media screen and (max-device-width: 768px) {
  .input-date-month {
    width: 100%;
  }
}