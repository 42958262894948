.bonifico-deposito-banner {
  .body-bannerDX {
    background-color: transparent;
    margin: 0;
  }
  .button-text.image-on-left img {
    margin-right: 0;
    margin-left: 6px;
  }
}
