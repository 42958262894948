.line1Col4-step2 {
  display: flex;
  flex-direction: row;
  height: 45px;
  margin-left: 10px;
}

.datiPersonali-row {
  padding: 14px 16px 14px 21px;
  transition: all .2s;
}

.datiPersonali-row-grid {
  >div.header>span {
    height: 32px;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
}

.datiPersonali-datiAnagrafici {
  >span:first-child {
    grid-area: prov;
  }

  >span:nth-child(2) {
    grid-area: comune;
  }

  >span:nth-child(3) {
    grid-area: località;
  }

  >span:nth-child(4) {
    grid-area: cap;
  }

  >span:nth-child(5) {
    grid-area: piazza;
  }

  >span:nth-child(6) {
    grid-area: indirizzo;
  }

  >span:nth-child(7) {
    grid-area: numeroCivico;
  }

  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: "prov comune comune comune località località località cap "
  "piazza indirizzo indirizzo indirizzo indirizzo indirizzo numeroCivico numeroCivico"

}