.input-box {
  flex-direction: row;
  margin-bottom: 16px;
  margin-top: 16px;
  align-items: center;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &-form-field {
    margin-bottom: 32px;
  }

  button {
    position: relative;
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
    overflow: hidden;
    padding: 0;
  }

  &-enabled {
    border: 0.5px solid $darkest-grey;
    background-color: transparent;
  }

  &-disabled {
    border: 0.5px solid $dark-grey-bottom;
    background-color: $dark-grey-bottom;
  }

  &-checked {
    border: 0.5px solid $red-monza;
    background-color: $red-monza;
  }

  .visto-checkbox {
    position: absolute;
    top: -3px;
    left: -2px;
    width: 20px;
    height: 20px;
    fill: $white;
  }

  .input-box-label {
    margin-left: 10px;
    margin-bottom: 0;

    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    vertical-align: middle;
    color: $black;

    &:hover {
      cursor: pointer;
    }
  }

  .input-box-label-disabled {
    color: $dark-grey-accessibility;
  }

  .switch-container {
    padding: 0;
  }

  .descrizione-label {
    word-break: break-word;
  }
}