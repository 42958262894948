.bottom-divider {
  border-bottom: 1px solid black;
}

.margin-1 {
  margin: 1%;
}

.pb-24px {
  padding-bottom: 24px;
}

.vertical-margin-1 {
  margin: 1% 0;
}

.horizontal-padding-2 {
  padding: 0 2%;
}

// barra laterale stato pagamento
.pagamento-bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 100%;
}

// a mano cliente
.bg_pagamento-0 {
  background-color: $legendaAssegniManoCliente;
}

// bloccato
.bg_pagamento-1 {
  background-color: $legendaAssegniBloccato;
}

.bg_pagamento-2 {
  background-color: $legendaAssegniBloccato;
}

.bg_pagamento-3 {
  background-color: $legendaAssegniBloccato;
}

.bg_pagamento-4 {
  background-color: $legendaAssegniBloccato;
}

.bg_pagamento-5 {
  background-color: $legendaAssegniBloccato;
}

.bg_pagamento-6 {
  background-color: $legendaAssegniBloccato;
}

.bg_pagamento-F {
  background-color: $legendaAssegniBloccato;
}

.bg_pagamento-G {
  background-color: $legendaAssegniBloccato;
}

// pagato
.bg_pagamento-7 {
  background-color: $legendaAssegniPagato;
}

.bg_pagamento-B {
  background-color: $legendaAssegniPagato;
}

.bg_pagamento-C {
  background-color: $legendaAssegniPagato;
}

.bg_pagamento-D {
  background-color: $legendaAssegniPagato;
}

.bg_pagamento-E {
  background-color: $legendaAssegniPagato;
}

.bg_pagamento-P {
  background-color: $legendaAssegniPagato;
}

.bg_pagamento-R {
  background-color: $legendaAssegniPagato;
}

// respinto
.bg_pagamento-8 {
  background-color: $legendaAssegniRespinto;
}

.bg_pagamento-9 {
  background-color: $legendaAssegniRespinto;
}

.bg_pagamento-L {
  background-color: $legendaAssegniRespinto;
}

.bg_pagamento-N {
  background-color: $legendaAssegniRespinto;
}

// annullato
.bg_pagamento-A {
  background-color: $legendaAssegniAnnullato;
}

.bg_pagamento-H {
  background-color: $legendaAssegniAnnullato;
}

.bg_pagamento-I {
  background-color: $legendaAssegniAnnullato;
}

.bg_pagamento-M {
  background-color: $legendaAssegniAnnullato;
}

// non presente
.bg_pagamento-none {
  border: solid 1px #979797;
  background-color: $legendaAssegniNoPresente;
}

.assegni-card-spacing {
  padding: 1rem 0 0 1%;
}

.no-padding {
  padding: 0;
}

// Spalla destra, box esplicativo stato con colore
.state-box-assegni {
  width: 16px;
  height: 16px;
}

.vertical-align {
  margin: auto 0;
}

.horizontal-padding-5 {
  padding: 0 5%;
}

.vertical-padding-1 {
  padding: 1% 0;
}

.mr-5px {
  margin-right: 5px;
}

.content-main-section--right-bar-small-title {
  color: $black !important;
  font-size: 20px;
  font-weight: 500 !important;
  margin: 10px;
  margin-bottom: 20px;
  margin-top: 18px;
}

.pull-right {
  float: right;
}

.f-grow-0 {
  flex-grow: 0;
}

.black-color {
  color: $black;
}

.pr-2 {
  padding-right: 2%;
}