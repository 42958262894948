.main-step1 {
  margin-top: 10px;
}

.checkbox-step1 {
  height: 60px !important;
}

.line2-step1 {
  display: flex;
  flex-direction: row;

  .bonifico-istantaneo-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .info-tooltip-trigger {
      padding-top: 0px;
    }
  }

}

.line2Col1-step1 {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.line2Col2-step1 {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.line3-step1 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.spaced-container {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.dati-aggiuntivi-label {
  color: $dark-grey-accessibility;
}

.datiAggiuntivi-step1 {
  display: flex;
  flex: 1;
  margin-top: 16px;
  margin-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid $dark-grey-bottom;
}

@media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
  .datiAggiuntivi-step1 {
    width: calc(100vw - 30px) !important;
    position: relative;
  }

  .line2-step1 {
    width: calc(100vw - 30px) !important;
    position: relative;
  }

  .line3-step1 {
    width: calc(100vw - 30px) !important;
    position: relative;
  }

  .stepper-bonifico {
    width: calc(100vw - 30px) !important;
    position: relative;
  }
}

.aggiungi-nuovo {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: $darkest-grey;
  margin-bottom: 16px;
}

.small-options-buttons {
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 10px;
}

.line1Col3-step1 {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 10px;
  height: 45px;
}

.display-currency {
  float: right;
  margin-top: 18px;
}

.bonifico-ripetitivo-elenco-cta {
  justify-content: flex-end;
  display: flex;
  margin-right: 15px;
}