.kvp-container {
  display: flex;
  flex-direction: column;
}

.kvp-container .kvp-key {
  font-size: 1rem;
  color: #9d9d9d;
}

.kvp-container .kvp-value {
  font-size: 16px;
  color: #333;
  font-weight: 400;
  margin-top: -5px;
}