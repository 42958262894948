$color-saldo-complessivo-value: #2D6510;

$light-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
$medium-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
$heavy-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
$heavy-shadow-top: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
$action-box-hover: 0 6px 16px 2px rgba(0, 0, 0, 0.16);
$light-shadow-bottom: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

$primary-color: "rgb(255, 0, 0)";
$primary-background-color: "rgb(68, 68, 68)";

$cards-background-color: "#fff";
$cards-shadow-color: "#fff";

$font-primary-color: "rgb(255, 0, 0)";
$font-secondary-color: "rgb(255, 0, 0)";

$red-active: #c90000;
$red-not-active: #df0025;
$red-monza: #df0025;
$bright-red: #b2001c;
$dark-grey-bottom: #9d9d9d;
$dark-grey-accessibility: #595959;
$dark-grey-accessibility-f24: #4F4F4F;
$darker-grey: #c7c7c7;
$darkest-grey: #181818;
$medium-dark-grey: #d8d8d8;
$light-grey: #f6f6f6;
$light-grey-alternative: #f0f0f0;
$asphalt: #3f3f3f;
$white: #fff;
$black: #000;
$mercury: #e3e3e3;
$green: #556b52;
$red: #ff0000;
$grey: #808080;
$negativeAmount: #B2001C;
$positiveAmount: #006731;
$error: #df0514;

$gradientA: #dfdfdf;

$inputColor: #444;

$graph-profile-a: #03d3df;
$graph-profile-b: #182045;
$graph-profile-c: #dc2363;
$graph-profile-d: #e7bd61;
$graph-profile-e: #ff6600;

$chartVariant6: #b2001c;
$chartVariant9: #006633;
$chartVariant10: #93bf1f;
$chartVariant11: #2b9e1b;
$chartVariant16: #f9bd21;


$legendaAssegniRespinto: #CF2A27;
$legendaAssegniPagato: #009E0F;
$legendaAssegniBloccato: #FF9900;
$legendaAssegniManoCliente: #2B78E4;
$legendaAssegniAnnullato: #000000;
$legendaAssegniNoPresente: #FFFFFF;

$backgroundIntestazioneF24: #a0dceb;

$darkBackground: #161616;

$bannerGreen: #d0e9d3;

$rifiutato: #2b78e4;
$accettato: #009e0f;

svg {
  color: #df0025;
  fill: #3f3f3f;
}

svg.icona-in-negativo {
  fill: #fff;
}

svg.icona-rossa {
  fill: #df0025;
}

svg.icona-monocromatica {
  fill: #3f3f3f !important;
  color: #3f3f3f !important;
}

svg.icona-in-negativo.icona-monocromatica {
  fill: #fff !important;
  color: #fff !important;
}

svg.icona-green {
  fill: #2b9e1b;
}

svg.icone-azioni:not(.icona-in-negativo):hover,
.icone-azioni:hover svg.icone-azioni {
  fill: #df0025;
  color: #df0025;
}



svg.icona-disabilitata,
svg.icona-disabilitata:hover {
  fill: #9d9d9d;
  color: #9d9d9d;
}