.pl-16 {
  padding-left: 16px;
}

.mb-3px {
  margin-bottom: 3px;
}

.mb-15px {
  margin-bottom: 15px;
}

.filtri-label {
  color: $dark-grey-accessibility;
  font-size: 16px;
  font-weight: bold;
}

.scegliConto-label {
  font-size: 16px;
  font-weight: 300;
}

.new-button-list {
  display: 'flex';
  flex-direction: 'row';
  flex: '1 1';
  height: 50;
  margin-top: 30;
  justify-content: 'flex-end';
}

.mt-10 {
  margin-top: 10%
}

.valuta {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.3px;
  text-align: right;
  color: $black;
}

.flex-grow-11 {
  flex-grow: 11;
}

.pb-8 {
  padding-bottom: 8%;
}

.time-deposit-list-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.14;
  color: $dark-grey-accessibility;
  margin-top: 24px;
  margin-bottom: 0;
}

.time-deposit-card-wrapper {
  margin-top: 16px;

  .card-view--body-view--date.card-view--body-view--tasso {
    color: $darkest-grey;
  }
}

.time-deposit-vai-elenco {
  margin-top: 44px;
}