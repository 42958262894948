.carousel-wrapper {
  display: flex;
  width: calc(100% + 32px);
  flex-direction: column;
  min-height: 100px;
  padding-top: 14px;
  padding-bottom: 8px;
  margin: 0 -16px;

  &.dark-background {
    width: 100%;
    margin: 0;
    background-color: $light-grey;

    .carousel-select {
      margin: 0;
    }

    &.draggable {
      .BrainhubCarousel__trackContainer {
        &:active {

          &::after,
          &::before {
            box-shadow: 0 0 16px 24px $light-grey;
          }
        }

        &::before {
          box-shadow: 8px 0 6px 2px $light-grey;
        }

        &::after {
          box-shadow: -8px 0 6px 2px $light-grey;
        }
      }
    }
  }

  &.single-item {
    .carousel-select-input {
      &:hover {
        cursor: auto !important;
      }
    }

    .carousel-select-icon {
      display: none;
    }

    .carousel-item-view {
      width: calc(100% - 32px);
    }

    .BrainhubCarousel__custom-arrowLeft {
      padding-left: 0 !important;
    }

    .BrainhubCarousel__custom-arrowRight {
      padding-right: 0 !important;
    }
  }

  .carousel-title {
    flex: 1;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $dark-grey-accessibility;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .carousel-toggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 16px;
    margin-right: 16px;

    .carousel-toggle-title {
      margin: 0 8px 0 0;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      color: $black;
      vertical-align: middle;
    }
  }

  .BrainhubCarousel__container {
    position: relative;
  }

  .BrainhubCarousel__customArrows {
    display: block;

    .carousel-button-arrow {
      position: absolute;
      top: 8px;
      bottom: 8px;
      display: flex;
      align-items: center;
      border: none;
    }

    &.BrainhubCarousel__custom-arrowLeft {
      padding-left: 60px;

      .carousel-button-arrow {
        left: 6px;
      }
    }

    &.BrainhubCarousel__custom-arrowRight {
      padding-right: 60px;

      // .carousel-button-arrow {
      //   right: 16px;
      // }
    }

    &:not(.BrainhubCarousel__arrow--disable) {
      .carousel-button-arrow {
        background-color: $white;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
      }
    }
  }

  .carousel-title,
  .txtCaption-carousel {
    margin: 0 16px;
  }

  .carousel-header,
  .view-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;

    .carousel-button {
      margin-top: 4px;
      margin-right: 16px;
      margin-left: 16px;
    }

    @media (min-width: $breakpoint-mobile-landscape) {
      flex-direction: row;

      .carousel-button {
        margin-top: -8px;
        margin-left: 0;
      }
    }
  }

  .carousel-view {
    position: relative;
    display: none;
    width: 100%;
    max-width: 100vw;
    justify-content: center;
  }

  &.banner-dx-carousel,
  &.banner-home-carousel,
  &.tour-contestuale-carousel {
    margin: 0;
    width: 100%;

    .carousel-view {
      display: block;

      .BrainhubCarousel__custom-arrowLeft {
        padding-left: 0 !important;
      }

      .BrainhubCarousel__custom-arrowRight {
        padding-right: 0 !important;
      }
    }

    .carousel-stepper {
      margin-top: 8px;
    }
  }

  .additional-info-container {
    display: flex;
    flex-direction: row;
    margin: 8px 0 8px 0;
    padding: 0 16px;

    :not(:last-child) {
      margin-right: 32px;
    }

    .info-item {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 10px 0;
    }

    .key {
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: $darkest-grey;
    }

    .value {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $black;
    }
  }

  .carousel-select {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 8px 16px 0 16px;
    padding: 16px;
    background-color: $light-grey;

    &>div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }

    &>label {
      justify-content: right;
      text-align: right;
      padding: 5px;
    }

    .carousel-select-input {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 44px;

      &:hover {
        cursor: pointer;
      }

      &+.carousel-select-saldo {
        margin-top: 16px;
      }

      .carousel-select-input-heading {
        padding-right: 44px;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        color: $black;

        .bank-img {
          display: inline-block;
          margin-right: 8px;
          margin-bottom: 4px;
          height: 20px;
          width: 20px;
        }
      }

      .carousel-select-input-subscript {
        padding-right: 44px;
        font-size: 16px;
        line-height: 20px;
        color: $black;
      }

      .carousel-select-icon {
        position: absolute;
        top: 0;
        right: 0;
      }

      .carousel-select-dropdown-wrapper {
        z-index: 1;
        position: absolute;
        top: calc(100% + 16px);
        left: -16px;
        right: -16px;
        padding-bottom: 150px;

        &:hover {
          cursor: initial;
        }

        .carousel-select-dropdown {
          -webkit-box-shadow: $medium-shadow;
          -moz-box-shadow: $medium-shadow;
          box-shadow: $medium-shadow;

          .carousel-select-dropdown-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 16px;
            min-height: 76px;
            background-color: $white;

            &:hover {
              cursor: pointer;
              background-color: $light-grey;
            }

            &.selected {
              background-color: $mercury;
            }
          }
        }
      }
    }

    .carousel-select-saldo {
      margin-top: 8px;

      .carousel-select-saldo-label {
        font-size: 16px;
        line-height: 16px;
        color: $black;
        letter-spacing: -0.2px;
        margin-right: 16px;
      }

      .carousel-select-saldo-value {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: $black;
        letter-spacing: -0.2px;

        .carousel-select-saldo-value-valuta {
          margin-left: 6px;
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }

  .carousel-stepper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    .stepper-step {
      padding-right: 4px;
      padding-left: 4px;
      border: none;
      background-color: transparent;

      &:not(.stepper-selected-step) {
        padding-top: 3px;
        padding-bottom: 3px;
        cursor: pointer;

        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 2px;
          background-color: $color-sparkasse-asphalt;
        }
      }

      &.stepper-selected-step {
        padding-top: 2px;
        padding-bottom: 2px;

        &::after {
          content: "";
          display: block;
          width: 24px;
          height: 4px;
          background-color: $color-sparkasse-monza;
        }
      }
    }
  }

  &.disabled {
    .carousel-item-view {
      &::after {
        border-color: initial;
      }
    }

    .carousel-view {
      opacity: 0.6;
      filter: saturate(0);

      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
    }

    .carousel-select {
      opacity: 0.4;
      filter: saturate(0);

      .carousel-select-input {
        &:hover {
          cursor: initial;
        }
      }

      .carousel-select-icon {
        fill: initial;
      }
    }

    .stepper-step {
      &::after {
        background-color: $dark-grey-accessibility !important;
      }

      &:not(.stepper-selected-step) {
        cursor: inherit;
      }
    }
  }

  &.draggable {
    .BrainhubCarousel__trackContainer {
      position: relative;

      &:active {

        &::after,
        &::before {
          box-shadow: 0 0 16px 24px $white;
        }
      }

      &::before {
        content: "";
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -14px;
        width: 14px;
        box-shadow: 8px 0 6px 2px $white;
        transition: box-shadow 1s ease;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -14px;
        bottom: 0;
        width: 14px;
        box-shadow: -8px 0 6px 2px $white;
        transition: box-shadow 1s ease;
      }
    }

    .BrainhubCarousel__track {
      cursor: move;
      /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    &:not(.show-only-select) {
      .carousel-view {
        display: block;
      }

      .carousel-select {
        display: none;
      }
    }
  }
}

.carousel-item-view-SmartCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 104px;
  background-color: $white;
}

.carousel-item-view {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 104px;
  margin: 8px 16px;
  background-color: $white;

  &.carousel-item-view-card {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      border-top: 2px solid #a9a9a9;
      border-left: 2px solid #a9a9a9;
    }
  }

  &.carousel-item-view-selected {
    &::after {
      border-color: $color-sparkasse-monza;
    }
  }

  &:not(.carousel-item-view-selected) {
    &>* {
      opacity: 0.4;
      filter: saturate(0);
    }
  }
}

.carousel-select {
  .carousel-pin-view {
    align-items: center !important;

    .carousel-pin-view-label {
      font-size: 16px;
      line-height: 16px;
      color: $dark-grey-accessibility;
      letter-spacing: -0.2px;
      margin-right: 16px;
    }

    .carousel-pin-view-value {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      color: #000;
      letter-spacing: -0.2px;
      text-align: right;
    }

    .carousel-pin-view-icon {
      padding-left: 16px;

      @media (max-width: $breakpoint-mobile-landscape) {
        padding-left: 8px;
      }
    }
  }

  .carousel-pan-view {
    align-items: center !important;

    .carousel-pan-view-label {
      font-size: 16px;
      line-height: 16px;
      color: $dark-grey-accessibility;
      letter-spacing: -0.2px;
      margin-right: 16px;
    }

    .carousel-pan-view-value {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      color: #000;
      letter-spacing: -0.2px;
      text-align: right;
    }

    .carousel-pan-view-icon {
      padding-left: 16px;

      @media (max-width: $breakpoint-mobile-landscape) {
        padding-left: 0px;
      }
    }

  }
}

.carousel-view {
  .carousel-pin-view {
    padding: 0px 12px 8px 8px;
    align-items: center;

    .carousel-pin-view-label {
      font-size: 1rem;
      color: #000;
      letter-spacing: -0.2px;
      margin-right: 16px;
      pointer-events: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $dark-grey-accessibility;
    }

    .carousel-pin-view-value {
      cursor: pointer;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      color: #000;
      letter-spacing: -0.2px;
      text-align: right;
    }

    .carousel-pin-view-icon {
      padding-left: 8px;
    }
  }

  .carousel-pan-view {
    padding: 0px 12px 8px 8px;
    align-items: center;

    .carousel-pan-view-label {
      font-size: 1rem;
      letter-spacing: -0.2px;
      margin-right: 16px;
      pointer-events: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $dark-grey-accessibility;
    }

    .carousel-pan-view-value {
      cursor: pointer;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      color: #000;
      letter-spacing: -0.2px;
      text-align: right;
    }

    .carousel-pan-view-icon {
      padding-left: 8px;
    }
  }
}

.carousel-pin-view.carousel-pin-view-nexi-credit {
  padding: 0px;
}

.carousel-pin-view-value {
  .pin {
    &::after {
      content: "";
      display: block;
      height: 4px;
      width: 100%;
      background-color: red;
      animation: timer 10s linear 0s 1 normal forwards;
    }

    img {
      height: 32px;
    }
  }
}

.carousel-pan-view-value {
  .pan {
    background-color: $light-grey;
    padding: 8px;

    img {
      height: 18px;

      @media (max-width: $breakpoint-tablet-landscape ) {
        height: 16px;
      }

      @media (max-width: $breakpoint-tablet) {
        height: 18px
      }

      @media (max-width: $breakpoint-mobile-landscape) {
        height: 10px
      }
    }
  }
}

@keyframes timer {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}