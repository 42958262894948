.balance-home {
  position: relative;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 24px 32px 8px 16px;

  .home-aggiungi-banche {
    position: relative;
    top: unset;
    left: unset;

    &.make-invisible {
      display: none;
    }

    @media (min-width: $breakpoint-tablet) {
      position: absolute;
      top: 72px;
      right: 24px;
    }
  }
}

.home-rinnova-consenso,
.home-aggiungi-banche {
  display: flex;
  margin-left: auto;
}

.corner.cornen-saldo-home {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-top: solid 4px $red-monza;
  border-left: solid 4px $red-monza;
}

.mini-seleziona-rapporto {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: $asphalt;
  height: 33px;

  .focused-item {
    border: 1px solid $black !important;
  }

  .mini-seleziona-rapporto-selezione {
    max-width: 50em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin-bottom: 0rem;
  }

  .mini-seleziona-rapporto-clickable {
    display: flex;
    align-items: center;

    svg {
      visibility: hidden;
    }
  }

  .mini-seleziona-rapporto-clickable.numero-elementi-sufficiente {
    cursor: pointer;

    svg {
      visibility: visible;
    }
  }

  .mini-seleziona-rapporto-dropdown {
    z-index: 1;
    position: absolute;
    max-height: 336px;
    min-width: 250px;
    border: 1px solid $light-grey;
    -webkit-box-shadow: $medium-shadow;
    -moz-box-shadow: $medium-shadow;
    box-shadow: $medium-shadow;
    cursor: pointer;
    overflow: auto;

    .mini-seleziona-rapporto-dropdown-item {
      background-color: $white;
      padding: 4px 8px 4px 8px;
      text-overflow: ellipsis;
      max-width: 50em;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 300px;
    }

    .mini-seleziona-rapporto-dropdown-item.row-alternative-color {
      background-color: $light-grey-alternative;
    }

    .mini-seleziona-rapporto-dropdown-item:hover {
      background-color: $light-grey;
    }
  }
}

.complessivo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: baseline;

  @media (min-width: $breakpoint-mobile-landscape) {
    flex-direction: row;
  }

  &.complessivo-contabile {
    margin-top: 16px;
  }

  &.complessivo-disponibile {
    margin-top: -8px;
  }
}

.txtCaptionComplessivo {
  display: flex;
  margin: 0 10px 0 0;
  color: $balance-color;
  font-size: $secondary-font-size;
  font-weight: $primary-font-weight;
}

.txtCaptionDisponibile {
  display: flex;
  margin: 0 10px 0 0;
  color: $balance-color;
  font-size: $section-font-size;
}

.viewBalance {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .info-tooltip-trigger {
    padding: 0px;
  }
}

.mostra-nascondi-saldo {
  margin-left: 16px;
  align-self: end;
}

.img-hide-amounts-inline {
  display: flex;

  &:hover {
    cursor: pointer;
  }
}

.info-tooltip-icon {
  height: 32px;
  width: 32px;
}

.txtComplessivo {
  color: $color-saldo-complessivo-value;
  font-size: $balance-font-size;
  font-weight: $balance-font-weight;
  text-align: center;
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: flex-end;
}

.txtDisponibile {
  color: $color-saldo-complessivo-value;
  font-size: $section-font-size;
  font-weight: $balance-font-weight;
  text-align: center;
  vertical-align: center;
  align-items: center;
}

.txtBalanceBlur {
  filter: "blur(6px)";
}