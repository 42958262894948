$font-size-valore-saldo-complessivo: 24px;

// $primary-font-size: 25px;
// $primary-font-weight: "bold";
// $primary-color: "#707070";

// $secondary-font-size: 20px;

// $balance-font-size: 25px;
// $balance-font-weight: "bold";
// $balance-color: "#707070";

// $section-font-size: 16px;
// $section-font-weight: "bold";
// $section-color: "#707070";