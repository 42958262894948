.rubricaLightContainer {
  position: relative;
  text-align: left;
  width: 100%;
  border-radius: 0;
}

.rubricaLight-searchWrapper {
  position: relative;
  border: none;
  padding: 0;
  border-radius: 0;
  min-height: 28px;
}

.rubricaLightContainer input {
  border: none;
  margin-top: 3px;
  background: transparent;
}

.rubricaLightContainer input:focus {
  outline: none;
}

.rubricaLight-optionListContainer {
  position: absolute;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  z-index: 2;
}

.rubricaLightContainer ul {
  display: block;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: hidden;
}

.rubricaLightContainer li {
  padding: 10px 10px;
}

.rubricaLightContainer li:hover {
  background: #f6f6f6;
  color: #333333;
  cursor: pointer;
}

.checkbox {
  margin-right: 10px;
}

.disableSelection {
  pointer-events: none;
  opacity: 0.5;
}

.rubricaLight-highlightOption {
  background: #e4e4e4;
  color: #333333;
}

.rubricaLight-selectedOption {
  background: #f6f6f6;
  color: #333333;
}

.rubricaLight-optionsContainer {
  display: flex;
  flex-direction: column;
}

.rubricaLight-optionsContainer .bold {
  font-weight: 500;
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.rubricaLight-optionsContainer .light-text {
  color: #9d9d9d;
}

.rubricaLight-search-view {
  display: flex;
  flex-direction: column;
  max-height: 68px;
  width: 100%;
  justify-content: center;

  @media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
    margin-left: 0px !important;
  }

  @media (min-width: $breakpoint-tablet) {
    margin-right: 0;
  }

  &--input-group.input-group {
    height: 100%;

    .rubricaLight-search-view--form-control.form-control {
      border-radius: 0;
      display: inherit;
      color: #ffffff;

      background: url(../../assets/icons/search-white.svg) no-repeat;
      padding-right: 50px;
      background-position: right 10px center;
      background-size: 30px;
      background-color: #e00125;
      border: 1px solid #e00125;
      height: 44px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      @media (min-width: 639px) and (max-width: 768px) {
        margin-left: 0px !important;
      }

      @media (min-width: 769px) and (max-width: 1023px) {
        margin-left: 0px !important;
      }
    }

    .rubricaLight-search-view--form-control.form-control:focus {
      outline: none !important;
      border: 1px solid #e00125;
      box-shadow: none;
    }

    .custom-placeholder::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #ffffff;
    }

    .custom-placeholder:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ffffff;
      opacity: 1;
    }

    .custom-placeholder::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ffffff;
      opacity: 1;
    }

    .custom-placeholder:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ffffff;
    }

    .input-group-append {
      margin-left: auto;

      img {
        height: 40px;
        margin-left: 10px;
      }

      display: inherit;

      @media (min-width: $breakpoint-desktop) {
        display: none;
        margin-left: inherit;
      }
    }
  }

  &--input-group.force-display {
    .rubricaLight-search-view--form-control.form-control {
      display: inherit;
      background: transparent;
      color: #ffffff;
      z-index: 1;
      height: 47px;
      margin-left: -80px;

      @media (min-width: $breakpoint-mobile-landscape) {
        margin-left: -330px;
      }
    }
  }
}

.rubricaLight-popOver {
  border: 1px solid #ebebeb;
  border-radius: 0 !important;
  width: 400px;
  max-width: calc(100vw - 32px - 44px);
  transform: translate3d(7.1rem, 0px, 0px) !important;

  @media (min-width: 1024px) {
    will-change: transform;
  }

  @media (min-width: 639px) and (max-width: 1023px) {
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    margin-right: 5px;
  }

  @media (min-width: 200px) and (max-width: 374px) {
    margin-right: 0;
  }
}

.rubricaLight-popOver .header {
  border: none;
  border-radius: 0 !important;
  background-color: #e00125;
}

.rubricaLight-popOver .triangle-container {
  display: inline-block;
  margin: 13px 0 0 -10px;
  vertical-align: middle;
  position: absolute;

  @media (min-width: 639px) and (max-width: 768px) {
    left: 0px;
  }

  @media (min-width: 375px) and (max-width: 637px) {
    left: 0px;
  }

  @media (min-width: 320px) and (max-width: 374px) {
    right: 0px;
  }
}

.rubricaLight-popOver .triangle-caret {
  width: 10px;
  height: 10px;
  border-right: solid 10px #e00125;
  border-bottom: solid 10px transparent;
  border-top: solid 10px transparent;

  @media (min-width: 769px) and (max-width: 1023px) {
    border-right: solid 10px #e00125;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-left: none !important;
  }

  @media (min-width: 639px) and (max-width: 768px) {
    border-left: none !important;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-right: solid 10px #e00125 !important;
  }

  @media (max-width: 638px) {
    border-left: none !important;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-right: solid 10px #e00125 !important;
  }

  @media (min-width: 375px) and (max-width: 637px) {
    border-right: solid 10px #e00125 !important;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-left: none !important;
  }

  @media (min-width: 200px) and (max-width: 374px) {
    display: none;
  }
}

.rubricaLight-popOver .body {
  background-color: #f6f6f6;
}

.groupped-options-container-rubrica-light {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin: 10px 0 0 0;
}

.groupped-options-container-rubrica-light .item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 2px solid #f6f6f6;
  min-height: 60px;
  justify-content: center;
}

.groupped-options-container-rubrica-light .item:last-child {
  border-bottom: none;
}

.groupped-options-container-rubrica-light .item:hover {
  background: #efeeee;
  color: #333333;
  cursor: pointer;
}

.groupped-options-container-rubrica-light .selectedOption {
  background: #efeeee;
  color: #333333;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
}

.rubricaLight-popOver-custom {
  border: 1px solid #ebebeb;
  border-radius: 0 !important;
  min-width: 400px;
  transform: translate3d(3.3rem, 0px, 0px) !important;

  @media (min-width: 1024px) {
    will-change: transform;
  }

  @media (min-width: 639px) and (max-width: 1023px) {
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    margin-right: 5px;
  }

  @media (min-width: 200px) and (max-width: 374px) {
    margin-right: 0;
  }
}

.rubricaLight-popOver-custom .header {
  background-color: #e00125;
  border-radius: 0 !important;
}

.rubricaLight-popOver-custom .triangle-container {
  display: inline-block;
  margin: 13px 0 0 -10px;
  vertical-align: middle;
  position: absolute;

  @media (min-width: 639px) and (max-width: 768px) {
    left: 0px;
  }

  @media (min-width: 375px) and (max-width: 637px) {
    left: 0px;
  }

  @media (min-width: 320px) and (max-width: 374px) {
    right: 0px;
  }
}

.rubricaLight-popOver-custom .triangle-caret {
  width: 10px;
  height: 10px;
  border-right: solid 10px #e00125;
  border-bottom: solid 10px transparent;
  border-top: solid 10px transparent;

  @media (min-width: 769px) and (max-width: 1023px) {
    border-right: solid 10px #e00125;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-left: none !important;
  }

  @media (min-width: 639px) and (max-width: 768px) {
    border-left: none !important;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-right: solid 10px #e00125 !important;
  }

  @media (max-width: 638px) {
    border-left: none !important;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-right: solid 10px #e00125 !important;
  }

  @media (min-width: 375px) and (max-width: 637px) {
    border-right: solid 10px #e00125 !important;
    border-bottom: solid 10px transparent;
    border-top: solid 10px 10px #e00125;
    border-left: none !important;
  }

  @media (min-width: 200px) and (max-width: 374px) {
    display: none;
  }
}

.rubricaLight-popOver-custom .body {
  background-color: #f6f6f6;
}

.resize-search-icon {
  height: 70% !important;
  top: 10px !important;
  left: 40%;
  margin-left: auto;
}

.small-text-autocomplete {
  font-size: 16px;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

.groupped-options-list-rubrica-light {
  display: block;
  padding: 0px;
  margin: 0;
  max-height: calc(70vh - 60px - 64px);
  overflow-y: auto;
}

.bold-rubrica-light {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  padding: 2px 5px;
}

.light-text-rubrica-light {
  color: #9d9d9d;
  padding: 2px 5px;
}