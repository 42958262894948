.gray-box {
  padding-top: 44px;
  .first-row {
    display: flex;
    flex-wrap: nowrap;
    .box-header {
      margin-right: 2px;
      width: 16%;
      height: 70px;
      background-color: #eaefef;
    }
  }
  .second-row {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 25px;
    .box-left {
      margin-top: 20px;
      .box-left-body {
        background-color: #d3d3d3;
        height: 40px;
        margin-bottom: 12px;
      }
      .box-left-body-line-1 {
        background-color: #d3d3d3;
        height: 20px;
        margin-bottom: 20px;
      }
      .box-left-body-line-2 {
        background-color: #d3d3d3;
        height: 20px;
        width: 28%;
        margin-bottom: 7px;
      }
      .box-space-between {
        margin-top: 10px;
        background-color: #d3d3d3;
        height: 20px;
        width: 60%;
        margin-bottom: 7px;
        &.right {
          float: right;
        }
      }
      .box-left-body-line-3 {
        background-color: #eaefef;
        height: 60px;
        margin-bottom: 3px;
      }
    }
    .box-right-body {
      margin-right: 2px;
      width: 100%;
      height: 490px;
      background-color: #eaefef;
    }
  }
  .third-row {
    margin-top: 48px;
    height: 150px;
    background-color: #eaefef;
    margin-left: 40px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    .box {
      height: 150px;
      background-color: #d3d3d3;
      margin-left: -15px;
    }
    .box-left-body-line-1 {
      background-color: #d3d3d3;
      margin-top: 40px;
      height: 40px;
      width: 30%;
      margin-bottom: 10px;
    }
    .box-left-body-line-2 {
      background-color: #d3d3d3;
      height: 20px;
      width: 55%;
    }
  }
}
