.containerStepAcquistoNexi {
  margin: 24px 0 0 0;
  padding: 4px 0 18px 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

  .padding-horizontal {
    padding: 0 16px;
  }

  .section-title {
    margin: 20px 16px 0 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: $darkest-grey;
  }

  .section-title-condizioniCarta {
    margin: 20px 16px 0 16px;
    font-size: 16px;
    line-height: 18.75px;
    font-weight: 700;
    color: $dark-grey-accessibility;
  }

  .section-text {
    margin: 20px 16px 0 16px;
    font-size: 16px;
    line-height: 24px;
    color: $darkest-grey;
  }

  .documents-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .text-footer-step1 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: $darkest-grey;
    padding: 16px;
  }

  .errorePersonaPoliticamenteEsposta {
    margin: 20px 16px 0 16px;
    font-size: 16px;
    line-height: 24px;
    color: $red-monza;
  }

  .checkbox-group {
    .input-box {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.radio-group-inline.radio-button-box {
  margin: 2px 0 4px 0;

  .radio-button-label {
    display: block;
    padding: 0 8px 4px 0px;
    font-size: 1rem;
    line-height: 0.75rem;
  }

  .radio-button-row {
    display: inline-flex;
    padding-top: 0 !important;
  }
}

.riepilogoSenzaBackground {
  .riepilogo-section {
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
  }
}

.checkbox-error {
  padding: 8px;
  font-size: 16px;
  line-height: 16px;
  color: #b2001c;
}

.multi-cobagde-container {
  list-style: none;
  padding: 0;
  margin: 0;
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0px; 

  li {
    width: 230px;
    padding-top: 24px
  }

  img {
    width: 230px;
    height: 146px;
    border-radius: 12px;
    background-color: #3f3f3f;
    object-fit: cover;
  }

  .selection-button {
    margin-top: 1rem;
    margin-left: 2px;
    margin-right: 2px;
    width: 100%;

    button {
      width: 100%;
    }
  }
}