.widgetView-fabVocal {
  color: $black;
  background-color: $white;
  border-radius: 99px;
  // margin: 10;
  width: 70px;
  height: 70px;
  display: flex;
  // flexDirection: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  z-index: 11;
  right: 30px;
  box-shadow: 1px 1px 1px 2px #a9a9a9;
}

.iconView-fabVocal {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}