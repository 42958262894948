.body-bannerDX {
  color: $black;
  background-color: $darker-grey;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 15px;
}

.compact {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.txtView-bannerDX {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  background-color: $darker-grey;
}

.txtTitle-bannerDX {
  color: $black;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 15px;
  font-size: 18px;
  font-weight: $primary-font-weight;
  white-space: pre-line;
}

.txtDescription-bannerDX {
  color: $black;
  font-size: 16px;
  margin: 10px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  margin-top: -10px;
  white-space: pre-line;
}

.iconView-bannerDX {
  flex-grow: 0.1;
  border: 1px solid $bright-red;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: $bright-red;
}

.buttonView-bannerDX {
  align-items: center;
  justify-content: center;
  display: flex;
  padding-right: 20px;
}