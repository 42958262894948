.stepper {
  display: flex;
  flex-direction: column;
  //min-height: 36px;
  margin-bottom: 16px;
  width: 100%;
}

.stepper-text-container {
  display: flex;
  flex-direction: row;
}

progress.stepper {
  width: 100%;
  height: 4px;
  background-color: $dark-grey-bottom;
  border: none;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 10px;
}

progress.stepper::-webkit-progress-value {
  background-color: $red-monza;
  transition: width 0.3s ease;
}

progress.stepper::-moz-progress-bar {
  background-color: $red-monza;
  transition: width 0.3s ease;
}

.stepper-item {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 12px 12px 0px 16px;
  //border-bottom: solid 3px $red-monza;

  .stepper-item-label {
    flex: 1;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    color: $dark-grey-accessibility;
    text-align: center;
  }

  &:first-child {
    .stepper-item-label {
      text-align: left;
    }
  }

  &:last-child {
    .stepper-item-label {
      text-align: right;
    }
  }

  &.stepper-item-selected {
    .stepper-item-label {
      color: $darkest-grey;
      font-weight: bold;
    }

    &~.stepper-item {
      //border-bottom: solid 2px $dark-grey-bottom;

      .stepper-item-label {
        font-weight: normal;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .stepper-item {
    margin-left: 1px;
    margin-right: 1px;

    &:not(.stepper-item-selected) {
      flex: 0 0 20px;

      .stepper-item-label {
        display: none;
      }
    }

    .stepper-item-label {
      text-align: center !important;
      white-space: nowrap !important;

      .stepper-item-label-number {
        display: none;
      }
    }
  }
}