.dossier-titoli_table-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin: 16px 0 24px 0;

  .input-control {
    flex: 1;
  }
}

.patrimonio-totaleInfo {
  padding: 14px 16px 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: var($color-sparkasse-concrete);
  border-top: 1px solid $color-sparkasse-asphalt;

  &_netto {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $darkest-grey;
    margin-bottom: 8px;

    &_value {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.45px;
      color: $bright-red;
      margin-left: 8px;
    }

    &_currency {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.3px;
      color: $darkest-grey;
      margin-left: 3px;
      align-self: center;
    }
  }
}

.widget-dossier {
  .txtTitle-widget-patrimonio {
    display: none;
  }

  .legendView1-widget-patrimonio {
    border-bottom: none;
    margin-bottom: 0;
  }

  .input-control,
  .form-field {
    margin: 0;
  }

  .riepilogo-value {
    margin-bottom: 16px;
  }
}

.dossier-titoli-charts {
  margin: 16px 0;

  .widget-patrimonio {
    position: relative;
    margin-bottom: 48px;

    .chartView-widget-patrimonio {
      margin: 50px 0 24px 0;
      flex-direction: column;

      @media (min-width: $breakpoint-mobile-landscape) {
        flex-direction: row;
        margin-top: 24px;
      }
    }

    .widget-patrimonio-button {
      position: absolute;
      top: 40px;
      right: 0;
    }

    .widget-patrimonio-select {
      position: absolute;
      top: 20px;
      right: 0;
    }

    .txtTitle-widget-patrimonio {
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      color: $dark-grey-accessibility;
      text-transform: uppercase;
    }

    .legendView1-widget-patrimonio {
      margin-left: 0;
      margin-right: 0;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}