.body-bannerDX-light {
  color: $darkest-grey;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 15px;
}

.txtView-bannerDX-light {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 290px;
}

.txtTitle-bannerDX-light {
  color: $dark-grey-accessibility;
  font-size: 20px;
  font-weight: $primary-font-weight;
  white-space: pre-line;
  margin-top: 15px;
}

.txtDescription-bannerDX-light {
  color: $darkest-grey;
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: -10px;
  white-space: pre-line;
}

.iconView-bannerDX-light {
  flex-grow: 0.1;
  border: 1px solid $bright-red;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: $bright-red;
}

.buttonView-bannerDX-light {
  align-items: center;
  justify-content: center;
  display: flex;
  padding-right: 20px;
}