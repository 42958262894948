.notificheText {
  background-color: $asphalt;
  padding: 96px 14px 0 15px;

  @media (max-width: 786px) {
    padding: 30px 0 20px 20px;
  }
}

.titoloLeft {
  margin: 0 0 24px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: $light-grey;

  &>p {
    margin-bottom: 0;
  }
}

.paragraphLeft {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $light-grey;
}

.titolo {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $darkest-grey;
}

.partsTitolo {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $darkest-grey;
  margin-left: 11px;
}

.partParagraph {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 18px;
}

.messagioNotifice {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $darkest-grey;
  margin-top: 15px;
}

.parts {
  background-color: $light-grey;
  padding: 14px 0;
}

.first-part {
  padding: 14px 0 190px;
  margin-bottom: 10px;
}

.red-rectangle {
  width: 15px;
  height: 5px;
  background-color: $red-monza;
  margin-top: 8px;
}

.notifice-legenda {
  font-weight: normal;
  margin: 10px;
  font-size: 16px;

  &-item {
    display: flex;
    flex-direction: row;
    padding-top: 10px;

    label.content-main-section--right-bar-small-caption {
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
    }
  }
}

.link-sito-pubblico {
  display: flex;
  justify-content: right;

  a {
    color: $red;
  }
}