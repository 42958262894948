.gestione-credenziali-item {
  display: flex;
  flex-direction: row;
  &-icon {
    margin-right: 12px;
  }
  &-desc {
    flex: 1;
  }
}

.gestione-credenziali-bold {
  font-weight: bold;
}

.gestione-credenziali-image-on-right {
  flex-direction: row-reverse;
}

.image {
  width: 180px;
  height: 124px;
}
