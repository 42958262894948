.widgetView-button-item-menu {
  color: $black;
  background-color: $white;
  border-radius: 2;
  margin: 5px;
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;
}

.txtCaption-button-item-menu {
  // flex: 1 ;
  // textAlign: center ;
  vertical-align: center;
  // display: flex ;
  // border: 1px solid red ;
  margin-left: 10px;
  margin-top: -5px;
  color: $primary-color;
  font-size: $section-font-size;
  font-weight: $section-font-weight
}

.iconView-button-item-menu {
  // flexGrow: 0.1 ;
  // border: 1px solid red ;
  margin: 10px;
  // alignItems: center ;
  // justifyContent: center ;
  // display: flex ;
  // flexDirection: column ;
}