.main-header {
  z-index: 20;
  grid-column: 1;
  -ms-grid-column: 1;
  grid-row: 1;
  -ms-grid-row: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);

  &.header-principale {
    height: 64px;
    position: fixed;
  }

  .ham-menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: $menu-sidebar-width;
    background: $red-monza;
    transition: all 0.2s;
    border: none;

    &:hover {
      cursor: pointer;
      background: $red-active;
    }
  }

  .session-handler {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: $white;
    top: 0;
    left: 0;
  }

  .header-logo-search-container {
    position: relative;
    flex: 1;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;

    @media (min-width: $breakpoint-tablet) {
      padding: 0 0 0 16px;
    }

    .bank-logo {
      display: none;
      margin: 0 16px 0 0;

      &:hover:not(.disabled) {
        cursor: pointer;
      }

      &.small {
        display: inherit;

        @media (min-width: $breakpoint-tablet) {
          display: none;
        }
      }

      &.big {
        display: none;

        @media (min-width: $breakpoint-tablet) {
          display: inherit;
        }
      }
    }

    .header-search-bar {
      flex: 1;

      @media (min-width: $breakpoint-tablet) {
        margin-right: 0;
      }

      * {
        margin: 0;
      }

      input {
        padding: 0 0 0 16px;
      }

      .search-view {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 44px;
        transition: background-color, box-shadow 0.2s ease-in-out;

        &.input-control-text-focused {
          position: absolute;
          top: 0;
          left: calc(-#{$menu-sidebar-width} + 16px);
          right: 0;
          bottom: 0;
          width: calc(100% + #{$menu-sidebar-width} - 32px);

          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
          background-color: $light-grey;

          @media (min-width: $breakpoint-tablet) {
            left: 16px;
            width: calc(100% - 16px);
          }

          @media (min-width: $breakpoint-desktop) {
            position: relative;
            left: 0;
            width: 100%;
          }
        }

        &:not(.input-control-text-focused) {
          .search-view--input-group {
            box-shadow: none;
            background-color: $white;

            .input-control {
              box-shadow: none;
            }

            @media (min-width: $breakpoint-tablet) {
              background-color: $light-grey;
            }

            input {
              &::placeholder {
                color: transparent !important;

                @media (min-width: $breakpoint-tablet-landscape) {
                  color: $dark-grey-accessibility !important;
                }
              }
            }
          }
        }

        &--input-group {
          height: 100%;

          .form-field__input {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
          }

          .input-control.input-control-text {
            .form-field__control {
              display: flex;

              input {
                border: none;
                color: $darkest-grey;

                &::placeholder {
                  color: $dark-grey-accessibility;
                }
              }

              svg {
                fill: $dark-grey-accessibility;
                min-width: 44px;
              }
            }
          }

          .input-group-append {
            margin-left: auto;

            img {
              height: 40px;
              margin-left: 10px;
            }

            display: inherit;

            @media (min-width: $breakpoint-desktop) {
              display: none;
              margin-left: inherit;
            }
          }
        }

        &--input-group.force-display {
          .input-control.input-control-text {
            display: inherit;
            background: $white;
            z-index: 1;
            height: 47px;
            margin-left: -80px;

            @media (min-width: $breakpoint-mobile-landscape) {
              margin-left: -330px;
            }
          }
        }

        .search-result {
          transform: translate3d(0px, 44px, 0px) !important;
          background-color: $white;
          width: 100%;
          box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
          opacity: 1;
          transition: background-color, box-shadow 0.3s ease-in-out;

          .single-searchItem {
            cursor: pointer;
            padding: 0 8px;
          }

          ul.listItems {
            padding-inline-start: 0;
            margin-bottom: 0;
            list-style: none;

            .noItems {
              padding: 18px 16px 10px;
            }
          }

          .listItems li:focus {
            outline: none !important;
            background-color: $light-grey;
          }

          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .header-buttons-container {
    display: flex;
    flex-direction: row;
    height: 100%;

    .header-buttons {
      display: none;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-left: 0;

      font-size: 16px;
      line-height: 16px;
      font-weight: bold;

      button {
        border: none;
        background-color: transparent;
        width: 100%;
        height: 100%;
      }

      div {
        cursor: pointer;
      }

      .has-notification {
        position: relative;

        &::before {
          content: "";
          display: block;
          position: absolute;
          bottom: 12px;
          right: 4px;
          background-color: $color-sparkasse-monza;
          width: 12px;
          height: 12px;
          border-radius: 8px;
        }
      }

      .is-selected {
        position: relative;

        &::after {
          content: "";
          z-index: 3;
          display: block;
          position: absolute;
          bottom: -16px;
          right: 11px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 16px 16px 16px;
          border-color: transparent transparent $asphalt transparent;
        }
      }

      &.header-buttons-first-group {
        display: none;
        margin: 0 12px;
        height: 100%;

        @media (min-width: $breakpoint-tablet) {
          display: inherit;
        }

        button {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 100%;
          width: inherit;

          padding: 0 4px;

          &:hover {
            background: $light-grey;
          }
        }
      }

      &.header-buttons-second-group {
        display: none;
        border-left: 1px solid $asphalt;

        @media (min-width: $breakpoint-tablet-landscape) {
          display: inherit;
        }

        button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 74px;
        }

        .header-change-language {
          &:hover {
            background: $light-grey;
          }
        }

        .header-logout {
          background: $red-monza;

          svg {
            color: $white;
          }

          &:hover {
            background: $red-active;
            transition: all 0.2s;
          }
        }
      }
    }
  }
}

.tooltip-firma-proposte {
  position: relative;
  padding-right: 32px;

  &:hover {
    cursor: pointer;
  }

  .tooltip-testo {
    text-align: left;
  }

  .tooltip-chiudi {
    position: absolute;
    top: 0;
    right: -8px;
  }
}