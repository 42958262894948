.menu-container {
  grid-column: 1;
  -ms-grid-column: 1;
  grid-row: 1;
  -ms-grid-row: 1;
  height: 100%;

  overflow-y: scroll;
  -ms-overflow-style: none; //ie
  scrollbar-width: none; //ff

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &.ul-submenu {
      padding: 0 24px;

      a:hover {
        text-decoration: none;
      }
    }
  }
}

.menu-bar {
  display: none;
  width: $menu-sidebar-width;
  height: calc(100vh - #{$menu-topbar-height});
  background-color: $asphalt;

  overflow-y: scroll;
  -ms-overflow-style: none; //ie
  scrollbar-width: none; //ff

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $breakpoint-tablet-landscape) {
    display: inherit;
  }
}

.menu-bar-expanded {
  position: relative;
  height: calc(100vh - #{$menu-topbar-height});
  width: 366px;
  max-width: 100%;
  background-color: $asphalt;
  padding-bottom: 193px; // TODO not the best solution, but works for now

  overflow-y: scroll;
  -ms-overflow-style: none; //ie
  scrollbar-width: none; //ff

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $breakpoint-tablet-landscape) {
    padding-bottom: 0; // TODO not the best solution, but works for now
  }
}

.menu-wrapper {
  z-index: 2;
  position: fixed;
  top: $menu-topbar-height;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  &:hover {
    cursor: pointer;
  }

  &.menu-desktop-mode {
    display: none;

    @media (min-width: $breakpoint-tablet-landscape) {
      display: block;
    }
  }

  &.menu-mobile-mode {
    display: block;

    @media (min-width: $breakpoint-tablet-landscape) {
      display: none;
    }
  }

  @media (min-width: $breakpoint-tablet-landscape) {
    width: calc(100% - #{$menu-sidebar-width});
    left: $menu-sidebar-width;
  }
}

.menu-main-wrapper {
  z-index: 2;
  position: fixed;
  top: $menu-topbar-height;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  &:hover {
    cursor: pointer;
  }
}

.menu-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 4px 16px 4px;
  border: none;
  border-bottom: solid 1px $darkBackground;
  background-color: transparent;
  width: 100%;

  &:not(.menu-row-selected):hover {
    cursor: pointer;
    transition: all 0.2s;
    background-color: #2a2a2a;
    box-shadow: inset 0 4px 12px 2px rgba(0, 0, 0, 0.3);
  }
}

.menu-row-expanded-header {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px $darkBackground;

  @media (min-width: $breakpoint-tablet) {
    display: none;
  }

  button {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 96px;
    background-color: #2a2a2a;
    border: none;

    img {
      color: $white;
    }

    &:not(:last-child) {
      border-right: solid 1px $darkBackground;
    }

    &:hover {
      cursor: pointer;
      transition: all 0.2s;
      background-color: $darkBackground;
      box-shadow: inset 0 4px 12px 2px rgba(0, 0, 0, 0.3);
    }

    &.has-notification {
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 24px;
        right: 36px;
        background-color: $color-sparkasse-monza;
        width: 12px;
        height: 12px;
        border-radius: 8px;
      }
    }
  }
}

.menu-row-expanded {
  display: flex;
  flex-direction: row;
  border: none;
  border-bottom: solid 1px $darkBackground;
  background-color: transparent;
  width: 100%;
  align-items: center;

  &:hover {
    cursor: pointer;
    transition: all 0.2s;
    background-color: #2a2a2a;
    box-shadow: inset 0 4px 12px 2px rgba(0, 0, 0, 0.3);
  }

  .menu-row-icon {
    display: flex;
    align-items: center;
    padding: 26px;
  }

  .menu-row-desc {
    display: flex;
    align-items: center;
    padding: 24px;

    font-size: 24px;
    font-weight: bold;
    color: $white;
  }
}

.menu-row-expanded-floating {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 366px;
  background-color: $white;
  box-shadow: 0 -8px 10px 2px rgba(0, 0, 0, 0.3);

  .menu-row-expanded {
    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: $light-grey;
    }

    .menu-row-desc {
      color: $asphalt;
    }
  }

  @media (min-width: $breakpoint-tablet-landscape) {
    display: none;
  }
}

.menu-row-selected {
  background-color: $darkBackground;
}

.menu-row-label {
  width: 100%;
  margin-top: 4px;
  color: $white;
  font-size: 16px;
  letter-spacing: -0.4px;
  text-align: center;
}

.menu-window-body {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
  grid-template-columns: 50% 50%;
  grid-auto-rows: calc(128px + 16px);
  height: calc(100vh - #{$menu-topbar-height} - 82px - 24px);
  padding: 0 24px;

  overflow-y: scroll;
  -ms-overflow-style: none; //ie
  scrollbar-width: none; //ff

  &::-webkit-scrollbar {
    display: none;
  }
}

.menu-window-header {
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 32px 24px;
  height: 92px;

  font-size: 20px;
  font-weight: bold;
  color: $white;

  span {
    &:hover {
      cursor: pointer;
    }
  }
}

.frecciaSinistra {
  margin-right: 16px;
  display: inline;
}

.menu-element-icon {
  &.has-notification {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0px;
      left: 28px;
      background-color: $color-sparkasse-monza;
      width: 12px;
      height: 12px;
      border-radius: 8px;
    }
  }
}

.menu-element-label {
  margin-top: 4px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  color: $asphalt;
  word-break: break-word;
}

.menu-window-element {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(128px + 16px);
  background-color: $white;
  border: 0 solid transparent;
  background-clip: padding-box;

  &:nth-child(odd) {
    -ms-grid-column: 1;
    grid-column: 1;
    border-width: 0 8px 16px 0;
  }

  &:nth-child(even) {
    -ms-grid-column: 2;
    grid-column: 2;
    border-width: 0 0 16px 8px;
  }

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      grid-row: ceil($i/2);
      -ms-grid-row: ceil($i/2);
    }
  }

  svg {
    min-height: 44px;
  }

  a,
  button {
    width: 100%;
    height: 100%;
    padding: 16px;
    text-align: left;
    padding-bottom: 50px;
    background-color: transparent;
    border: none;
  }

  &.selectedElement-LVL2 {
    background-color: $darkBackground;

    .menu-element-label {
      color: $white;
    }
  }

  &:not(.selectedElement-LVL2):hover {
    cursor: pointer;
    transition: all 0.1s;
    background-color: $light-grey;
    box-shadow: inset 0 4px 12px 2px rgba(0, 0, 0, 0.3);
  }
}

.menu-item-corner {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
  border-right: solid 2px $red-monza;
  border-bottom: solid 2px $red-monza;
}

.corner-reverse {
  top: 0;
  left: 0;
  right: unset;
  bottom: unset;
  transform: rotate(180deg);
}

.menu-window-LVL3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 366px;
  height: 100vh;
  background-color: $darkBackground;

  @media (min-width: $breakpoint-tablet-landscape) {
    width: 421px;
    left: 421px;
  }
}

.menu-window {
  width: 366px;
  max-width: 100%;
  height: calc(100vh - #{$menu-topbar-height});
  background-color: $darkBackground;
  cursor: default;

  @media (min-width: $breakpoint-tablet-landscape) {
    width: 421px;

    .frecciaSinistra {
      display: none;
    }
  }

  &.menu-window-minimized {
    display: none;
    background-color: $asphalt;

    @media (min-width: $breakpoint-tablet-landscape) {
      display: inherit;
    }
  }
}

.menu-container {
  .menu-wrapper-area {
    z-index: 2;
    position: fixed;
    left: 0;
    top: $menu-topbar-height;
    width: 100%;
    height: calc(100% - #{$menu-topbar-height});
    background-color: rgba(0, 0, 0, 0.4);

    &.sidebar-area {
      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
    }

    .menu-window-area {
      z-index: 2;
      width: 366px;
      max-width: 100%;
      height: calc(100vh - #{$menu-topbar-height});
      cursor: default;
      background-color: $darkBackground;

      &.topbar-area {
        @media (min-width: $breakpoint-tablet) {
          position: absolute;
          right: 16px;
          top: 16px;
          height: auto;
          padding-bottom: 26px;
          background-color: $asphalt;

          .menu-window-body {
            height: auto;
            max-height: calc(100vh - #{$menu-topbar-height} - 82px - 32px - 24px);
          }
        }

        @media (min-width: $breakpoint-tablet-landscape) {
          width: 421px;
        }

        @media (min-width: $breakpoint-tablet) {
          .frecciaSinistra.first-level {
            display: none;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.menu-area-open {
    width: 100%;
  }

  &.menu-container-expanded {
    width: 100%;

    .menu-wrapper-area {
      @media (min-width: $breakpoint-tablet) {
        left: 0;
        width: 100%;
      }

      &~.menu-main-wrapper {
        display: none;
      }
    }

    .menu-main-wrapper {
      display: inherit;
    }

    .menu-bar {
      display: none;
    }

    .menu-wrapper {
      display: none;
    }
  }

  &:not(.menu-container-expanded) {
    .menu-wrapper-area {
      @media (min-width: $breakpoint-tablet) {
        left: 0;
        width: 100%;
      }

      @media (min-width: $breakpoint-tablet-landscape) {
        left: $menu-sidebar-width;
        width: calc(100% - #{$menu-sidebar-width});
      }
    }

    .menu-main-wrapper {
      display: none;
    }

    // .menu-wrapper {
    //   display: inherit;
    // }
  }
}