.firma-proposte {
  justify-content: space-between;
}

.firma-proposte-text {
  padding-top: 20px;
}

.firma-status {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
  color: $darkest-grey;
}

.firma-label {
  margin-top: 6px;
}