.carousel-wrapper {

  &.banner-dx-carousel,
  &.banner-home-carousel {
    min-height: 120px;
    padding-bottom: 0;

    .view-header {
      display: none;
    }

    .button-general-standard {
      text-transform: uppercase;
    }

    .banner-body {
      cursor: default;
    }
  }

  &.banner-dx-carousel {
    margin-bottom: 32px !important;
    min-height: 120px;
    background-color: inherit;

    &-light {
      .carousel-view {
        background-color: $light-grey;
      }
    }

    .view-header {
      display: none;
    }

    .body-bannerDX,
    .body-bannerDX-light {
      cursor: default;
      width: 338px;
      margin: 0;
      height: 100%;
      min-height: 120px;
      margin-top: 75px;

      @media (max-width: $breakpoint-tablet) {
        margin-top: 15px;
      }
    }

    .body-bannerDX.con-immagine,
    .body-bannerDX-light.con-immagine {
      .iconView {
        margin-bottom: 30px;
        background-color: inherit;

        img {
          border: 1px solid $light-grey;
          width: 304px;
          height: 88px;
          object-fit: contain;
        }
      }
    }
  }
}

.banner-body {
  background-color: $white;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
  }

  .banner-icon-view-img {
    fill: $black;
    color: $white;
    max-height: 200px;
    max-width: 100%;

    @media (min-width: $breakpoint-tablet) {
      max-height: 120px;
      max-width: 100%;
    }
  }
}

.txtView {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 120px;
}

.txtTitle {
  flex: 1;
  margin-left: 30px;
  margin-top: 16px;
  margin-bottom: 5px;
  font-size: $secondary-font-size;
  font-weight: $section-font-weight;
  line-height: 1;
}

.txtTitle.titoloBanner {
  margin-top: 32px;
  margin-bottom: 0;
}

.txtDescription {
  flex: 1 1;
  margin-left: 30px;
  line-height: 1;
  margin-bottom: 16px;
}

.iconView {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: $light-grey; //#-b2001-c;
}

.buttonView {
  align-items: center;
  justify-content: center;
  // display: flex;
  max-width: 20em;
  padding-right: auto;
  padding-left: auto;
  align-self: center;

  >a {
    text-decoration: none;
  }

  @media (min-width: $breakpoint-tablet) {
    padding-right: 20px;
    padding-left: 20px;
  }
}