.contatti-title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: $asphalt;
}

.contatti-number {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: $asphalt;
  font-size: 24px;
}

.contatti-giorni-apertura {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $darkest-grey;
}

.grow-1 {
  flex-grow: 1;
}

.contatti-card-padding {
  padding: 1% 2%;
}

.direction-row {
  flex-direction: row;
}

.contatti-align-icon {
  margin: auto 0 auto 1%;
}

.motivo-richiesta-box {
  padding: 1%;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
}

.pl-8px {
  padding-left: 8px;
}

.pv-1pc {
  padding: 1% 0;
}

.height45px {
  height: 45px;
}

.input-allegato {
  display: flex;
  flex-direction: row;
}