.central-step3 {
  // border: 1px solid black;
  display: flex;
  // flex: 1;
  flex-direction: row;
  flex-grow: 1;
}

.main-step3 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // margin-left: 90;
}

.captionView-step3 {
  display: flex;
  flex-direction: row;
  // flex: 1;
  padding-left: 5px;
  margin-top: 20px;
}

.txtCaption-step3 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $dark-grey-accessibility;
}

.esito-step3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 25% 16px 16px;

  background-color: $light-grey;
  background: rgb(240, 240, 240);
  background: url("./../assets/icons/aeroplanino.png"),
    linear-gradient(90deg, rgba(240, 240, 240, 1) 0%, rgba(254, 254, 254, 1) 50%, rgba(240, 240, 240, 1) 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}

.esito-step3-oneClick {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #dfdfdf;
  padding: 15px;
  background-image: url("./../assets/icons/one_click_banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.esito-step3-bolloACI {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #dfdfdf;
  padding: 15px;
  background-image: url("./../assets/icons/bollo_aci_banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.esitoRevoca-step3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #dfdfdf;
  padding: 16px 30% 16px 16px;
  background-image: url("../assets/icons/paper-plane-airplane-red-red-paper-airplane.png"),
    linear-gradient(90deg, rgba(240, 240, 240, 1) 0%, rgba(254, 254, 254, 1) 50%, rgba(240, 240, 240, 1) 100%);
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
}

.esitoSospendi-step3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #dfdfdf;
  padding: 15px;
  background-image: url("../assets/icons/ripetitivo_banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.esitoBancomatPay-step3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #dfdfdf;
  padding: 15px;
  background-image: url("../assets/icons/bancomatPay_banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.esitoTxt1-step3 {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.esitoTxt2-step3 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #181818;
}

.section-step3 {
  display: flex;
  flex-direction: column;
  // flex: 1;
  background-color: #f6f6f6;
  padding-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
}

.line1-step3 {
  display: flex;
  flex-direction: row;
  // flex: 1;
  background-color: #f6f6f6;
  padding-bottom: 8px;
  // height: 80px;
  // border: 1px solid black;
}

.line1Col1-step3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
  height: 45px;
  // border: 1px solid black;
}

.line1Col2-step3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
  height: 45px;
  // border: 1px solid black;
}

.line2-step3 {
  display: flex;
  flex-direction: row;
  // flex: 1;
  background-color: #f6f6f6;
  padding-bottom: 8px;
  // height: 80px;
  // border: 1px solid black;
}

.line2Col1-step3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 45px;
  // border: 1px solid black;
}

.sospendi-date-container {
  margin-top: 35px;
}

.line1Col3-step3 {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 10px;
  height: 45px;
}

.esitoTxt2-step3-consensi {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  color: #181818;
  white-space: pre-line;
}