.widget-patrimonio {
  width: 100%;
}

.body-widget-patrimonio {
  width: 100%;
  // height: 60;
  display: flex;
  flex-direction: column;
  // justify-content: center;
}

.txtView-widget-patrimonio {
  flex-grow: 1;
  // margin: 15;
  // align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.txtTitle-widget-patrimonio {
  max-height: 30px;
  margin-bottom: 8px;
  margin-left: 16px;
  font-size: $secondary-font-size;
  font-weight: $section-font-weight;
}

.titleChartView-widget-patrimonio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}

.txtTitleChart-widget-patrimonio {
  margin-top: 5px;
  font-size: $section-font-size;
}

.txtAmountChart-widget-patrimonio {
  margin-top: -15px;
  font-size: $secondary-font-size;
  font-weight: $section-font-weight;
}

.txtDescription-widget-patrimonio {
  // flex: 1;
  margin-left: 10px;
  margin-top: -10px;
}

.chartView-widget-patrimonio {
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-mobile-landscape) {
    flex-direction: row;
  }
}

.legendView1-widget-patrimonio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid #cbcbcb;
  padding: 0 0 10px 0;
  margin: 0 16px 20px 16px;
}

.legendView1col1-widget-patrimonio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.legendView1col2-widget-patrimonio {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.legendView2-widget-patrimonio {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #cbcbcb;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px;
}

.legendView2col1-widget-patrimonio {
  display: flex;
  // flex: 1;
  align-items: center;
  justify-content: center;
}

.legendView2col2-widget-patrimonio {
  display: flex;
  // flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.txtTitleLegend-widget-patrimonio {
  // flex: 1;
  display: flex;
  margin-bottom: 0;
  font-size: $section-font-size;
}

.txtAmountLegend-widget-patrimonio {
  // flex: 1;
  display: flex;
  margin-bottom: 0;
  font-size: $secondary-font-size;
  font-weight: $section-font-weight;
  text-align: end;
}

/*
* Override stili del widget della pagina quando in home
*/
.widget-patrimonio-home {
  .widgets-patrimonio-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-mobile-landscape) {
      flex-direction: row;
    }

    .patrimoniText,
    .patrimonio-totaleInfo,
    .txtTitle-widget-patrimonio,
    .widget-patrimonio_details_icon {
      display: none;
    }

    .txtAmountChart-widget-patrimonio {
      font-size: 28px;
    }

    .txtAmountChart-widget-patrimonio_value,
    .patrimonio-totaleInfo_netto_currency {
      font-size: 16px;
      font-weight: normal;
      display: contents;
    }

    .legendView1-widget-patrimonio {
      border-bottom: none;
      margin-bottom: 0;
    }

    .txtAmountLegend-widget-patrimonio {
      font-size: 18px;
    }

    .widget-patrimonio-no-data {
      display: none;
    }

    .widget-patrimonio-twin {
      flex: 1;

      .chartView-widget-patrimonio {
        flex-direction: column;
        align-items: flex-start;

        > div[type="donut"] {
          transform: scale(0.6);
          min-height: 150px !important;
          max-height: 150px;
          margin-top: -32px;
          margin-bottom: -32px;
          margin-left: -32px;
        }
      }

      .legendView1-widget-patrimonio {
        flex-direction: column;
        align-items: flex-start;

        .legendView1col2-widget-patrimonio {
          margin-left: 24px;
        }
      }
    }
  }
}
