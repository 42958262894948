.page-link {
  color: #9d9d9d;
  background-color: unset;
  border-color: unset;
  border: none;
}

.page-link:hover {
  color: #9d9d9d;
  border-color: unset;
  border: none;
}

.page-item.active .page-link {
  color: #DF0025;
  background-color: unset;
  border-color: unset;
  border: none;
}