// import Colors from './generated/colors'

.body-widget-prestiti {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.txtView-widget-prestiti {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.titleChartView-widget-prestiti {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.widget-currency {
  font-size: 16px;
  font-weight: normal;
  display: contents;
}

.txtTitleChart-widget-prestiti {
  flex: 1;
  margin-left: 10px;
  margin-top: 5px;
  font-size: $section-font-size;
}

.txtAmountChart-widget-prestiti {
  margin-left: 10px;
  margin-top: -15px;
  font-size: 26px;
  font-weight: $section-font-weight;
}

.txtDescription-widget-prestiti {
  flex: 1;
  margin-left: 10px;
  margin-top: -10px;
}

.chartView-widget-prestiti {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $breakpoint-mobile-landscape) {
    flex-direction: row;
  }
}

.legendView1-widget-prestiti {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #cbcbcb;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.legendView1col1-widget-prestiti {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
}

.legendView1col2-widget-prestiti {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.legendView2-widget-prestiti {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #cbcbcb;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px;
}

.legendView2col1-widget-prestiti {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.legendView2col2-widget-prestiti {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.txtTitleLegend-widget-prestiti {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  margin-left: 8px;
}

.txtImportoLegend-widget-prestiti {
  flex: 1;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  margin-right: 16px;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}

.txtAmountLegend-widget-prestiti {
  display: flex;
  margin-bottom: 0;
  font-size: $secondary-font-size;
  font-weight: $section-font-weight;
  text-align: end;
}

.widget-info-col-prestiti {
  flex: 1;
  margin-top: 24px;

  &-label {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25;
    color: $darkest-grey;
  }

  &-value {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: $black;
    margin-top: 4px;
  }
}

.widget-placeholder {
  padding: 10px;
}

.widget-cta {
  margin-top: 16px;
  margin-right: 16px;
}