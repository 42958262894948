.variazione-canali {
  margin: 20px 0;

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $breakpoint-mobile-landscape) {
      flex-direction: row;
      align-items: center;
    }

    .title-container {
      display: flex;
      flex-direction: row;
      flex: 1 1;
    }
  }
}

.fondi-paperless-disclaimer {
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  padding: 0 16px;
  background-color: $bannerGreen;

  img {
    width: 75px;
    height: 93px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    word-break: break-word;
  }

  @media (min-width: $breakpoint-mobile-landscape) {
    padding: 0 64px;

    img {
      width: 100px;
      height: 124px;
    }
  }
}