.stepper-bonifico {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  height: 36px;
}

.bonificoIstantaneo-alert {
  background-color: $mercury;
  text-align: left;
  padding: 16px 12px;
}

.level3-bonifico {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 16px;
}

.widgetView-fabFavorite {
  display: flex;
}

@media (max-width: 768px) {
  .button-level-3 {
    overflow-x: scroll;
  }

  .text-button-level3 {
    white-space: nowrap;
  }

  .widgetView-fabFavorite {
    display: none !important;
  }

  .line2-step1 {
    display: flex;
    flex-direction: column !important;
  }

  .line2Col2-step1 {
    margin-left: 0 !important;
  }
}