.messaggio-container {
  display: flex;
  flex-direction: column;
  max-width: 788px;

  &-title {
    margin-top: 28px;
  }

  >h3,
  &-title h3 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: $black;
  }

  .description {
    max-width: 788px;
    margin-top: 5px;
    white-space: pre-line;

    >p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $darkest-grey;
    }
  }

  .button-text {
    margin-top: 28px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $darkest-grey;
  }

  .checkBox {
    margin-left: 14px;
  }
}