.cardContainer {
  display: flex;
  flex-direction: row;
  margin: 8px 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.left-side {
  display: flex;
  flex-direction: column;
  position: relative;
}

.right-side {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  flex: 1;

  @media (max-width: 768px) {
    padding: 20px 0;
  }
}

.saldiContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 16px;
}

.buttonContainerNexi {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 8px 16px;
}

.buttonContainerSmartCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 0px 8px 0px;
}

@media screen and (max-width: 768px) {
  .buttonContainerMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .buttonContainerMobile {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .buttonContainerMobile {
    display: none;
  }
}

.buttonContainer .textButton {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $darkest-grey;
  cursor: default;
}

.buttonContainer .textButton:hover {
  cursor: pointer;
}

.separatorContainer hr {
  margin: 0px;
}