.bancomat-pay {
  .central-step2 {
    .bancomat-pay-title-container {
      margin-top: 12px;
    }

    .scegli-conto {
      margin-top: 28px;
      font-weight: 300;
      line-height: 1.25;
      color: $black;
      display: flex;
      align-items: center;

      label {
        margin: auto 0 auto 6px;
      }
    }
  }

  .central-step3 {
    .bancomat-pay-title-container {
      margin-top: 28px;
    }

    .compact {
      margin: 0 !important;
    }
  }

  .bancomat-pay-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      >h3 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: $black;
      }
    }

    .image {
      display: flex;
      // flex-direction: column;
      // align-items: flex-end;
      // justify-content: flex-end;
      width: auto !important;
      height: auto !important;

      >img {
        width: 155px !important;
        height: auto !important;
      }
    }
  }

  .bancomat-pay-central-container {
    >p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $darkest-grey;
    }

    .text-button {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
    }

    .checkBox {
      margin-left: 14px;
    }
  }

  .compact-text {
    margin: 0 !important;
    padding: 0 !important;
  }

  .compact-container {
    margin: 0 0 5 0 !important;
    padding: 0 !important;
  }

  .bancomat-pay-progress-bar-container {
    background-color: $asphalt;
    display: flex;
    height: 30px;
    justify-content: flex-start;
    align-items: center;

    .bancomat-pay-progress-bar {
      background-color: $red-monza;
      height: 30px;
    }

    >span {
      color: $white;
      position: absolute;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      margin-left: 14px;
    }
  }

  .last-line-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 10px 0;

    .column-1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 1;
    }

    .column-2 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex: 1 1;

      >img {
        width: 144px;
        height: 144px;
      }
    }
  }

  .esito-label-custom {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $dark-grey-accessibility;
  }

  .bancomat-pay-text-container-central {
    max-width: 788px;
    width: 100%;

    >ol {
      padding: 0;
      counter-reset: item;

      >li {
        margin: 0;
        list-style-type: none;
        counter-increment: item;
      }

      >li::before {
        display: inline-block;
        width: 1em;
        padding-right: 0.5em;
        font-weight: bold;
        text-align: right;
        content: counter(item) ".";
      }
    }
  }

  .ml-10 {
    margin-left: 10px !important;
  }

  .transparent-bg {
    background-color: transparent !important;
  }

  .app-markets-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .app-markets-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .market-image {
      margin-left: 8px;

      >img {
        height: 40px;
        width: auto;
      }

      >img:hover {
        cursor: pointer;
      }
    }
  }
}

.slider-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .info-icon {
    margin-top: 10px;
  }
}

.rapporti-container {
  margin-bottom: 20px;

  .item-rapporti-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $medium-dark-grey;

    .checkbox-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .intestazione-container {
      flex-direction: column;
      flex: 1 1;
    }

    .iban-container {
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .text {
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $black;
    }
  }

  .spaced-item {
    padding: 14px 0;
  }
}

.rapporto-accredito-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .alias-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1 1;
  }

  .iban-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .text {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $black;
  }
}

.central-title-container {
  margin-top: 16px;
}