.caricamento-pagina-info-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $light-grey-alternative;
  padding: 15px;
  background-image: url('./../assets/icons/aeroplanino.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 132px;

  &-title {
    color: $black;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
  }

  &-paragraph {
    font-size: 16px;
    line-height: 1.5;
    color: $darkest-grey;
  }
}

.caricamento-pagina-info-section.caricamento-pagina-info-section-not-ready {
  background-image: none;
}