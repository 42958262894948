.modal-componente-autorizzativo.modal {
  z-index: 10000; // 9999 è loader

  .modal-header {
    padding: 0;

    .close {
      position: absolute;
      top: 9px;
      padding: 0;
      margin: 0;
      left: 14px;
      color: $white;
      opacity: 1;
      font-size: 2.0rem;
    }

    .modal-title {
      color: white;
      font-weight: bold;

      @media (max-width:$breakpoint-mobile ) {
        margin-left: 40px;
        font-size: 20px;
      }

      @media (max-width: 475px) {
        margin-left: 40px;
      }
    }
  }



  progress {
    border: 2px solid $red-monza
  }

  progress::-webkit-progress-bar {
    background: $red-monza !important;

  }

  progress::-webkit-progress-value {
    background: $red-monza !important;
  }

  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-dialog {
    max-width: calc(100vw - 1rem);

    @media (min-width: $breakpoint-tablet) {
      max-width: calc(#{$breakpoint-tablet} - 1rem);
    }

    @media (min-width: $breakpoint-tablet-landscape) {
      max-width: 816px;
    }
  }

  .modal-body {
    padding: 0 !important;
  }
}

.componente-autorizzativo-loader {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;

  img {
    width: 50px;
    margin: auto;
  }
}

.componente-autorizzativo {
  display: flex;
  flex-flow: column;
  min-height: 500px;
  overflow: auto;

  &-header {
    z-index: 0;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 56px;
    background-color: $asphalt;

    .icon-close-modal {
      position: absolute;
      left: 8px;
      width: 44px;
      height: 44px;
      top: 6px;
    }

    .icon-close-modal:focus {
      outline: 2px black solid !important;
    }

    h1 {
      z-index: 1;
      margin-bottom: 0;
      margin-left: 44px;
      padding: 16px;
      color: $white;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;

      @media (min-width: $breakpoint-tablet-landscape) {
        margin-left: 0;
      }
    }

    &-progress {
      height: 100%;
      left: 0;
      background-color: $red-monza;
      position: absolute;
      z-index: -1;
    }
  }

  &-body {
    z-index: 0;
    flex: 1;
    display: flex;
    padding: 24px 48px 24px 48px;
    justify-content: flex-start;
    flex-flow: column;

    @media (min-width: $breakpoint-tablet-landscape) {
      padding-bottom: 0;
    }

    &-azione {
      margin-bottom: 16px;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      color: $darkest-grey;
    }

    &-contesto {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      .bottone-contesto {
        margin: 0 auto;
        width: fit-content;
        width: -moz-fit-content;
      }

      .bottone-contesto:focus {
        outline: 2px black solid !important;
      }

      &>div {
        flex: 1;

        &:first-child {
          margin-bottom: 8px;
          margin-right: 0;
        }

        &:not(:first-child) {
          margin-top: 8px;
          margin-left: 0;
        }
      }

      @media (min-width: $breakpoint-tablet-landscape) {
        flex-direction: row;
        justify-content: center;

        &>div {
          flex: 1;

          &:first-child {
            margin-bottom: 0;
            margin-right: 8px;

            .bottone-contesto {
              margin-right: 0;
            }
          }

          &:not(:first-child) {
            margin-top: 0;
            margin-left: 8px;

            .bottone-contesto {
              margin-left: 0;
            }
          }
        }
      }
    }

    &-colonne {
      display: flex;
      flex-flow: column;

      @media (min-width: $breakpoint-tablet-landscape) {
        flex-flow: row;
        justify-content: space-between;
      }

      >div {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;

        &:not(:first-child) {
          margin-top: 32px;
          margin-left: 0;
          padding-top: 32px;
          padding-left: 0;
          border-top: 1px solid $dark-grey-bottom;
          border-left: none;

          @media (min-width: $breakpoint-tablet-landscape) {
            margin-top: 0;
            margin-left: 64px;
            padding-top: 0;
            padding-left: 64px;
            border-top: none;
            border-left: 1px solid $dark-grey-bottom;
          }
        }
      }
    }

    &-bottoni {
      margin: 0 !important;
      padding: 0 48px 32px 48px;
    }

    &-opzioni {
      &-descrizione {
        text-align: center;
        line-height: 1.5;
        color: $darkest-grey;
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
      }

      &-qr {
        position: relative;

        .qrcode {
          width: 250px;
          margin-top: 16px;
        }

        .genera-codice-qr {
          width: 230px;
          height: 100px;
          margin-top: 25px;
          margin-bottom: 16px;

          .sca-small-icon {
            width: 230px;
            height: 44px;
            padding: 0;
            margin: 0;
          }
        }

        &-oppure {
          position: absolute;
          display: block;
          padding: 8px 8px 12px 8px;
          left: 50%;
          top: 0;
          transform: translate(-50%, -50%);
          background-color: $white;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.71;
          color: $darkest-grey;

          @media (min-width: $breakpoint-tablet-landscape) {
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &-numeroverde {
        position: relative;

        .numero-verde {
          position: relative;
          width: 170px;
          height: 60px;
          margin-top: 25px;
          margin-bottom: 16px;

          .sca-small-icon {
            position: absolute;
            width: 170px;
            height: 44px;
          }

          &-sfondo {
            position: absolute;
            width: 170px;
            height: 60px;
          }

          &-testo {
            width: max-content;
            z-index: 1;
            position: absolute;
            top: 22px;
            left: 24px;
            font-size: 22px;
            font-weight: 900;
            line-height: 0.91;
            color: $black;
          }
        }

        .inserisci-codice {
          font-size: 16px;
          font-weight: 300;
          line-height: 1.33;
          color: $darkest-grey;
        }

        .codice {
          font-size: 16px;
          margin-top: 4px;
          margin-bottom: 16px;
          line-height: 1.25;
          color: $black;
        }
      }
    }

    &-estero {
      .inserisci-codice {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.33;
        color: $darkest-grey;
        margin: 40px auto 0 auto;
        text-align: center;
      }

      .codice {
        font-size: 23px;
        line-height: 1.25;
        color: $black;
        margin: 4px auto 16px auto;
        text-align: center;
      }
    }

    &-errore {
      margin-top: 16px;

      .componente-autorizzativo-body-opzioni-descrizione {
        margin-bottom: 16px;
      }
    }

    @media (min-width: $breakpoint-tablet-landscape) {
      min-height: 374px;
    }
  }
}

.componente-autorizzativo.token-sw,
.componente-autorizzativo.token-hw {
  .claim-title {
    font-weight: bold;
  }
}