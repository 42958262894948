.domandi-frequentati {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $darkest-grey;

  p span {
    white-space: normal !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    color: $darkest-grey !important;
  }
}

.faq-card-icon {
  display: flex;
  justify-content: center;
  margin: auto;
}

.faq-card-text {
  margin-top: 8px;
  // font-family: UniversLTStd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $darkest-grey;
}

.cta-allegato {
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
  color: $darkest-grey;
}

.faq-img {
  width: 100%;
  padding-bottom: 20px;
}

.faq-paragraph {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $darkest-grey;
}

.faq-span {
  font-weight: bold;
}

.page-faq {
  .accordion-title-tab {
    text-transform: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: transparent;
  }

}

.faq-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  color: $black;
}

.card-faq-viewer {
  display: flex;
  width: calc(200% + 7px);
  flex: 0 0 100%;

  &>div:nth-child(2) {
    color: $white;
    font-weight: 400;
    display: flex;
    justify-content: center;

    align-items: center;
    width: 50%;

    background: rgba(0, 0, 0, 0.85) !important;
    flex-direction: row;
    flex-wrap: wrap;

    &>span {
      flex-grow: 1;
      padding-left: 48px;
    }

    &>div {
      text-align: center;
      text-transform: uppercase;
      padding-right: 24px;

      &>span {
        margin-left: 30px;

        &:hover {
          cursor: pointer;
          color: $red-monza;
        }
      }
    }
  }

  &>.deletedItem {
    width: 50% !important;
    transition: all 0.3s;
    margin-left: -50% !important;
    position: relative;
    background: rgba(0, 0, 0, 0.85) !important;
    flex-direction: row;
    flex-wrap: wrap;
    display: none;

    &>span {
      flex-grow: 1;
      padding-left: 48px;
    }

    &>div {
      text-align: center;
      text-transform: uppercase;
      padding-right: 24px;

      &>span {
        margin-left: 30px;

        &:hover {
          cursor: pointer;
          color: $red-monza;
        }
      }
    }
  }

  &>div {
    flex-grow: 0;
    transition: all 0.3s;
    width: calc(50% - 24px);
  }
}

.cardFaq-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .cardFaq-item {
    // min-width: 250px;
    padding: 16px 8px;
    min-height: 120px;
    cursor: pointer;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: 30%;

    //  background-color: white;
    &:hover {
      box-shadow: 0 6px 16px 2px rgba(0, 0, 0, 0.16);
      background-color: $light-grey;
      color: $asphalt;
    }

    @media (max-width: 800px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .card-bozze-wrapper {
    box-shadow: none;
  }
}