.bonifico-myBank-header {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 15px 0;
}

.bonifico-myBank-text {
  width: 162px;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: $black;
  padding-top: 10px;
}

.bonifico-myBank-logo {
  width: 70px;
  height: 40px;
}