.scadenza-titoli-footer {
  -webkit-box-shadow: $light-shadow-bottom;
  -moz-box-shadow: $light-shadow-bottom;
  box-shadow: $light-shadow-bottom;
  margin-top: 5px;
  margin-bottom: 5px;
  color: $dark-grey-bottom;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  background-color: $white;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  border-top: 1px solid $asphalt;
  height: 54px;

  &-inizio,
  &-fine {
    display: flex;
    flex-direction: column;

    &-caption {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $black;
    }

    &-import {
      font-size: 20px;
      font-weight: bold;
      display: flex;
    }

    &-currency {
      font-size: 16px;
      font-weight: 300;
      color: $black;
      justify-content: center;
      align-items: center;
    }
  }

  &-fine {
    &-caption {
      text-align: right;
      font-weight: bold;
    }

    &-import {
      justify-content: flex-end;
    }
  }
}