.item-one-click-button {
  @extend .action-box;
  margin-left: 0;
}

.item-one-click-button:focus {
  outline: 2px solid;
  outline-offset: 2px;
}

.general-button {
  cursor: pointer;
}

.general-button.general-button-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}