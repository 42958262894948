.radio-button-label {
  color: $dark-grey-accessibility;
}

.radio-button-box {
  margin: 4px 0 8px 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.radio-button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.radio-group-input-number {
  .form-field {
    margin-bottom: 0;
  }
}

.input-radio-error {
  color: $bright-red;
  font-size: 16px;
  margin-top: -15px;
  padding: 8px;
  line-height: 16px;
}