.central {
  .titolo-wrapper {
    background-color: $light-grey;
    padding: 15px 24px 0 24px;
  }

  .titolo-wrapper>h3 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $dark-grey-accessibility;
  }

  .titolo-wrapper>p {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $black;
  }

  .elementi-non-disponibili {
    font-size: 16px;
    line-height: 24px;
    color: $darkest-grey;
  }

  .movimenti-non-disponibili {
    height: 64px;
    padding: 16px 0;
    display: flex;
    color: $dark-grey-accessibility;

    label {
      margin: auto;

      @media (min-width: $breakpoint-tablet) {
        margin: inherit;
      }
    }
  }

  .footer-saldi {
    z-index: 1;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 0;
    width: calc(100vw - 32px);
    margin: 0 -16px;
    padding: 18px 16px 30px 16px;
    color: $dark-grey-accessibility;
    background-color: $white;
    box-shadow: $heavy-shadow-top;
    border-top: 2px solid $asphalt;

    @media (min-width: $breakpoint-tablet) {
      max-width: calc(768px - 32px);
    }

    @media (min-width: $breakpoint-tablet-landscape) {
      max-width: calc(816px - 32px);
    }

    &-inizio,
    &-fine {
      display: flex;
      flex-direction: column;

      &-caption {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: $black;
        margin-bottom: 4px;
      }

      &-import {
        display: flex;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
        align-items: baseline;
      }

      &-currency {
        font-size: 16px;
        font-weight: normal;
        color: $darkest-grey;
        margin-left: 6px;
      }

      .outcoming {
        color: $color-negative-amount;
      }

      .incoming {
        color: $color-positive-amount;
      }
    }

    &-fine {
      &-caption {
        text-align: right;
      }

      &-import {
        justify-content: flex-end;
      }
    }
  }
}

.container_select_divisa {
  flex-direction: row;
  display: flex;
}

.select_divisa_position {
  float: left;
  flex: 1;
}

.saldo_contabile_label {
  text-align: right;
  float: right;
  font-weight: bold;
}

.saldi_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.saldo_disponibile_label {
  text-align: right;
  float: right;
}

.dati-aggiuntivi-show {
  max-height: inherit;
  height: inherit;
  border-bottom: 1px solid #999;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.dati-aggiuntivi-hide {
  max-height: 0;
  height: 0;
  overflow: hidden;
  margin-bottom: 16px;
}

@keyframes effect {
  0% {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  }

  20% {
    box-shadow: 0px 4px 10px 0px rgba(248, 145, 145, 0.5);
  }

  100% {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  }
}

.highlight {
  -webkit-animation-name: effect;
  -webkit-animation-duration: 2s;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
}