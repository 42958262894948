.text {
  white-space: pre-line;
  word-break: break-word;

  &.titolo {
    color: $darkest-grey;
    font-size: 32px;
    letter-spacing: -0.32px;
    font-family: "Bold";
  }

  &.corsivo {
    color: $darkest-grey;
    font-size: 16px;
    letter-spacing: -0.14px;
    font-style: italic;
    font-family: "Light";
  }

  &.suggerimento {
    color: $mercury;
    font-size: 16px;
    letter-spacing: -0.12px;
    font-family: "Bold";
  }

  &.testo {
    color: $darkest-grey;
    font-size: 16px;
    line-height: 20px;
    font-family: "Regular";

    &.disabled {
      color: $dark-grey-bottom;
    }
  }

  &.testo-leggero {
    color: $dark-grey-bottom;
    font-size: 16px;
    line-height: 24px;
    font-family: "Regular";
  }

  &.testo-piccolo {
    color: $darkest-grey;
    font-size: 16px;
    line-height: 18px;
    font-family: "Regular";
  }

  &.intestazione {
    font-size: 18px;
    line-height: 24px;
    color: $darkest-grey;
    font-family: "Bold";

    &.disabled {
      color: $dark-grey-bottom;
    }
  }

  &.titolo-piccolo {
    font-size: 20px;
    color: $dark-grey-bottom;
    ;
    font-family: "Bold";
  }

  &.sottotitolo {
    color: $dark-grey-bottom;
    font-size: 16px;
    line-height: 16px;
    font-family: "Bold";
  }

  &.small {
    color: $darkest-grey;
    font-size: 16px;
    line-height: 16px;
    font-family: "Light";
  }

  &.grassetto {
    color: $darkest-grey;
    font-size: 16px;
    line-height: 20px;
    font-family: "Bold";
  }

  &.errore {
    color: $error;
    font-size: 16px;
    font-family: "Regular";
  }

  &.menu {
    color: $white;
    font-size: 13px;
    font-family: "Regular";
  }
}