.servizi-soggetti {
  background-color: $light-grey;
}

.sezione-informativa {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.massimali-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
  list-style-type: none;
  padding: 0;
  width: 100%;
}