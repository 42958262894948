.selectBox-container {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: flex-start;
}

.selectBox-item {
  display: flex;
  flex-direction: column;
  flex: auto;
  align-items: center;
  justify-content: center;
  background-color: $white;
  width: 100%;
  max-width: 120px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $dark-grey-bottom;
  height: 48px;

  &:hover {
    cursor: pointer;
    background-color: $red-monza;
    color: $white;
  }
}

.item-selected {
  background-color: $red-monza;
  color: $white;
}

.error-message-container {
  flex-basis: 100%;
}

.error-message-container>span {
  color: $bright-red;
  font-size: 16px;
  line-height: 16px;
}

.selectBox-descrizione {
  padding: 0 0 5px;
}

.selectBox-operatore {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: auto;
  align-items: center;
  justify-content: center;
  background-color: $white;
  width: 100%;
  max-width: 140px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $dark-grey-accessibility;
  height: 78px;

  &:hover {
    cursor: pointer;
    -webkit-filter: grayscale(0%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    background-color: $light-grey;
  }

  @media (max-width: 321px) {
    max-width: 100%;
    margin-right: 0px;
  }
}

.selectBox-operatore .logo {
  height: 80px;
  width: auto;
}

.selectBox-operatore .greyscale {
  -webkit-filter: grayscale(100%) brightness(0.4);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) brightness(0.4);
  opacity: 0.8;
}

.selectBox-operatore .greyscale:hover {
  -webkit-filter: grayscale(0%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  opacity: 1;
}

.operatore-selected {
  -webkit-filter: grayscale(0%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  opacity: 1;
}