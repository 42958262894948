.loader {
  z-index: 9999;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  .loader_img {
    display: block;
    width: 50%;
    max-width: 200px;
  }
}

body.modal-open .loader {
  display: none;
}
