.uscite-bar-view {
  background-color: $bright-red;
  height: 20px;

  &.has-separator {
    margin-left: 5px;
  }
}

.entrate-bar-view {
  background-color: $color-saldo-complessivo-value;
  height: 20px;
}

.month-header {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.txt-saldo {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.45px;
}

.entrate-color {
  color: $positiveAmount;
}

.uscite-color {
  color: $negativeAmount;
}

.txt-divisa {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.3px;
  color: $darkest-grey;
}

.horizontal-margin-15 {
  margin: 0 15px;
}

.pt-01 {
  padding-top: 0.1em;
}

.pt-03 {
  padding-top: 0.3em;
}

.space-between-content {
  justify-content: space-between;
}

.bilancio-mesi {
  padding: 0 16px;
}

.bilancio-horizontal-chart {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  &:first-of-type {
    margin-top: 0;
  }
}

.bilancio-accordion-container {
  margin-bottom: 16px;

  .accordion-row {
    padding: 0;

    &:not(.accordion-row-resize) {
      padding-top: 8px;
    }
  }

  .accordion-title-tab {
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.bilancio-colonne-categorie {
  display: flex;
  flex-direction: column;

  .bilancio-colonna-categoria {
    flex: 1;
    margin-bottom: -1px;

    &+.bilancio-colonna-categoria {
      margin-top: 24px;
    }

    .bilancio-colonna-categoria-titolo {
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      color: $dark-grey-accessibility;
    }
  }

  @media (min-width: $breakpoint-mobile-landscape) {
    flex-direction: row;

    .bilancio-colonna-categoria {
      &+.bilancio-colonna-categoria {
        margin-top: 0;
        margin-left: 32px;
      }

      .bilancio-colonna-categoria-titolo {
        display: none;
      }
    }
  }
}

.bilancio-summary {
  display: flex;
  flex-direction: column;
  padding: 14px 16px 18px 16px;
  background-color: $light-grey;
  border-top: 1px solid $asphalt;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}

.bilancio-summary-totalone {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  p+p {
    margin-left: 8px;
  }
}

.bilancio-summary-totali {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bilancio-summary-totale {
  display: flex;
  flex-direction: column;
  align-items: baseline;

  span+span {
    margin-left: 8px;
  }

  @media (min-width: $breakpoint-mobile-landscape) {
    flex-direction: row;
  }
}

.bilancio-item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $dark-grey-bottom;

  .bilancio-item-icon {
    width: 44px;
    margin: 4px 0;
  }

  .bilancio-item-desc {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 8px;
  }

  .bilancio-item-importo {
    margin-top: 16px;
  }
}

.align-right {
  text-align: right;
}

.w-48 {
  width: 48%;
}

.margin-right-2 {
  margin-right: 2%;
}

.margin-left-2 {
  margin-left: 2%;
}

.no-margin {
  margin: 0;
}

.font-weight-300 {
  font-weight: 300;
}

.pr-0 {
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}

/* totale entrate/uscite bilancio legenda*/

.linechart-legend {
  width: 100%;
  padding: 1%;
  border-bottom: 1px solid $dark-grey-bottom;
}

.linechart-legend-square {
  height: 25px;
  width: 25px;
  display: inline-block;
}

.d-inline-block {
  display: inline-block;
}

.capitalize-letters {
  text-transform: capitalize;
}

.tooltip-line-chart {
  background-color: $black;
  padding: 0.8em;
  min-width: 80px;
  font-size: 16pt;

  &>span {
    color: $white;
    font-weight: 500;
  }
}

.titolo-grafico-bilancio {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: $dark-grey-accessibility;
  margin-bottom: 16px;
}