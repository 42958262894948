body,
#root {}

body {
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  overflow: hidden;
}

.aside-menu {
  width: fit-content;
  z-index: 2;
  position: fixed;
  margin-top: 64px;
}

/*
  Layout usato per le pagine in cui si include la spalla destra
  nel corpo della pagina, ad es. la Home.
*/
.wide {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: auto;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

/*
  Layout usato per le pagine in cui la spalla destra è fissa al
  lato destro della pagina.
*/
.central {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.main {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.core {
  padding: 24px 16px 108px 16px;
  width: 100%;

  &.extra-padding-for-datepicker {
    padding-bottom: 320px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .core {
    max-width: calc(736px + 32px);
  }
}

@media (min-width: $breakpoint-tablet-landscape) {
  .wide {
    .core {
      max-width: 928px;
    }
  }

  .core {
    max-width: 816px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .wide {
    .core {
      max-width: calc(816px + #{$shoulder-width});
    }
  }

  .central {
    -ms-grid-columns: calc(100% - #{$shoulder-width}) $shoulder-width;
    grid-template-columns: calc(100% - #{$shoulder-width}) $shoulder-width;
  }
}

.waiting-sca {
  .core {
    opacity: 0.4;
    cursor: default;
  }
}

//----

.form-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $dark-grey-accessibility;
  text-transform: uppercase;
  margin: 10px 5px;

  .fido {
    margin: 30px 5px 10px;
  }
}

.gray-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $dark-grey-accessibility;
  text-transform: uppercase;
  word-break: break-word;
}

.level3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 16px;
}

.widgetView-fabFavorite {
  display: flex;
}

@media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
  .button-level-3 {
    overflow-x: scroll;
  }

  .text-button-level3 {
    white-space: nowrap;
  }

  .widgetView-fabFavorite {
    display: none !important;
  }

  .line2-step1 {
    display: flex;
    flex-direction: column !important;
    // flex: 1 1;
  }

  .line2Col2-step1 {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1024px) {
  .inputBox {
    max-width: 382px;
  }
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .inputBox {
    max-width: 382px;
  }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 640px) {
  .inputBox {
    max-width: 320px;
  }
}

/* Navigate button placed on rightbar shoulder */

.navigate-btn-rightbar-shoulder {
  display: flex;
  justify-content: flex-end;
}

.grow-3 {
  flex: 3;
}

.w-fit-content {
  width: fit-content;
}

.contenitore-filtri,
.filtri-conti {
  @extend .spaced-bottom;
  padding: 16px 24px 24px 24px;
  margin: 16px 0;
  background-color: $light-grey;

  &-filtri {
    text-transform: uppercase;
    color: $dark-grey-accessibility;
    margin-right: 16px;
  }

  &-applicati {
    flex: 1 1;
  }

  &-applicato {
    padding: 6px 8px;
    margin-bottom: 0;
    vertical-align: top;
    background-color: $asphalt;
    color: $white;
    border-radius: 0;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    white-space: nowrap;
    height: 24px;
    text-transform: uppercase;
    border-left: 2px solid $color-sparkasse-monza;
  }

  &-arrow {
    width: 44px;
  }

  .form-field {
    background-color: $white;
  }

  .filtri-conti-line {
    height: auto !important;
  }

  .filtri-aggiuntivi-expander-collapsed {
    .expander-title {
      color: $dark-grey-accessibility;
    }
  }

  .filtri-aggiuntivi-expander {
    display: flex;
    flex-direction: row;
    justify-items: stretch;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: normal;
    color: $darkest-grey;
    border-bottom: 1px solid #999;

    &:hover {
      cursor: pointer;
    }

    &.filtri-aggiuntivi-expander-expanded {
      margin-bottom: 16px;
    }

    .expander-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      font-weight: bold;
    }

    div:last-child {
      img {
        flex: 1;
      }
    }
  }

  .filtra-movimenti-button {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    white-space: nowrap;
    margin-top: 15px;

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
      justify-content: space-between;
    }

    button {
      margin: auto 0 auto 16px;
      text-transform: uppercase;
    }

    .general-button {
      background-color: inherit !important;
      min-height: 50px;

      p {
        color: inherit !important;
      }
    }

    .filtra-movimenti-sort {
      margin-top: auto;
      margin-bottom: auto;
      font-weight: bold;
      white-space: nowrap;
    }
  }
}

.reset-filtri-movimenti {
  margin-bottom: 16px;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 0;
    margin-right: 16px;
  }
}

.filtri-conti>div>div>div>div>div {
  position: relative;
  width: 100%;
  background: $white;
  border-color: $dark-grey-bottom;
}

.filtri-conti {
  .filtra-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

.switch-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 8px 0;
  background-color: inherit;

  >span {
    display: inline-block;
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 0 !important;
    vertical-align: middle;
  }

  .react-switch {
    width: auto !important;
  }
}

.tooltip {
  &.show {
    opacity: 1;
  }

  &.bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: $color-sparkasse-asphalt;
      }
    }

    .tooltip-inner {
      padding: 8px 16px;
    }
  }

  &.bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: $color-sparkasse-asphalt;
      }
    }

    .tooltip-inner {
      padding: 8px 16px;
    }
  }

  &.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: $color-sparkasse-asphalt;
      }
    }

    .tooltip-inner {
      padding: 8px 16px;
    }
  }

  &.bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: $color-sparkasse-asphalt;
      }
    }

    .tooltip-inner {
      padding: 8px 16px;
    }
  }

  .tooltip-inner {
    max-width: 268px;
    color: $white;
    border-radius: 0;
    background-color: $color-sparkasse-asphalt;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.3);
  }
}

.image-viewer {
  position: relative;
  width: 100%;
  height: 0;
  transition: height 0.5s ease-in-out;
  background-color: $darkest-grey;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);

  &.visible {
    height: 30%;
    opacity: 1;
  }

  &.extended {
    height: 60%;
  }

  .image-viewer-controls {
    position: absolute;
    display: flex;
    flex-direction: row;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

    &--upper-left {
      top: 16px;
      left: 32px;

      .image-viewer-control {
        &+.image-viewer-control {
          margin-left: 8px;
        }
      }
    }

    &--bottom-right {
      bottom: 16px;
      right: 32px;

      .image-viewer-control {
        &+.image-viewer-control {
          margin-right: 8px;
        }
      }
    }

    .image-viewer-control {
      padding: 8px;
      background-color: rgba(0, 0, 0, 0.05);
      border: 2px solid rgba(255, 255, 255, 0.5);
      transition: background-color 0.6s ease-in-out 0.2s, border-color 0.6s ease-in-out 0.2s;

      svg {
        color: rgba(255, 255, 255, 0.5);
        fill: rgba(255, 255, 255, 0.5);
        transition: color 0.6s ease-in-out 0.2s, fill 0.6s ease-in-out 0.2s, transform 0.6s ease-in-out 0.2s;
      }

      &:active {
        transition: background-color 0s ease-in-out 0s;
      }

      &:hover:not(:active) {
        background-color: rgba(255, 255, 255, 1);
      }

      &:hover {
        cursor: pointer;

        &.animate-hover-in {
          svg {
            transform: scale(1.2) !important;
            transition: transform 0.3s ease-in-out;
          }
        }

        &.animate-hover-out {
          svg {
            transform: scale(0.8) !important;
            transition: transform 0.3s ease-in-out;
          }
        }
      }

      // used to be compatible with the "react-zoom-pan-pinch" zoom and resize features
      transform: translate3d(0, 0, 100px);
    }
  }

  &:hover {
    .image-viewer-control {
      background-color: rgba(240, 240, 240, 0.85);
      border-color: $color-sparkasse-monza;
      transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

      svg {
        color: $color-sparkasse-monza;
        fill: $color-sparkasse-monza;
        transition: color 0.3s ease-in-out, fill 0.3s ease-in-out, transform 0.1s ease-in-out;
      }
    }
  }

  // "react-zoom-pan-pinch" container
  .react-transform-wrapper {
    width: 100%;
    height: 100%;
    z-index: 0;

    .image-viewer-img-wrapper {
      position: relative;
    }

    .image-viewer-img {
      max-width: 100%;
    }

    .image-viewer-rectangle {
      position: absolute;
      transition: top 0.2s ease-in-out, left 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out,
        opacity 0.2s ease-in-out;

      &::after {
        content: "";
        position: absolute;
        top: -8px;
        right: -8px;
        bottom: -8px;
        left: -8px;
        background-color: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.3);
        transition: border 0.2s ease-in-out;
      }

      &.confidence-unknown {
        &::after {
          border: 2px solid $color-confidence-unknown;
        }
      }

      &.confidence-high {
        &::after {
          border: 2px solid $color-confidence-high;
        }
      }

      &.confidence-medium {
        &::after {
          border: 2px solid $color-confidence-medium;
        }
      }

      &.confidence-low {
        &::after {
          border: 2px solid $color-confidence-low;
        }
      }
    }

    &:hover {
      cursor: move;
      /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }
  }
}

.page-title {
  margin-bottom: 16px;

  font-size: 20px;
  font-weight: bold;
  line-height: 1.25;
  color: $darkest-grey;
}

.page-subtitle {
  margin-bottom: 8px;

  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: $darkest-grey;
}

.page-note {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  color: $asphalt;
}

.page-description {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: $darkest-grey;
}

.page-error {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  span {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    color: $red-monza;
    text-align: center;
    word-break: break-word;

    @media (min-width: $breakpoint-mobile-landscape) {
      text-align: right;
    }
  }
}

button,
input[type="button"] {
  outline: none;

  &:focus {
    outline: none;
  }
}

.filled_primary_button,
.filled_secondary_button,
.empty_primary_button,
.empty_secondary_button {
  width: 100%;
  min-height: 48px;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;

  @media (min-width: $breakpoint-mobile-landscape) {
    width: 160px;
  }

  &:disabled {
    opacity: 0.6;
  }
}

.empty_secondary_button {
  color: $asphalt;
  background-color: transparent;
  border: 2px solid $asphalt;
}

.filled_secondary_button {
  color: $white;
  background-color: $asphalt;
  border: none;
}

.empty_primary_button {
  color: $color-sparkasse-monza;
  background-color: transparent;
  border: 2px solid $color-sparkasse-monza;
}

.filled_primary_button {
  color: $white;
  background-color: $color-sparkasse-monza;
  border: none;
}

.accordion_opened_show {
  max-height: inherit;
  height: inherit;
  margin-bottom: 16px;
}

.accordion_opened_hide {
  max-height: 0;
  height: 0;
  overflow: hidden;
  margin-bottom: 16px;
}

.plastic-card {
  position: relative;

  img {
    width: 287px;
    height: 181px;
    border-radius: 12px;
    background-color: $asphalt;
    object-fit: cover;
  }

  .plastic-card-pan {
    position: absolute;

    top: 104px;
    left: 192px;

    text-align: right;
    font-size: 16px;
    line-height: 12px;
    font-weight: bold;
    letter-spacing: 4.2px;
    color: $white;
    text-shadow: 0 0 1px $asphalt;
  }

  .plastic-card-pan-debit {
    position: absolute;

    top: 102px;
    left: 210px;

    text-align: right;
    font-size: 16px;
    line-height: 12px;
    font-weight: bold;
    letter-spacing: 4.2px;
    color: $white;
    text-shadow: 0 0 1px #3f3f3f;
  }

  .plastic-card-data-scadenza {
    position: absolute;

    top: 126px;
    right: 118px;

    text-align: left;
    font-size: 16px;
    line-height: 12px;
    font-weight: bold;
    letter-spacing: 4.2px;
    color: $white;
    text-shadow: 0 0 1px #3f3f3f;

    &.credit {
      top: 132px;
    }
  }

  .plastic-card-name {
    position: absolute;

    top: 154px;
    left: 22px;
    max-width: 176px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 16px;
    text-wrap: auto;
    line-height: 13px;
    font-weight: bold;
    color: $white;
    text-shadow: 0 0 1px $asphalt;
  }
}

.callout {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  padding: 16px;
  background-color: $light-grey;

  .callout-title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: $darkest-grey;
  }

  .callout-description {
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    color: $darkest-grey;

    &+.callout-description {
      margin-top: 16px;
    }
  }
}

.bank-accordion {
  width: 100%;
  background-color: transparent;
  border: none;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #999;
  cursor: pointer;
}

.accordion {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #999;
  cursor: pointer;
}

.accordion.expanded {
  border-bottom: 0;
}

.text {
  font-size: 16px;
  font-weight: bold;
}

.text.collapsed {
  color: $dark-grey-accessibility;
}

.text.expanded {
  color: $black
}

.dynamic-button {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 900;
  width: 100%;
  min-height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 280px;
}

.dynamic-button.primary-filled {
  background-color: $red-monza;
  color: $white;
}

.dynamic-button.primary-empty {
  border: 2px solid $red-monza !important;
  color: $red-monza;
}

.dynamic-button.secondary-filled {
  background-color: $asphalt;
  color: $white;
}

.dynamic-button.secondary-empty {
  border: 2px solid $asphalt !important;
  color: $asphalt;
}

.dynamic-button.disabled {
  opacity: 0.5;
}

.tooltip-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.radio-container {
  flex-direction: row !important;
  display: flex;
  justify-content: center;
}

.radio-externalCircle {
  border: 1px solid $black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: $white;
  justify-content: center;
  align-items: center;
  display: flex;
}

.radio-internalCircle {
  border-radius: 100px;
  width: 12px;
  height: 12px;
  background-color: $red-monza
}

.radio-internalCircle.disabled {
  background-color: $dark-grey-bottom;
}

.radio-textContainer {
  justify-content: center;
  margin-left: 12px;
}

.radio-text {
  font-size: 16px;
}

.bs-tooltip-bottom {
  .arrow {
    top: 0 !important;
  }
}

.bs-tooltip-top {
  .arrow {
    bottom: 0;
    top: auto !important;
  }
}