.home-section-esito {
  display: flex;
  flex-direction: column;
}

.txtTitle-section-esito {
  margin: 10px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: $black;
}

.txtText-section-esito {
  margin: 10px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $darkest-grey;

  .button-text {
    margin-top: 8px;
  }
}