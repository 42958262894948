.modal-personalizza-massimale {
  .modal-title {
    font-family: inherit;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: $red-monza;
  }

  .modal-content {
    font-family: inherit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $darkest-grey;
    margin-top: 24px;
    border: none;
    border-radius: 0;

    .btn-radio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 24px 0;
    }

    .option {
      margin-top: 12px;
    }

    .input-control-currency {
      label {
        max-width: 80% !important;
      }
    }

    .modal-text-description {
      font-size: 16px;
      padding-left: 14px;
    }
  }

  .modal-footer {
    border: none;
    background-color: $light-grey;
    flex-direction: column;

    &.justify {
      justify-content: center;
    }

    .btn-box {
      width: 50%;
      align-self: flex-end;

      button {
        width: 100%;
      }
    }
  }

  .descrizione-pulsante {
    display: flex;
    flex: 1;
    align-self: flex-start;
  }
}