.oneClickView-item-bozze {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;

  min-height: 60px;
  width: 100%;
  margin-bottom: 8px;

  color: $asphalt;
  background-color: $white;
  border-left: 2px solid $asphalt;

  &>span {
    width: 290px;
  }
}

.toggleContainer {
  flex-shrink: 0;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.dangerIcon {
  margin-right: 8px;
  width: 15px;
  display: inline-block;
  margin-bottom: 0.5rem;
  position: relative;
  white-space: nowrap;
  color: #343535;
  margin-top: 2px;
}

.submenuIcons {
  width: 15px;
  position: absolute;
  color: $white;
  fill: $white;
  margin-top: -13px;
  margin-left: -24px;
  -webkit-user-drag: none;
  user-select: none;
}

.toggleContainer div {
  flex-shrink: 0;
  transition: all 0.2s;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggleButtonsSubContainer {
  position: relative;
  width: 100% !important;
  top: 8px;
}

.txtOneClick-item-bozze {
  flex: 1;
  vertical-align: center;
  color: #343535;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 13em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toggleButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  background: $asphalt;
  padding-right: 20px;
}

.toggleButtons {
  padding: 5px;
  color: $white;
  left: 0px;
  font-weight: 400;
  width: 32px;
  text-align: center;
  font-size: 0.9em;
  height: 28px;
  line-height: 1.3;
  align-items: flex-end;
  justify-content: center;
  text-transform: uppercase;
  transition: all 0.2s;
}

.toggleButtons:hover {
  color: $bright-red;
  background-color: $light-grey;
  cursor: pointer;
}

.buttonContainerLabel {
  display: inline-block;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  position: relative;
  white-space: nowrap;
  line-height: 1;
  color: $white;
  margin-top: 7px;
  margin-left: 5px;
  font-weight: 400;
}

.iconView-item-bozze {
  // flex: 0.1;
  // border: 1px solid red;
  // marginLeft: 15;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.answerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}