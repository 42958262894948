.filled_primary_button {
  width: auto;
  cursor: pointer;
}

.elencoAppuntamentiCta {
  display: flex;
  justify-content: right;
  margin: 20px auto;
}

.elenco-appuntamenti {
  .no-items {
    display: flex;
    flex-flow: column;

    .no-items-content {
      text-align: left;
    }

    .no-items-title {
      text-align: center;
    }

    .no-items-cta {
      display: flex;
      justify-content: right;
      margin: 20px 0px;
      cursor: pointer;
    }
  }
}
