.accordion-catalogo-wrapper {
  background: transparent;

  >.accordion-title-tab {
    background: transparent;
    height: 44px;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $black;
    padding: 14px 0px 14px 0px;
    text-transform: uppercase;
    justify-content: space-between;

    >img {
      align-items: flex-end;
      align-content: flex-end;
      position: relative;
      width: 44px;
      height: 44px;
      top: -13px;

      &:hover {
        cursor: pointer;
      }
    }

    >img.reverse {
      transform: scale(-1);
    }

    >span {
      position: relative;
      height: 44px;
      position: relative;
      top: -12px;
    }

    >span:hover {
      cursor: pointer;
    }
  }

  >.accordion-row {
    padding: 14px 0px 0px 0px;
    transition: all 0.2s;
    background: transparent;

    >label {
      height: 16px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: $dark-grey-bottom;
      text-transform: uppercase;
    }
  }

  >.accordion-row-resize {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  &.expanded {
    .accordion-catalogo-header-title {
      color: $dark-grey-accessibility;
    }
  }
}

.accordion-catalogo-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  border: none;
  background-color: white;
  padding: 0;
  text-align: left;

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }

  .accordion-catalogo-header-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 100%;
    background-color: $color-sparkasse-monza;

    &>div {
      width: fit-content;
      margin: auto;
      padding: 10px 4px;
    }
  }

  .accordion-catalogo-header-title {
    flex: 1;
    margin: 0 0 0 24px;
    font-size: 16px;
    font-weight: bold;
    line-height: 64px;
    color: $asphalt;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .accordion-catalogo-header-arrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.justify-space-between {
  justify-content: space-between;
}

.card-view-container-corner-box-prodotto {
  position: absolute;
  top: 15px;
}

.position-relative {
  position: relative;
}

.catalogo-rects {
  display: grid;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;

  @media (min-width: $breakpoint-mobile-landscape) {
    grid-template-columns: 1fr 1fr;
  }

  .catalogo-rect {
    position: relative;
    min-height: 195px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    background-color: $medium-dark-grey;
    transition: background 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }
}

.black-box-per-te {
  width: 50%;
  height: 100%;
  padding: 16px;
  color: $white;
  background-color: rgba(63, 63, 63, 0.85);
}

.subtitle-black-box {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
}

.contattaci-btn-box {
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 48px;
  background-color: $color-sparkasse-monza;
}

.contattaci-btn {
  color: $white;
  font-weight: bold;
  width: 100%;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

//DESKTOP
@media (min-width: 1025px) {
  .block-visual-header {
    position: relative;
    background-size: auto 336px;
    background-repeat: no-repeat;
    background-position: right top;
    background-color: $medium-dark-grey;
    min-height: 336px;
    padding-top: 336px;

    // @media (min-width: $breakpoint-tablet) {
    //   padding-top: 0;

    //   .block-visual-box-text {
    //     position: absolute;
    //     height: 100%;
    //     width: 35%;
    //   }
    // }
  }
}

@media (min-width: 1025px) {
  .block-visual-box-text {
    padding: 15px;
    background-color: rgba(63, 63, 63, 0.85);

    .titoloLeft {
      h1 {
        font-size: 20px;
      }
    }
  }
}

//MOBILE
@media (max-width: 480px) {
  .block-visual-box-text {
    background-color: rgba(63, 63, 63, 0.85);
    padding: 15px;
    max-height: 40%;

    .titoloLeft {
      h1 {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 480px) {
  .titoloLeft {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .paragraphLeft {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .block-visual-header {
    position: relative;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $medium-dark-grey;
    min-height: 65%;
    padding-top: 40%;
  }
}

//TABLET

@media (min-width: 481px) and (max-width: 1024px) {
  .block-visual-box-text {
    padding: 15px;
    background-color: rgba(63, 63, 63, 0.85);

    .titoloLeft {
      h1 {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .block-visual-header {
    position: relative;
    background-size: auto 336px;
    background-repeat: no-repeat;
    background-position: right top;
    background-color: $medium-dark-grey;
    min-height: 336px;
    padding-top: 336px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .block-visual-header {
    position: relative;
    background-size: auto 336px;
    background-repeat: no-repeat;
    background-position: right top;
    background-color: $medium-dark-grey;
    min-height: 336px;
    padding-top: 336px;
  }
}

// i.e. iPad Pro 12.9
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .block-visual-box-text {
    padding: 15px;
    background-color: rgba(63, 63, 63, 0.85);

    .titoloLeft {
      h1 {
        font-size: 20px;
      }
    }
  }
}

.accordion-catalogo-rows {
  margin-top: 24px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .accordion-catalogo-row {
    margin-top: 16px;
  }
}

.catalogo-blocks-container {
  display: grid;
  width: 100%;
  margin-bottom: 24px;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;

  @media (min-width: $breakpoint-mobile-landscape) {
    grid-template-columns: 1fr 1fr;
  }
}

.catalogo-block {
  position: relative;
  min-height: 195px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-top: 2px solid $color-sparkasse-monza;
    border-left: 2px solid $color-sparkasse-monza;
  }
}

.prodotto-box {
  width: 100%;
  height: 100%;
  padding: 16px 32px 32px 16px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px 0px;

  &:hover {
    cursor: pointer;
    background-color: $light-grey;
  }

  p {
    margin: 0;
  }

  >.title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: $darkest-grey;
  }

  >.description {
    margin-top: 8px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $darkest-grey;
  }

  >.descrizione-aggiuntiva {
    margin-top: 8px;
  }
}