.full-page-fatal-error {
  width: 100%;
  height: 100%;
  background-color: #999999;
  position: absolute;
  z-index: 5;
  text-align: center;
  padding-top: 150px;
  // min-height: 100vw;
  min-height: calc(100vw - 200px);

  &-dialog {
    background-image: url("../../../public/fullPageNotification.png");
    height: 240px;
    max-height: 240px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    padding: 20px;
    overflow: hidden;

    &-text {
      // margin: auto;
      // max-height: 120px;
      font-weight: bold;
      overflow: auto;
      display: flex;
      align-items: center;
      height: 80%;
    }
  }

  img {
    width: 300px;
    cursor: pointer;
  }
}