div[id^="input_"] {

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
}

textarea:focus,
input:focus,
select:focus {
  outline: none;
}

input,
select {
  background: none;
  border-width: 0;
  border-radius: 0;
  display: block;
  width: 100%;
}

.input-container {
  position: relative;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 16px;

  span.icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    i {
      color: $darkest-grey;
      font-size: 20px;
    }
  }

  .floating {
    &__input {
      padding: 16px 44px 8px 8px;
      font-size: 16px;
      height: 43px;
      line-height: 20px;
      color: $darkest-grey;
      border-bottom: 1px solid #999;
      transition: border-color 160ms ease-in-out, box-shadow 160ms ease-in-out,
        color 160ms ease-in;

      &::placeholder {
        color: transparent;
      }

      &:focus-visible {
        box-shadow: 0 1px 0 0 $darkest-grey;
      }
    }

    &__label {
      position: relative;
      display: block;
      max-height: 0;
      pointer-events: none;

      &::before {
        content: attr(data-content);
        position: absolute;
        display: block;
        overflow: hidden;
        left: 8px;
        height: 20px;
        max-width: calc(100% - 44px);
        font-size: 16px;
        line-height: 20px;
        font-family: "Light";
        color: $dark-grey-bottom;
        white-space: nowrap;
        text-overflow: ellipsis;
        backface-visibility: hidden;
        transform-origin: left top;
        transition: transform 160ms ease-in-out;
      }
    }

    &.error {
      .floating__input {
        border-color: $error;

        &:focus-visible {
          box-shadow: 0 1px 0 0 $error;
        }
      }

      .floating__label::before,
      .floating__input:focus+.floating__label::before {
        color: $error;
      }
    }

    &.disabled {
      .floating__input {
        border-color: $medium-dark-grey;
      }

      .floating__label::before,
      .floating__input:focus+.floating__label::before {
        color: $medium-dark-grey;
      }
    }
  }

  .floating__label .floating__input:focus+.floating__label::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  .floating__input:placeholder-shown+.floating__label::before {
    transform: translate3d(0, -30px, 0) scale3d(1, 1, 1);
  }

  .floating__label::before,
  .floating__input:focus+.floating__label::before {
    transform: translate3d(0, -50px, 0) scale3d(0.8, 0.8, 1);
  }

  .floating__input:focus+.floating__label::before {
    color: #9d9d9d;
  }

  .placeholder-selected {
    .floating__input {
      color: transparent;
      transition: border-color 160ms ease-in-out, box-shadow 160ms ease-in-out;
    }

    .floating__label::before {
      transform: translate3d(0, -30px, 0) scale3d(1, 1, 1);
    }
  }

  .switch-wrapper {
    display: flex;
    flex-direction: row;

    &.switch-position-right {
      justify-content: flex-end;

      label {
        span {
          text-align: end;
        }
      }
    }

    .switch-container {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 48px;
      cursor: pointer;

      .switch {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 36px;
        height: 20px;

        input {
          opacity: 0;
          width: 0;
          height: 0;

          &:focus-visible+.slider {
            box-shadow: 0 0 0 1px $dark-grey-bottom;
          }

          &:focus-visible:checked+.slider {
            box-shadow: 0 0 0 1px $red-monza;
          }

          &:checked+.slider {
            background-color: $red-monza;
          }

          &:checked+.slider:before {
            -webkit-transform: translate3d(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px);
          }
        }

        .slider {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $dark-grey-bottom;
          -webkit-transition: 160ms;
          transition: 160ms;
          cursor: pointer;

          &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 2px;
            background-color: $white;
            -webkit-transition: 160ms;
            transition: 160ms;
          }

          &.round {
            border-radius: 16px;

            &:before {
              border-radius: 50%;
            }
          }
        }
      }
    }

    &.switch-button-position-right {
      .switch-container {
        padding-right: 0;
        padding-left: 48px;

        .switch {
          right: unset;
          left: 0;
        }
      }
    }

    &.disabled {
      .switch-container {
        cursor: auto;

        span {
          color: $medium-dark-grey;
        }

        .switch {
          .slider {
            opacity: 0.5;
            cursor: auto;
          }
        }
      }
    }
  }

  .form-error {
    padding: 8px;
  }

  .form-select {
    &.error {
      color: $error;
      border-color: $error;
    }
  }

  .radio-wrapper,
  .checkbox-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    -webkit-appearance: none;
    appearance: none;
  }

  .radio-label,
  .checkbox-label {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 6px 0 4px 34px;
    min-height: 30px;
  }

  .radio-label:before,
  .radio-label:after {
    border-radius: 50%;
  }

  .radio-label:before,
  .checkbox-label:before {
    content: " ";
    position: absolute;
    top: 3px;
    left: 0;
    height: 24px;
    width: 24px;
    border: solid 1px $black;
    background-color: $white;
    pointer-events: none;
  }

  .radio-label:before {
    transition: background-color 160ms ease-in-out;
  }

  .checkbox-label:before {
    transition: border-color 160ms ease-in-out,
      background-color 160ms ease-in-out;
  }

  .radio-label:after {
    content: " ";
    position: absolute;
    top: 9px;
    left: 6px;
    display: flex;
    height: 12px;
    width: 12px;
    background: $red-monza;
    opacity: 0;
    transform: scale(0);
    transition: transform 160ms ease-in-out, opacity 160ms ease-in-out;
  }

  .checkbox-label:after {
    content: " ";
    position: absolute;
    top: 11px;
    left: 6px;
    height: 5px;
    width: 12px;
    border-bottom: solid 2px $white;
    border-left: solid 2px $white;
    background: none;
    opacity: 0;
    transform: rotate(-45deg) scale(0);
    transition: transform 160ms ease-in-out, opacity 160ms ease-in-out;
    transform-origin: center center;
  }

  input[type="radio"]:not(:disabled)+.radio-label,
  input[type="checkbox"]:not(:disabled)+.checkbox-label {
    cursor: pointer;
  }

  input[type="checkbox"]:checked+.checkbox-label:before {
    border-color: $red-monza;
    background-color: $red-monza;
  }

  input[type="radio"]:checked+.radio-label:before {
    border-color: $red-monza;
    border-width: 2px;
  }

  input[type="radio"]:focus-visible+.radio-label:before,
  input[type="checkbox"]:focus-visible+.checkbox-label:before {
    box-shadow: 0 0 0 1px $darkest-grey;
  }

  input[type="radio"]:focus-visible:checked+.radio-label:before,
  input[type="checkbox"]:focus-visible:checked+.checkbox-label:before {
    box-shadow: 0 0 0 1px $red-monza;
    transition: none;
  }

  input[type="radio"]:disabled+.radio-label:before,
  input[type="checkbox"]:disabled+.checkbox-label:before {
    background-color: $dark-grey-bottom;
    border-color: $dark-grey-bottom;
  }

  input[type="radio"]:disabled+.radio-label:after {
    background: $white;
  }

  .radio-wrapper input[type="radio"]:checked+.radio-label:after {
    opacity: 1;
    transform: scale(1);
  }

  .checkbox-wrapper input[type="checkbox"]:checked+.checkbox-label:after {
    opacity: 1;
    transform: rotate(-45deg) scale(1);
  }

  input[type="date"] {
    -webkit-appearance: none;
    appearance: none;

    &::-webkit-datetime-edit-fields-wrapper {
      padding: 0;
    }

    &::-webkit-datetime-edit-text {
      color: $darkest-grey;
    }

    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-year-field {
      padding: 0 1px;
      color: $darkest-grey;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      padding: 0;
      width: 44px;
      height: 44px;
      opacity: 0;
      cursor: pointer;
    }
  }

  .form-searchable-select {
    display: inline;

    .floating__input {
      cursor: pointer;
    }

    .form-searchable-select-toggle {
      width: 100%;
      text-align: left;
      background: $white;
      color: $darkest-grey;
      white-space: break-spaces;

      &::after {
        position: absolute;
        right: 15px;
        top: 8px;
      }
    }

    .form-searchable-select-menu {
      position: absolute;
      z-index: 1;
      padding: 0;
      width: 100%;
      text-align: left;
      background: $white;
      color: $darkest-grey;
      border: 1px solid $inputColor;
      font-size: 16px;
      font-family: inherit;
      margin-top: -1px;

      input.form-searchable-select-search {
        padding: 12px 16px;
        font-size: 16px;
        line-height: 20px;
        color: $darkest-grey;
        border-bottom: 1px solid $inputColor;
      }

      .form-searchable-select-list {
        max-height: 300px;
        overflow-y: auto;

        .form-searchable-select-group-header {
          padding-top: 4px;
          padding-right: 10px;
          padding-bottom: 4px;
          padding-left: 10px;
        }

        .form-searchable-select-item {
          cursor: pointer;
          padding-top: 8px;
          padding-right: 16px;
          padding-bottom: 8px;
          padding-left: 16px;

          &:hover {
            background-color: $light-grey;
          }
        }
      }
    }
  }
}

.dropdown {
  position: relative;

  .dropdown-outside {
    position: relative;
    height: 48px;
    overflow: hidden;
    cursor: pointer;
    background-color: $white;

    .items-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 28px;
      bottom: 6px;

      .items {
        overflow: hidden;
        position: absolute;
        width: 200%;
      }

      &::after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 8px;
        background: linear-gradient(270deg, $white, $black);
      }
    }

    .icon {
      position: relative !important;

      svg {
        width: 24px !important;
        height: 24px !important;
        min-width: auto !important;
        min-height: auto !important;
      }
    }
  }

  .closable-item {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    background-color: $asphalt;
    color: $white;
    padding: 2px 0px 2px 8px;
    margin: 0 2px;
    max-width: 248px;

    .label {
      max-width: 224px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tooltiptext {
      visibility: hidden;
      width: auto;
      background-color: $dark-grey-bottom;
      color: $black;
      text-align: center;
      padding: 5px;
      position: absolute;
      z-index: 1;
      top: -6px;
      font-size: 16px;
    }

    &:hover .tooltiptext {
      visibility: visible;
    }
  }

  .dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 1px 0 0 0;
    background-color: $white;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 8px;
      background: linear-gradient(0deg, $white, $black);
    }

    .dropdown-container-items {
      padding: 8px 0;
      max-height: 165px;
      overflow-y: auto;
    }

    .checkbox-wrapper {
      .checkbox-label {
        width: 100%;
        padding: 8px 16px 6px 50px;

        &:hover {
          background-color: $light-grey;
        }

        &::before {
          top: 5px;
          left: 16px;
        }

        &::after {
          top: 13px;
          left: 22px;
        }
      }
    }
  }
}