.accordion-body {
  background: transparent;

  h3 {
    margin: 0;

    .accordion-title-tab {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      min-height: 44px;
      width: 100%;
      border: none;

      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: $black;
      text-transform: uppercase;
      background: transparent;

      .accordion-title-text {
        padding: 14px 0px;
      }

      > img {
        align-items: flex-end;
        align-content: flex-end;
        position: relative;
        width: 44px;
        height: 44px;
        top: -13px;

        &:hover {
          cursor: pointer;
        }
      }

      > img.reverse {
        transform: scale(-1);
      }

      > .accordion-arrow {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  > .accordion-row {
    padding: 14px 10px 0px 14px;
    transition: all 0.2s;

    > label {
      height: 16px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: $dark-grey-accessibility;
      text-transform: uppercase;
    }
  }

  > .accordion-row-resize {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
}

.accordion-container {
  margin-top: 16px;
  border-bottom: solid 1px #c1c1c1;
}
