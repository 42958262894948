  .menuView-item-menu {
    color: $white;
    // background-color: #ff0000;//#4a4a4a;
    border-radius: 2px;
    width: 100%;
    // height: 110;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $white;

  }

  .icon-item-menu {
    fill: $white;
  }

  .menuViewItemExpanded-item-menu {
    color: $black;
    background-color: $white !important;
    border-radius: 2px;
    width: 100%;
    // height: 110;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .menuViewExpanded-item-menu {
    color: $white;
    background-color: #4a4a4a;
    border-radius: 2px;
    padding-left: 10px;
    // height: 90;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid $white;
  }

  .imgMenuView-item-menu {
    color: $white;
    margin-top: 10px;
    // width: 30;
  }

  .imgMenuViewExpand-item-menu {
    margin-top: 0;
  }

  .txtMenuView-item-menu {
    text-align: center;
    font-size: 15px;
    font-weight: 400 !important;
  }

  .txtMenuViewExpand-item-menu {
    vertical-align: center;
    font-size: 25px;
    margin-left: 25px !important;
    justify-content: flex-end;
    margin-top: 15px !important;
  }