.blocco-carte-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: bold;
  color: $black;
}

.blocco-carte-subtitle-container {
  font-size: 16px;
  color: $darkest-grey;
  max-width: 788px;
}

.blocco-carte {
  .blocco-carte-left {
    float: left;

    .blocco-carte-image {
      width: 44px;
      height: 44px;
    }
  }

  .blocco-carte-right {
    float: right;

    .contact-number {
      font-weight: bold;
    }
  }

  .accordion-title-text {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
  }

  .accordion-title-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;

  }
}

.appointment-card {
  .card-view {
    &--body-view {
      flex: 1 1 auto;

      &--date {
        color: $red-monza;
        margin: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        word-break: break-word;
      }
    }
  }
}