.bolloACI-image-contaner {
  display: flex;
  flex-direction: row-reverse;
  margin: 18px 0;
}

.bolloACI-image-contaner > img {
 width: 160px;
 height: auto;
}
