.addebito-interessi {
  .addebito-interessi {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .card-view-container {
    .info-container {
      padding: 5px 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 5rem;
      width: 100%;

      @media (max-width:$breakpoint-mobile-landscape) {
        flex-direction: column;
        gap: 1rem;
      }

      .label-value-container {
        display: flex;
        flex-direction: column;
      }
    }


    .icon-view,
    .card-view-container-corner,
    .card-view--body-view--import-view--import,
    .card-view--body-view--description,
    .card-view--body-view--import-view--currency {
      display: none !important;
    }

    .card-view--body-view--full-col {
      margin-left: 18px;
    }

    .card-view--body-view--additional-info-label {
      color: $black;
    }

    .card-view--body-view--date,
    .card-view--body-view--date-on-right {
      font-size: 16px;
      font-weight: 300;
      color: $darkest-grey;
      text-align: left;
    }
  }

  .card-view-container-rifiutato {
    .card-view-container {
      border-left: 4px solid $rifiutato;
    }
  }

  .card-view-container-accettato {
    .card-view-container {
      border-left: 4px solid $accettato;
    }
  }

  .card-view-container.card-view--body-view--vertical-cta {
    border-left: none;
  }
}

.addebito-interessi-legenda {
  white-space: nowrap;
  font-weight: normal;
  margin: 10px;
  font-size: 16px;

  &-item {
    display: flex;
    flex-direction: row;

    .small-square-accettato {
      width: 15px;
      height: 15px;
      background-color: $accettato;
      margin: 2px 0 auto 0;
    }

    .small-square-rifiutato {
      width: 15px;
      height: 15px;
      background-color: $rifiutato;
      margin: 2px 0 auto 0;
    }

    label.content-main-section--right-bar-small-caption {
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
    }
  }
}

.seleziona-addebiti {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $dark-grey-accessibility;
  text-transform: uppercase;
  margin-top: 16px;
}

.accetta-tutte {
  margin-top: 42px;
}

.riassunto-anaticismo-descrizione {
  margin-top: 16px;

  &-testo {
    justify-content: center;
    margin-left: 8px;
  }
}

.riassunto-anaticismo-addebito-accettato {
  background-color: $accettato;
}

.riassunto-anaticismo-addebito-rifiutato {
  background-color: $rifiutato;
}