.chart_container {
	display: flex;
	flex: 1;
	flex-direction: row;

	@media (max-width: $breakpoint-mobile-landscape) {
		flex-direction: column;
	}
}

.chart {
	@media (max-width: $breakpoint-mobile-landscape) {
		align-self: center;
	}
}

.prossima-rata-idea {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	margin-bottom: 30px;

	span {
		&:nth-child(1) {
			font-size: 16px;
			font-weight: 300;
		}

		&:nth-child(2) {
			span {
				&:nth-child(1) {
					font-size: 18px;
					font-weight: normal;
				}

				&:nth-child(2) {
					font-size: 16px;
					font-weight: 300;
				}
			}
		}
	}
}