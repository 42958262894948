.legendView-polizze-wrapper {
  margin-top: 32px;

  .legendView-polizze {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    padding-bottom: 10px;

    &-col1 {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    &-categoria {
      flex: 1;
      display: flex;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.25;
      margin-left: 16px;
      white-space: nowrap;
    }

    &-col2 {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      margin-left: 16px;
    }

    &-importo {
      display: flex;
      margin-bottom: 0;
      font-weight: $section-font-weight;
      text-align: end;
      font-size: 18px;

      &-divisa {
        font-size: 16px;
        margin: auto 0 auto 0.5rem;
      }
    }
  }
}

.legend-square {
  height: 20px !important;
  width: 20px !important;
  margin-right: 5px;
}

.formSaldoDisponibile,
.formSaldoContabile {
  .polizze-from-time {
    font-weight: normal !important;
    font-size: 16px !important;
    margin-top: 10px;
  }
}