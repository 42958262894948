.rendimenti {
  .rendimenti-tabella-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 32px 0;
    padding: 0 16px;
  }

  .rendimenti-tabella-cta-row {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }

  .rendimenti-tabella-cta-bottom {
    position: sticky;
    bottom: 16px;
    display: flex;
    justify-content: flex-end;

    .rendimenti-tabella-cta-row {
      margin-top: 24px;

      div {
        margin-left: 8px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
        background-color: $light-grey;
      }
    }
  }

  .rendimenti-tabella {
    .custom-table {
      .table {
        margin-bottom: 0;

        thead tr {
          height: 50px;

          th {
            &:first-child {
              border-right: 1px solid $mercury;
              width: 140px;
            }

            &.rendimenti-header-labels {
              font-weight: bold;
              font-size: 16px;
              height: 50px;
              color: $black;
            }
          }
        }
      }
    }

    .rendimenti-tabella-flussi {
      .negative-amount {
        color: $color-negative-amount !important;
      }

      .positive-amount {
        color: $color-positive-amount !important;
      }

      tbody tr {
        &.rendimenti-tabella-riga-ndg {
          border-bottom: none;
          border-top: 4px solid $mercury;
          height: 36px;

          td {
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            color: $darkest-grey;
          }
        }
      }

      tr.rendimenti-tabella-totali-ndg td {
        font-weight: bold;
        font-size: 16px;
      }

      td:first-child {
        border-right: 1px solid $mercury;
        width: 200px;
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .rendimenti-colonna-deposito {
        span {
          display: block;
          font-size: 16px;
          line-height: 20px;
          color: $darkest-grey;
          font-weight: 300;

          &:last-child {
            font-weight: normal;
          }
        }
      }

      .rendimenti-ultima-colonna {
        width: 96px;
        text-align: center;
        border-left: 1px solid $mercury;
        font-weight: bold;
      }

      .rendimenti-colonna-numerica {
        text-align: right;
      }

      .rendimenti-tabella-prodotto {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;

        &-descrizione {
          display: block;
          text-decoration: underline;
          word-break: break-word;
          color: $black
        }

        &-descrizione-Gp {
          display: block;
          word-break: break-word;
        }
      }

      .rendimenti-tabella-total {
        border-top: 4px solid $mercury;

        &~.rendimenti-tabella-total {
          border-top: none;
        }
      }

      .rendimenti-tabella-grand-total {
        background-color: $asphalt;

        td {
          font-size: 16px;
          font-weight: bold;
          line-height: 20px;
          color: $white;

          &.negative-amount {
            color: $color-negative-amount-light !important;
          }

          &.positive-amount {
            color: $color-positive-amount-light !important;
          }
        }
      }

      .riga-deposito-arrow {
        height: 32px;
        width: 32px;
      }

      .riga-prodotti-clickable {
        &:hover {
          cursor: pointer;
        }
      }

      .riga-prodotti-visibile {
        background-color: $light-grey;
      }

      .riga-prodotti-nascosta {
        display: none;
      }
    }

    &-riga {
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      color: $darkest-grey;
      border-top: 1px solid $dark-grey-bottom;
    }

    &-small-label {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: $darkest-grey;
    }
  }
}

.bottone-ingrandisci-schermo {
  display: none;

  @media (min-width: $breakpoint-desktop) {
    display: block;
  }
}

.totali-depositi {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
}

.rendimenti-modal {
  .modal-dialog {
    width: calc(100% - 16px);
    max-width: 816px;

    .modal-header {
      border: none;
      background-color: $asphalt;
      color: $white;
      border-radius: 0;

      .modal-title-text {
        color: $white;
        font-family: inherit;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        margin: auto;
      }
    }

    .modal-footer {
      background-color: $white;
      justify-content: center;

      button {
        width: 100% !important;
        max-width: 280px;
        background-color: $white !important;
      }
    }
  }
}

.rendimenti-date {
  span {
    display: block;

    &+span {
      color: $dark-grey-accessibility;
    }
  }
}

.rendimenti-causale {
  text-align: left;
  text-transform: capitalize;
}