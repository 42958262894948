.item-carousel-mirino {
  position: absolute;
  height: 20px;
  top: 0;
  left: 0;
}

.item-carousel-row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.item-carousel-superscript {
  display: block;
  color: $dark-grey-accessibility;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;

  &~.item-carousel-row-container {
    margin-top: 4px;
  }

  &~.item-carousel-column-container {
    margin-top: 4px;
  }
}

.item-carousel-plastic-card-layout {
  display: flex;
  flex-direction: row;
}

.item-carousel-plastic-card-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.item-carousel-graph {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .item-carousel-graph-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;

    .item-carousel-graph-data-item {
      .item-carousel-graph-data-item-label {
        font-size: 16px;
        font-weight: 300;
        line-height: 16px;
        color: $darkest-grey;
        word-break: break-word;
      }

      .item-carousel-graph-data-item-value {
        margin-top: 4px;
        font-size: 16px;
        line-height: 20px;
        color: $black;
      }

      &+.item-carousel-graph-data-item {
        .item-carousel-graph-data-item-label {
          text-align: right;
        }

        .item-carousel-graph-data-item-value {
          text-align: right;
        }
      }
    }
  }

  .item-carousel-graph-bar {
    height: 11px;
    background-color: $medium-dark-grey;

    .item-carousel-graph-bar-fill {
      height: 100%;
      background-color: $red-monza !important;
    }
  }

  .item-carousel-graph-sub-data {
    margin-top: 12px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
    color: $darkest-grey;
  }
}

.item-carousel-column-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.carousel-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .bank-img {
    margin-right: 8px;
  }

  .txtIBAN-item-carousel {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.bank-img {
  height: 24px;
  width: 24px;
}

.carousel-alias-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 320px;

  @media (min-width: $breakpoint-tablet-landscape) {
    flex: 1.3;
  }

  &~.carousel-saldi-container {
    margin-left: 24px;
  }
}

.carousel-saldi-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.carousel-alias-container,
.carousel-saldi-container {
  &>*~* {
    margin-top: 8px;
  }
}

.carousel-saldo-contabile,
.carousel-saldo-disponibile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  div:not(:first-child) {
    text-align: right;
    margin-left: 16px;
    margin-top: 8px;
  }

  @media (min-width: $breakpoint-tablet-landscape) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: $breakpoint-desktop) {
    div:not(:first-child) {
      margin-top: 0;
    }
  }

}

.carousel-item-label-value-symbol {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: $breakpoint-tablet-landscape) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  div:not(:first-child) {
    text-align: right;
  }
}

.carousel-intestazione-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 0 16px 0;
}

.carousel-totali-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;

  &>div {
    flex: 1;
  }

  .text-currency {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    color: $black;
  }
}

.txtBanca-item-carousel {
  color: $dark-grey-bottom;
  font-size: 16px;
  margin: 5px;
}

.txtAlias-item-carousel {
  color: $darkest-grey;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.2px;
}

.txtIBAN-item-carousel {
  color: $black;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: -0.2px;
}

.txtTypeImport-item-carousel {
  color: $black;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: -0.2px;
}

.txtImporto-item-carousel {
  color: $black;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
}

.txtCurrency-item-carousel {
  color: $black;
  font-size: 16px;
  font-weight: 300;
  margin-left: 8px;
}

.carousel-select-columns {
  flex-direction: column !important;

  @media (min-width: $breakpoint-mobile-landscape) {
    flex-direction: row !important;

    div {
      text-align: right;
    }
  }
}