.modal-dialog {
  max-width: 624px;
}

.modal-psd2-modal {
  .modal-content {
    border-radius: 0;
    border: none;

    .modal-header {
      border: none;

      .modal-title {
        // padding-left: 9px;
        font-family: inherit;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: $darkest-grey;
      }
    }

    .modal-body {
      font-family: inherit;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $darkest-grey;
      padding: 0 16px;

      .input-control-text {
        .input-control-text-icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 32px;
          cursor: pointer;
        }
      }

      .cerca-banca-testo {
        margin-top: 8px;
        margin-bottom: 8px;

        svg {
          width: 32px !important;
          height: 32px !important;
        }
      }

      .lista-banche {
        max-height: 264px;
        min-height: 264px;
        overflow-y: auto;

        .lista-banche-item:hover {
          background-color: $light-grey;
        }
      }

      .radio-button-box.elenco-prodotti-psd2 {
        font-weight: bold;
        color: $darkest-grey;

        .radio-button-label {
          padding-bottom: 8px;
          color: $darkest-grey;
        }
      }

      .psd2-scarica-privacy {
        margin-top: 16px;

        svg {
          margin-left: -12px; // fix icon
        }

        label {
          margin-left: -5px; // fix icon
        }
      }

      .quick-operation {
        text-align: center;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);

        .txtWidgetView-item-quickOpr {
          margin: 0 15px 15px 15px;
          font-weight: normal;
          font-size: 16px;
          line-height: 1.14;
          max-height: 32px;
          overflow: hidden;
        }
      }
    }

    .modal-footer {
      border: none;

      &.justify {
        justify-content: flex-end;
      }

      .modal-psd2__close-button {
        width: 220px;
        height: 48px;
        padding: 8px 0;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.4px;
        font-family: inherit;
        background: inherit;
        border: 2px solid $asphalt;

        &.left {
          color: $asphalt;
        }

        &.right {
          &:disabled {
            opacity: 0.5;
          }

          color: #e2001a;
          border: 2px solid #e2001a;
        }

        &.right.button-disable {
          opacity: 0.5;
        }
      }
    }
  }
}

.lista-banche-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;

  &-name {
    display: flex;
    flex-direction: row;

    span {
      align-self: center;
    }
  }

  .bank-img {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    margin-right: 16px;
  }
}

.frame-aggiungi-banca {
  width: 99%;
  height: 99%;
  min-height: 380px;
  border: 1px solid $darkest-grey;
}

.frame-psd2 {
  width: 100%;
  height: 544px;
  max-height: calc(100vh - 180px);
  border: 1px solid #979797;
}

.widget-errore-psd2 {
  display: flex;
  flex-direction: row;
  height: 60px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  margin-top: 8px;
  margin-bottom: 8px;

  &-icon {
    width: 60px;
    height: 60px;
    background-color: $mercury;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 32px;
      height: 32px;
      margin: auto;
    }
  }

  &-testo {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
}

.modal-subtitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: $darkest-grey;
}