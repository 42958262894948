.home-section-oneclick {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.txtOneClick-section-oneclick {
  color: $dark-grey-accessibility;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}