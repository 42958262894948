.home-section-guide {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .txtTitle-section-guide,
  .txtText-section-guide {
    margin-bottom: 16px;
  }

  .button-text {
    margin-bottom: 8px;

    label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.txtTitle-section-guide {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #181818;
  white-space: pre-line;
  word-break: break-word;
}

.txtText-section-guide {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #181818;
  white-space: pre-line;
  word-break: break-word;
}
