.vantaggi-caratteristiche {
  font-weight: normal;
  margin: 10px;
  font-size: 16px;

  &-item {
    display: flex;
    flex-direction: row;
    padding-top: 10px;

    label.content-main-section--right-bar-small-caption {
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
    }
  }
}

.vantaggi-caratteristiche-item-green {
  ul {
    list-style: none;

    li {
      position: relative;

      &::before {
        content: "";
        width: 14px;
        height: 4px;
        position: absolute;
        background-color: $green;
        left: -26px;
        top: 10px;
      }
    }
  }
}

.vantaggi-caratteristiche-item-red {
  ul {
    list-style: none;

    li {
      position: relative;

      &::before {
        content: "";
        width: 14px;
        height: 4px;
        position: absolute;
        background-color: $red-monza;
        left: -26px;
        top: 10px;
      }
    }
  }
}

.block-chili-header {
  height: 110px;
  margin: 0 0 24px;
  padding: 24px 16px 24px 16px;
  color: $white;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  >p {
    font-size: 20px;
    margin-bottom: 0.25rem;

    &+p {
      font-size: 16px;
    }
  }
}

.bg-red {
  background-color: $red-monza;
}

.bg-chili-gradient-green {
  background-image: linear-gradient(to bottom, $chartVariant9, $chartVariant10);
}

.block-chili-paragraph-header {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $darkest-grey;
  font-size: 20px;
  line-height: 1.2;
}

.block-chili-paragraph-description {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  position: relative;
  line-height: 1.5;
  font-size: 16px;
}

.block-chili-image {
  max-width: 100%;
  max-height: 100%;
}

.description-block-mutuo {
  &>p {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $darkest-grey;
  }

  & p~ul {
    max-width: 50%;
    list-style: none;

    li {
      position: relative;

      &::before {
        content: "";
        width: 14px;
        height: 4px;
        position: absolute;
        background-color: $red-monza;
        left: -26px;
        top: 10px;
      }
    }
  }
}

.accordion-mutuo-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $asphalt;
  text-transform: initial;

  &>span {
    text-transform: initial;
    display: block;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.33;
    color: $dark-grey-accessibility;
  }
}

.accordion-block-mutuo-box {
  padding: 10px 0 17px 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: $white;
}

.block-campagna-header {
  height: 110px;
  padding: 24px 16px 24px 16px;
  color: $white;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.block-campagna-table {
  &>svg {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

.salute-dental-img {
  width: 50px;
  height: 64px;
}

.salute-dental-titolo {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $red-monza;
}

.salute-dentale-item-img {
  max-height: 44px;
  max-width: 44px;
}

.smart-card-section-container {
  position: relative;
  min-height: 450px;

  &>img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;

    &.smart-card-section-inner-image {
      position: absolute;
      left: 3px;
      top: 18px;
    }
  }
}

.direction-row-reverse {
  flex-direction: row-reverse;
}

.apple-pay-section-container {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column !important;
  }
}

.apple-pay-title-container {
  margin-top: auto;
  margin-bottom: auto;
}

.apple-pay-sub-section-container {
  position: relative;
  margin-left: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column !important;
  }

  background-color: $light-grey;

  &>img {
    max-width: 250px;
    max-height: 250px;

    &.apple-pay-section-inner-image {
      margin: 25px;

      @media (max-width: $breakpoint-tablet) {
        margin: auto;
      }
    }
  }
}

.red-corner {
  position: absolute;
  top: 25px;
  left: 20px;
  width: 25px;
  height: 25px;
  border-top: 5px solid $red-monza;
  border-left: 5px solid $red-monza;
}

.text-container {
  padding-top: 25px;
  padding-left: 25px;
}