.prestito-view {

  .section-step3,
  .line1-step3 {
    background-color: transparent;
    padding-left: 0;
  }

  .input-control {
    margin-bottom: 0;
  }

  .prestitoIdea {
    &-title {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $asphalt;
    }

    &-paragraph {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $darkest-grey;
    }
  }

  .notificheText {
    padding-left: 16px;
    padding-right: 16px;
  }

  .notifice-legenda-item {
    ul {
      list-style: none;

      li {
        position: relative;

        &::before {
          content: "";
          width: 14px;
          height: 4px;
          background-color: $red-monza;
          position: absolute;
          left: -26px;
          top: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .line1-step3 {
      .line1Col2-step3 {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
}