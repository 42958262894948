.modal-dialog {
  max-width: $breakpoint-tablet;
}

.mesaggi-bloccanti-modal {


  .modal-content {
    border-radius: 0;

    .modal-header {
      border: none;

      .modal-title {
        padding-left: 5px;
        font-family: inherit;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: $red-monza;
      }
    }

    .modal-body {
      font-family: inherit;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $darkest-grey;
      margin-bottom: 16px;
      // padding: 25px;

      .messaggi-bloccanti-modal__image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        background-color: $light-grey;
        padding: 20px;
      }
    }

    .modal-footer {
      border: none;
      background-color: $light-grey;

      &.justify {
        justify-content: space-between;
      }

      .mesaggi-bloccanti-modal__close-button {
        width: 100%;
        height: 48px;
        padding: 8px 0;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.4px;
        font-family: inherit;

        @media (min-width: $breakpoint-mobile-landscape) {
          width: 220px;
        }

        &.button-disable {
          opacity: 0.5;
        }
      }
    }
  }

  &.modale-cobadge {
    .modal-content {

      .modal-header {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        background-color: $asphalt;

        .modal-title {
          color: $white;
        }
      }

      .modal-body {
        margin-bottom: 0px;
      }
    }
  }
}

.font-size-16 {
  font-size: 16px;
}

.rapporti-table-row {
  padding-top: 2%;
  padding-bottom: 4%;
  border-bottom: 1px solid $medium-dark-grey;
}

.rapporti-table-text {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.no-rapporti-modal {
  & .modal-footer {
    border: none;
    background-color: $light-grey;
  }

  & .modal-body {
    min-height: 60px;
  }

  .modal-content {
    border: none;
    border-radius: 0;

    .modal-header .modal-title {
      padding-left: 5px;
      font-family: inherit;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $black;

      & .modal-header {
        border-bottom: 1px solid $dark-grey-bottom;
      }
    }
  }

  .no-rapporti-modal__close-button {
    width: 220px;
    height: 48px;
    padding: 8px 0;
    color: $white;
    background-color: $asphalt;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    font-family: inherit;
    border: none;
  }
}



.scadenza-carte-message {
  p {
    text-align: center;

    &.italic {
      font-style: italic;
    }
  }
}

.scadenza-carte-list {
  .row {
    border-bottom: 1px solid $medium-dark-grey;
    padding: 4px 0px;

    .text-red {
      color: $red-monza;
    }
  }
}