.multiSelectContainer {
  position: relative;
  text-align: left;
  width: 100%;
  border-radius: 0;
}

.searchWrapper {
  position: relative;
  border: none;
  padding: 0;
  border-radius: 0;
  min-height: 28px;
}

.no-selections-label {
  color: $dark-grey-accessibility;
}

.multiSelectContainer input {
  border: none;
  margin-top: 3px;
  background: transparent;
}

.multiSelectContainer input:focus {
  outline: none;
}

.chip {
  padding: 4px 10px;
  background: #0096fb;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: $white;
  white-space: nowrap;

  &--close-icon {
    top: 0;
    height: 15px !important;
    width: 15px !important;
    position: static !important;
    cursor: pointer;
    margin-right: -5px;
  }
}

.multiselect-chips {
  padding: 2px 5px 2px 10px;
  margin-bottom: 0;
  vertical-align: top;
  background-color: $asphalt;
  color: $white;
  border-radius: 0;
}

.singleChip {
  background: none;
  border-radius: none;
  color: inherit;
  white-space: nowrap;
}

.singleChip i {
  display: none;
}

.optionListContainer {
  position: absolute;
  width: 100%;
  background: $white;
  border-radius: 4px;
  margin-top: 1px;
  z-index: 2;
  right: 0px;
}

.multiSelectContainer ul {
  display: block;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 250px;
  overflow-y: auto;
}

.ul-list {
  display: block !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  max-height: 250px !important;
  overflow-y: auto !important;
}

.multiSelectContainer li {
  padding: 10px 10px;
}

.multiSelectContainer li:hover {
  background: #0096fb;
  color: $white;
  cursor: pointer;
}

.option-class {
  display: flex;
  align-items: center;
  padding: 10px;
}

.highlightOption {
  background: #0096fb;
  color: $white;
}

li.groupHeading {
  color: #908e8e;
  pointer-events: none;
  padding: 5px 15px;
}

li.groupChildEle {
  padding-left: 30px;
}