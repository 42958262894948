.address-book- {
  &field {
    display: flex;
    flex: 1;
    flex-direction: column;
    // height: 45px;
    margin-left: 8px;
  }

  &column {
    flex-direction: column;
  }

  &row {
    flex-direction: column;

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
    }
  }
}
