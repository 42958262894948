.switch-button {
  color: $black;
  background-color: $white;
  border-radius: 2px;
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  -webkit-box-shadow: $medium-shadow;
  -moz-box-shadow: $medium-shadow;
  box-shadow: $medium-shadow;

  label {
    flex: 1 1 0%;
    color: rgb(52, 53, 53);
    font-weight: bold;
    margin: 10px;
  }

  .switch-button-icon {
    flex-grow: 0.1;
    margin: 15px;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    img {
      height: 30px;
      fill: $black;
    }
  }

  &-icon-left {
    margin-right: 5px;
  }

  &-icon-right {
    margin-left: 5px;
  }
}