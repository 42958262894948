.bollettinoFreccia-grid>div:first-child {
  grid-area: prefisso;
}

.bollettinoFreccia-grid>div:nth-child(2) {
  grid-area: codice;
}

.bollettinoFreccia-grid>div:nth-child(3) {
  grid-area: importo;
}

.bollettinoFreccia-grid>div:nth-child(4) {
  grid-area: iban;
}

.bollettinoFreccia-grid>div:nth-child(7) {
  grid-area: cin_importo;
}

.bollettinoFreccia-grid>div:nth-child(5) {
  grid-area: data;
}

.bollettinoFreccia-grid>div:nth-child(8) {
  grid-area: cin_intermedio;
}

.bollettinoFreccia-grid>div:nth-child(9) {
  grid-area: cin_complesso;
}

.bollettinoFreccia-grid>div:nth-child(6) {
  grid-area: note;
}

.bollettinoFreccia-grid>div:nth-child(10) {
  grid-area: carattere_esenzione;
}

.bollettinoFreccia-grid-step2>div:first-child {
  grid-area: prefisso;
}

.bollettinoFreccia-grid-step2>div:nth-child(2) {
  grid-area: importo;
}

.bollettinoFreccia-grid-step2>div:nth-child(3) {
  grid-area: iban;
}

.bollettinoFreccia-grid-step2>div:nth-child(4) {
  grid-area: cin_importo;
}

.bollettinoFreccia-grid-step2>div:nth-child(5) {
  grid-area: data;
}

.bollettinoFreccia-grid-step2>div:nth-child(6) {
  grid-area: cin_intermedio;
}

.bollettinoFreccia-grid-step2>div:nth-child(7) {
  grid-area: cin_complesso;
}

.bollettinoFreccia-grid-step2>div:nth-child(8) {
  grid-area: note;
}

.bollettinoFreccia-grid-step2>div:nth-child(9) {
  grid-area: carattere_esenzione;
}

.bollettinoFreccia-grid-step2>div:nth-child(10) {
  grid-area: a_favore;
}

.bollettinoFreccia-grid {
  display: grid;
  column-gap: 16px;
  grid-template-columns: 25% 25%;
  grid-template-rows: auto;
  grid-template-areas:
    "prefisso codice importo importo"
    "iban iban . cin_importo"
    "data data . cin_intermedio"
    ". . . cin_complesso"
    "note note . carattere_esenzione";

  @media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-template-areas:
      "prefisso codice"
      "iban iban"
      "importo importo"
      "data data"
      "note note"
      "cin_importo cin_intermedio"
      "cin_complesso carattere_esenzione";
  }
}

.bollettinoFreccia-grid-step2 {
  display: grid;
  column-gap: 12px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas:
    "prefisso importo"
    "iban  cin_importo"
    "data  cin_intermedio"
    "a_favore  cin_complesso"
    "note  carattere_esenzione";

  @media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-template-areas:
      "prefisso prefisso"
      "iban iban"
      "importo importo"
      "data data"
      "a_favore a_favore"
      "note note"
      "cin_importo cin_intermedio"
      "cin_complesso carattere_esenzione";
  }
}

.bollettinoFreccia-buttonGroup {
  position: relative;
  width: 100%;
}

.bollettinoFreccia-borders {
  border-top: solid 1px $black;
  border-bottom: solid 1px $black;
  display: flex;
  justify-content: flex-end;
  padding: 9.5px;
  margin-top: 23.5px;
  margin-bottom: 23.5px;
}

.flexRight {
  display: flex;
  justify-content: flex-end;
}