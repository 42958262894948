.sidebar-animated-menu.collapsed {
  display: none;
  transition: opacity 0.1s ease-in-out;
  width: 96px;

  @media (min-width: $breakpoint-tablet-landscape) {
    display: flex;
  }
}

.sidebar-animated-menu {
  .menu-card {
    height: 104px;
    cursor: pointer;
    background-color: $asphalt;

    .menu-item {
      font-size: 16px;
      line-height: 15px;
      font-weight: bold;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 4px;
    }
  }

  .menu-card:hover {
    background-color: $darkest-grey;
  }
}

.button-item-menu {
  cursor: pointer;
  background-color: $white;
  color: $darkest-grey !important;

  &--label {
    line-height: 1.2;
  }
}

.button-item-menu:hover {
  background-color: $light-grey !important;

  p {
    color: $darkest-grey !important;
  }
}