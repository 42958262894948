  .widgetView-fabFavorite {
    color: $black;
    // backgroundColor: #F6F6F6;
    border-radius: 99px;
    // height: 40;
    display: flex;
    // justifyContent: center;
    // alignContent: center;
    // alignItems: center;
    // boxShadow: 1px 1px 1px 2px #A9A9A9;
    flex-direction: row;
  }

  .txt-fabFavorite {
    margin-left: 15px;
    margin-right: 10px;
    font-size: 13px;
    margin-bottom: 0;
    padding-top: 7px;
    flex: 1;
    vertical-align: middle;
    // border: 1px solid black;
  }

  .iconView-fabFavorite {
    // margin-right: 5px;
    // borderRadius: 99;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    // border: 1px solid black;
  }