.patrimonioBox-section{
    margin-left: 18px;
      margin-right: 25px;
  }
  
  .patrimonio-text{
    margin-left: 6px;
  }

  .patrimonio-title{
      margin-left: 6px;
  }

  .align-depo-box-center{
    margin-bottom: 20px;
  }