.titleADV {
  font-size: 20px;
  font-weight: bold !important;
}

.scadenza {
  margin-bottom: 8px;
}

.aggiorna-questionario {
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  max-width: fit-content;
  display: flex;
}

.aggiorna-questionario:hover {
  cursor: pointer;
}

.aggiorna-questionario-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;
}

.errore-aggiorna-questionario-button {
  color: $error;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 16px;
}

.aggiorna-documento {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 19px;
  font-size: 16px;
  font-weight: bold;
}

.button-container {
  flex-direction: row;
  display: flex;
}

.spacing {
  flex: 1;
}

.back-button {
  margin-right: 16px;
}

@media screen and (max-device-width: 768px) {
  .button-container {
    flex-direction: column;
  }
}

@media screen and (max-device-width: 768px) {
  .spacing {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-device-width: 768px) {
  .back-button {
    margin-right: 0;
  }
}

@media screen and (max-device-width: 768px) {
  .nav-button {
    width: 100%;
  }
}

.inputContainer {
  padding: 8px 8px 16px 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: $white;
  margin-bottom: 40px;
  margin-top: 24px;
}

.titleStep {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  text-overflow: ellipsis;
}

@media screen and (max-device-width: 768px) {
  .description {
    display: block;
    /* or inline-block */
    overflow: hidden;
    max-height: 5.6em;
    line-height: 1.8em;
  }
}

@media screen and (max-device-width: 768px) {
  .titleStep {
    display: block;
    /* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
  }
}

.input-row {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.title-campo {
  font-size: 16px;
  font-weight: bold;
}

.title-campo-regular {
  font-size: 16px;
}

.checkbox-text {
  font-weight: bold !important;
}

.upload-document {
  background-color: $light-grey-alternative;
  border: dashed 2px $darker-grey !important;
  width: 100%;
  justify-content: center;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
}

.upload-area {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.upload-photo-title {
  color: $darker-grey;
}

.info-personali-text {
  color: $darker-grey;
  font-size: 16px;
  margin-bottom: 8px;
}

.info-persionali-container {
  display: flex;
  flex-direction: row;
}

.option {
  margin-right: 20px;
}

.option-wrapper {
  margin-right: 42px;
}

.rapporto-container {
  margin-bottom: 48px;
}

.rapporto-flag {
  color: $black !important;
}

.rapporto-select-container {
  margin-top: 28px;
}

.rapporto-natura-scopo-title {
  font-size: 16px;
}

@media screen and (max-device-width: 768px) {
  .info-persionali-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-device-width: 768px) {
  .option {
    margin-right: 0;
    margin-bottom: 4px;
  }
}

@media screen and (max-device-width: 768px) {
  .second-option {
    margin-top: 8px;
  }
}

.riepilogo-container {
  margin-top: 38px;
}

.back-riepilogo {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: fit-content;
}

.back-riepilogo:hover {
  cursor: pointer;
}

.back-text {
  font-weight: bold;
}