.titolo-gestione-appuntamento {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: bold;
  color: $black;
}

.sottotitolo-gestione-appuntamento {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  color: $black;
}

.sottotitolo-gestione-appuntamento-light {
  font-weight: normal;
}

.cardOrarioConsulenza {
  // .disabled {
  //   color: rgba(0, 0, 0, 0.3);
  // }

  .button-text svg {
    fill: $white;
  }

  .freccia-sinistra {
    transform: rotate(180deg) !important;
  }

  .cardOrarioConsulenza-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .cardOrarioConsulenza-item-deselected {
      padding: 16px 8px;
      cursor: pointer;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
      margin-top: 20px;
      width: 30%;

      &:hover {
        box-shadow: 0 6px 16px 2px rgba(0, 0, 0, 0.16);
        background-color: $light-grey;
        color: $asphalt;
      }

      @media (max-width: 800px) {
        flex-direction: column;
        width: 100%;
      }
    }

    .cardOrarioConsulenza-item-selected {
      // min-width: 250px;
      padding: 16px 8px;
      cursor: pointer;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
      margin-top: 20px;
      width: 30%;
      background-color: $asphalt;
      color: $white;

      //  background-color: white;
      &:hover {
        box-shadow: 0 6px 16px 2px rgba(0, 0, 0, 0.16);
        background-color: $asphalt;
        color: $white;
      }

      @media (max-width: 800px) {
        flex-direction: column;
        width: 100%;
      }
    }

    .card-bozze-wrapper {
      box-shadow: none;
    }
  }
}

.cardModalitaIncontro {
  label {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $dark-grey-bottom;
  }
}

.orarioConsulenza-card-text {
  // font-family: UniversLTStd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $darkest-grey;
}

.orarioConsulenza-card-text-selected {
  // font-family: UniversLTStd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $light-grey;
}

.container-icon {
  display: flex !important;
}

.card-info-appuntamento {
  background-color: #e8e7e7;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.cardNoteConsulenza {
  textarea {
    border: 1px solid rgba(24, 24, 24, 0.3) !important;
  }
}

.slotSelezionato {
  background-color: $asphalt;
  margin-top: 16px;
  color: $white;
  padding: 8px;
  padding-bottom: 4px;
  padding-left: 16px;
  background-color: $asphalt;

  p:nth-of-type(2),
  p:nth-of-type(3) {
    margin-bottom: 8px;
  }

}