.container {
  background-color: $light-grey;
  height: 200px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.title {
  color: $dark-grey-accessibility;
  font-weight: bold;
}

.icon_container {
  background-color: $dark-grey-bottom;
  border-radius: 50%;
  width: 69px;
  height: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
}