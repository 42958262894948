.button-text {
  line-height: 20px;
  display: flex;
  flex-flow: row;
  flex-direction: row;
  cursor: pointer;
  background-color: transparent;
  border: none;

  label {
    color: $darkest-grey;
    font-size: 16px;
    margin: auto 4px auto auto;
    font-weight: 700;
    cursor: pointer;
    align-items: center;
  }

  svg,
  img {
    fill: $red-monza;
    display: flex;
    margin: auto 0 auto 4px;
  }
}

.button-text.button-on-left {
  label {
    margin: auto 4px auto 4px;
  }
}

.button-text.button-cta-nexi-debit {
  label {
    margin: auto 8px auto 8px;
  }

}

.button-text.button-text-not-red {
  svg {
    fill: inherit;
  }
}

.button-text.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.button-text:hover {
  label {
    color: $black;
    font-size: 16px;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
  }

  svg {
    fill: $bright-red;
  }
}

// testate su Scarica elenco movimenti in PDF
.button-text.image-on-left {
  flex-direction: row-reverse;

  svg,
  img {
    margin: auto 4px auto 0;
  }

  label {
    margin: auto auto auto 4px;
  }
}