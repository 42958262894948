.root-switch {
  z-index: 1;
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: $menu-topbar-height calc(100% - #{$menu-topbar-height});
  grid-template-rows: $menu-topbar-height calc(100% - #{$menu-topbar-height});
  -ms-grid-columns: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.central-switch {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0 100%;
  grid-template-columns: 0 100%;
  -ms-grid-rows: 100%;
  grid-column: 1;
  -ms-grid-column: 1;
  grid-row: 2;
  -ms-grid-row: 2;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (min-width: $breakpoint-tablet-landscape) {
    -ms-grid-columns: $menu-sidebar-width calc(100% - #{$menu-sidebar-width});
    grid-template-columns: $menu-sidebar-width calc(100% - #{$menu-sidebar-width});
  }
}

.main-switch {
  grid-column: 2;
  -ms-grid-column: 2;
  grid-row: 1;
  -ms-grid-row: 1;
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  overflow: hidden;
}
