.itemView-button-level3 {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  color: $dark-grey-accessibility;
  background-color: $white;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 10px;
  transition: box-shadow background-color color 0.2s ease-in-out;
}

.itemView-button-level3.active:not(.itemView-button-level3--selected) {
  cursor: pointer;
}

.itemView-button-level3.disabled {
  color: $dark-grey-accessibility;
  background-color: $light-grey;
  font-weight: normal;
}

.itemView-button-level3.active.darker {
  color: $darkest-grey;
}

.itemView-button-level3.itemView-button-level3--selected,
.itemView-button-level3.itemView-button-level3--selected.darker {
  background-color: $red-monza;
  color: $white;
}

.text-button-level3 {
  vertical-align: center;
  text-align: center;
  display: flex;
  font-size: $section-font-size;
  font-weight: $section-font-weight;
  margin: 10px;
}

.text-button-level3-light {
  vertical-align: center;
  text-align: center;
  display: flex;
  font-size: $section-font-size;
  margin: 10px;
}

.button-tabs {
  display: flex;
  flex-direction: row;
  margin: -16px -16px 0 -16px;
  padding: 16px;

  overflow-y: scroll;
  -ms-overflow-style: none; //ie
  scrollbar-width: none; //ff

  &::-webkit-scrollbar {
    display: none;
  }
}

.itemView-button-level3.active:not(.itemView-button-level3--selected):hover {
  box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.16);
  background-color: $light-grey;
  color: $asphalt;
}

.itemView-button-level3.active.darker:hover {
  color: $black;
}

.itemView-button-level3--selected.active.darker:hover,
.itemView-button-level3--selected.active:hover {
  background-color: $red-monza;
  color: $white;
}

.pointer {
  cursor: pointer;
}