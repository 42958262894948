.elenco-riba-container>h3 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $dark-grey-accessibility;
}

.elenco-riba-container .movimenti {
  margin-left: 0;
  margin-right: 0;
}

.elenco-riba-container .message {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.riba-avissi-container {
  margin-bottom: 10px;
}

.riba-rightbar-button {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $darkest-grey;
}