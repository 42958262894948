.cerca-titolo {
  &-form {
    background-color: $light-grey;
    padding: 4px 15px 16px 16px;

    .form-field__control {
      background-color: $white;
    }
  }

  .custom-table {
    .form-title {
      margin-top: 32px;
    }

    .action-group {
      display: flex;
      width: 100%;
      height: auto;
    }
  }
}

.form-filiale_view {
  background-color: $light-grey;
  padding: 16px 16px 16px 12px;

  .form-filiale-container {
    display: flex;
    flex-direction: row;

    .left-side {
      display: flex;
      flex-direction: column;
    }

    .left-side>img {
      width: 175px;
      height: auto;
    }
  }
}

.attention-paragraph {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $darkest-grey;
}

.bonifico-mybank {
  .timer-component {
    margin-top: 24px;
  }

  .componente-autorizzativo_parent {
    width: 100%;
    height: 4px;
    position: relative;
  }

  .componente-autorizzativo-header-progress_transition {
    transition: width 0.5s linear;
  }

  .componente-autorizzativo-header-progress {
    position: relative;
    height: 4px;
    z-index: 1;

    &_inner {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $dark-grey-bottom;
      top: 0;
    }

    .svg {
      fill: white !important
    }
  }

  .width-100 {
    width: 100%;
  }

  .width-0 {
    width: 0%;
  }

  .componente-autorizzativo-title {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $darkest-grey;

    span {
      font-weight: bold;
    }
  }
}

.acquista-titolo {
  .form-title {
    margin: 16px 0 16px 0;
  }

  .custom-table {

    .table thead tr,
    .table tbody tr {
      border: none;

      th {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.33;
        color: $darkest-grey;
        padding-left: 5px;
      }

      td {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.25;
        color: $darkest-grey;
        padding-left: 5px;
      }
    }

    .table tbody tr:hover {
      background-color: transparent;
    }

    &.book-table {
      .table thead tr {
        border-bottom: solid 1px #979797;
      }

      .table tbody tr {
        border-bottom: solid 1px $medium-dark-grey;
      }

      th {
        padding: 5px 12px 12px 12px;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.25;
        color: $darkest-grey;

        &.leftCell {
          border-right: solid 1px #979797;
        }

        &.rightCell {
          padding-left: 20px;
        }
      }

      td {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.25;
        color: $darkest-grey;

        &.leftCell,
        &.rightCell {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: $darkest-grey;
        }

        &.leftCell {
          border-right: solid 1px #979797;
        }

        &.rightCell {
          padding-left: 20px;
        }
      }
    }
  }

  .table-quotazioni {
    thead {
      th {
        padding: 8px 12px 4px 0;
      }
    }

    tbody {
      tr {
        height: auto;
      }

      td {
        padding: 0 12px 0 0;
      }
    }
  }

  .verifica-product-text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $darkest-grey;
    padding-left: 8px;
  }

  p.verifica-product-text {
    padding-left: 16px;
    margin-top: 16px;
  }

  .verifica-product-header {
    font-weight: bold;
  }

  .checkBox-wraper {
    margin-left: 20px;
  }

  .table-wrapper {
    margin-bottom: 32px;
    padding: 16px;
    background-color: $light-grey;

    .form-title {
      margin: 0 0 16px 0;
    }

    .accordion-container {
      margin-top: 0;

      .accordion-title-tab {
        color: $darkest-grey;
      }
    }

    .accordion-title-tab {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: $dark-grey-accessibility;
      padding-left: 0px;
    }
  }
}

.isin-border {
  text-decoration: underline;
}

.modale-andamento-titolo {
  .modal-dialog {
    max-width: 992px;

    .modal-footer {
      border: none;
      background-color: $light-grey;

      .notification-modal__close-button {
        width: 160px;
        /*204px*/
        height: 48px;
        font-family: inherit;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.4px;
        text-align: center;
        color: $asphalt;
        background: none;
      }

      .notification-modal__confirm-button {
        background-color: $red-monza;
        color: $white;
        width: 160px;
        height: 48px;
        font-family: inherit;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.4px;
        text-align: center;
        text-transform: uppercase;
      }

      .no-border_button {
        border: none;
      }
    }
  }

  .modal-header {
    font-size: 20px;
    font-weight: bold;
  }

  .andamento-titolo-periodo {
    margin-bottom: 16px;
  }

  .andamento-titolo-dati {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
    }

    .andamento-titolo-grafico {
      margin: 16px 0 0 0;
      background-color: $light-grey;
      min-width: 380px;
      max-width: 624px;
      width: 100%;

      .andamento-xaxis {
        font-size: 16px;
      }
    }

    .andamento-titolo-dettagli {
      margin: 16px 0 0 0;

      @media (min-width: $breakpoint-tablet) {
        margin: 16px 0 0 16px;
      }

      &-mercato {
        font-size: 18px;
        color: $darkest-grey;
      }

      &-aggiornamento {
        font-size: 16px;
        color: $black;
        font-weight: 300;
      }

      &-stats {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;

        &-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          line-height: 1.43;
          margin: 8px 0 8px 0;
          justify-content: space-between;
        }

        &-label {
          font-size: 16px;
          color: $darkest-grey;
          text-align: left;
        }

        &-value {
          font-size: 16px;
          color: $darkest-grey;
          text-align: right;
        }
      }
    }
  }
}