@import "styles/generated/_colors.scss";
@import "styles/generated/_fonts.scss";
@import "styles/generated/_uiblock.scss";
@import "styles/generated/_buttons.scss";
@import "styles/_general.scss";
@import "styles/_header.scss";
@import "styles/_home.scss";
@import "styles/components//_datePicker.scss";
@import "styles/_saldoemovimenti.scss";
@import "styles/_documenti.scss";
@import "styles/_sidebar.scss";
@import "styles/_balance.scss";
@import "styles/_banner.scss";
@import "styles/bonifico";
@import "styles/_bonifico-step1.scss";
@import "styles/_bonifico-step2.scss";
@import "styles/_bonifico-step3.scss";
@import "styles/_bannerDX.scss";
@import "styles/_bannerDXLight.scss";
@import "styles/_switch-page.scss";
@import "styles/_breadcrumbs.scss";
@import "styles/_button-item-menu.scss";
@import "styles/_buttonText.scss";
@import "styles/_carousel.scss";
@import "styles/_fabFavourite.scss";
@import "styles/_fabVocal.scss";
@import "styles/_item-bozze.scss";
@import "styles/_item-buttonLevel3.scss";
@import "styles/_item-carousel.scss";
@import "styles/_item-esito.scss";
@import "styles/_item-menu.scss";
@import "styles/_item-oneClick.scss";
@import "styles/_item-quickOpr.scss";
@import "styles/_menu1ClickExpanded.scss";
@import "./component/snackBar/snackbar";
@import "styles/_section-esito.scss";
@import "styles/_section-movement.scss";
@import "styles/_section-sca.scss";
@import "styles/_stepper.scss";
@import "styles/_dotStepper.scss";
@import "styles/_sectionMenuLvl3.scss";
@import "styles/_widgetBilancio.scss";
@import "styles/_widgetPatrimonio.scss";
@import "styles/_widgetPrestiti.scss";
@import "styles/input_text.scss";
@import "styles/_section-oneclick.scss";
@import "styles/_anatocismo.scss";
@import "styles/_archivio-bozze.scss";
@import "styles/_oneClick.scss";
@import "styles/_mifid.scss";
@import "styles/tuoConsulenteWidget";
@import "./component/guideSection/section-guide.scss";
@import "./component/SelectBox/selectBox.scss";
@import "./component/bannerElimina/bannerElimina.scss";
@import "./component/inputAutoCompleteRubrica/inputAutoComplete.scss";
@import "./component/inputAutoCompleteBillers/inputAutoCompleteBillers.scss";
@import "./component/inputAutoCompleteGeneric/rubricaLight.scss";

@import "styles/_layout-generale.scss";
@import "styles/_sidebar-menu.scss";
@import "styles/dragAndDropHome";
@import "styles/questionario-adeguata-verifica";

@import "styles/components/_switchButton.component.scss";
@import "styles/components/_oneclick.component.scss";
@import "styles/components/_inputControl.component.scss";
@import "styles/components/_inputBox.component.scss";
@import "styles/components/_multiselect.component.scss";
@import "styles/components/_card.component.scss";
@import "styles/components/_fullPageNotification.component.scss";
@import "styles/components/_bankComponenteAutorizzativo.scss";
@import "styles/components/_notificationModal.component.scss";
@import "styles/components/_serviceItem.component.scss";
@import "styles/components/_rectangle.component.scss";
@import "styles/_rav.scss";
@import "styles/_rendimenti.scss";
@import "styles/_mav.scss";
@import "styles/rightShoulder";
@import "styles/rubrica-contatti";
@import "styles/oneclick-card";
@import "styles/bollettino-freccia";
@import "styles/bollettino-postale";
@import "styles/bolloACI.scss";
@import "./component/kvp/_kvp.scss";
@import "styles/assegni";
@import "styles/f24-ordinario";
@import "styles/cbill";
@import "styles/smartCard";
@import "styles/_riba.scss";
@import "styles/_acordionCustom.scss";
@import "styles/_tableCustom.scss";
@import "styles/_pianoMutui.scss";
@import "styles/_bloccoUtente.scss";
@import "styles/_menuProfilo.scss";
@import "styles/_carta-credito.scss";
@import "styles/_bonifico-deposito.scss";
@import "styles/components/_loader.component.scss";
@import "styles/_massimaliUtente.scss";
@import "styles/_bancomat-pay.scss";
@import "styles/_polizze.scss";
@import "styles/components/_loader.component.scss";
@import "styles/timeDeposit";
@import "styles/components/listino.card";
@import "styles/components/rangeSlider.component";
@import "styles/_scadenzaTitoli.scss";
@import "styles/blocco-carte.scss";
@import "styles/variazioneCanali.scss";
@import "styles/elencoRapporti";
@import "styles/_variazioneCanali.scss";
@import "styles/_messaggioContainer.scss";
@import "styles/_messaggiComunicazioniUtente.scss";
@import "styles/consesiTerzeParti";
@import "styles/gestioneCredenziali";
@import "styles/_commons.scss";
@import "styles/_f24-ordinario-ie11.scss";
@import "styles/_cercaTitolo.scss";
@import "styles/_datiPersonali.scss";
@import "styles/components/_modalPreferiti.scss";
@import "styles/components/_modalPsd2.scss";
@import "styles/components/_tourContestuale.scss";
@import "styles/components/_modalPersonalizzaHome.scss";
@import "styles/components/_modalPersonalizzaMassimale.scss";
@import "styles/components/_messaggiBloccanti.scss";
@import "styles/_postLogin.scss";
@import "styles/bilancio";
@import "styles/_datiFiliale";
@import "styles/_notificaPush.scss";
@import "styles/_patrimonio.scss";
@import "styles/_mercato.scss";
@import "styles/_faq.scss";
@import "styles/_timeDepositBox.scss";
@import "styles/filialeVirtuale";
@import "styles/_storicoOrdine.scss";
@import "styles/_gestionePolizze.scss";
@import "styles/_prestito.scss";
@import "styles/components/_inputRadio.scss";
@import "styles/components/_inputUpload.scss";
@import "styles/_myBank.scss";
@import "styles/_fidoIsiDispo.scss";
@import "styles/components/item-TributiLocali";
@import "styles/_pct.scss";
@import "styles/catalogoProdotti";
@import "styles/components/templateCatalogo";
@import "styles/dossierTitoli";
@import "styles/firmaProposte.scss";
@import "styles/multiSca.scss";
@import "styles/addressBook.scss";
@import "styles/_consulenzaVirtuale.scss";
@import "styles/prestito-idea";
@import "styles/components/_boxUpload.scss";
@import "styles/elencoAppuntamenti";
@import "styles/_widget-consulenza.scss";
@import "styles/_searchable-select.scss";
@import "styles/_text.scss";
@import "styles/_pagination.scss";
@import "styles/_nexiDebitAcquisto.scss";

*:focus-visible {
  outline: 2px solid $black;
  outline-offset: -2px;
}


button:focus-visible {
  outline: 2px solid $black;
  outline-offset: -2px;
}

input:focus-visible {
  border-bottom: 2px solid $black;
}

select:focus-visible {
  border-bottom: 2px solid $black;
}

.menu-row:focus-visible {
  outline: 2px solid $red-active;
  outline-offset: -2px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: $white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white;
  height: 400px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.flex-1 {
  flex: 1;
}