.pagina-documenti {
  .tableTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.14;
    color: $dark-grey-accessibility;
    text-transform: uppercase;
  }

  .bottoni-imposta-letti {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    align-items: center;

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
    }
  }

  .search-alert {
    font-size: 16px;
    color: $red;
  }
}