$breakpoint-mobile: 360px;
$breakpoint-mobile-landscape: 640px;
$breakpoint-tablet: 768px;
$breakpoint-tablet-landscape: 1024px;
$breakpoint-desktop: 1280px;

$menu-topbar-height: 64px;
$menu-sidebar-width: 110px;
$shoulder-width: 368px;

$primary-color: rgb(255, 0, 0);
$primary-background-color: rgb(68, 68, 68);

$cards-background-color: "#fff";
$cards-shadow-color: "#000";

$font-secondary-color: #343535;

$primary-font-size: 25px;
$primary-font-weight: bold;
$primary-color: #707070;

$secondary-font-size: 20px;

$balance-font-size: 25px;
$balance-font-weight: bold;
$balance-color: #343535;

$section-font-size: 16px;
$section-font-weight: bold;
$section-color: #707070;

$color-sparkasse-concrete: #f6f6f6;
$color-sparkasse-asphalt: #3f3f3f;
$color-sparkasse-monza: #df0025;

$color-positive-amount: #419816;
$color-negative-amount: #b2001c;

$color-positive-amount-light: #31ba68;
$color-negative-amount-light: #fa4248;

$color-confidence-unknown: #3f3f3f;
$color-confidence-high: #31ba68;
$color-confidence-medium: #facc42;
$color-confidence-low: #fa4248;

@font-face {
  font-family: "Univers LT Std";
  src: url("../assets/fonts/UniversLTStd.eot");
  src: url("../assets/fonts/UniversLTStd.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/UniversLTStd.woff2") format("woff2"), url("../assets/fonts/UniversLTStd.woff") format("woff"),
    url("../assets/fonts/UniversLTStd.ttf") format("truetype"),
    url("../assets/fonts/UniversLTStd.svg#UniversLTStd") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Univers LT Std";
  src: url("../assets/fonts/UniversLTStd-Light.eot");
  src: url("../assets/fonts/UniversLTStd-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/UniversLTStd-Light.woff2") format("woff2"),
    url("../assets/fonts/UniversLTStd-Light.woff") format("woff"),
    url("../assets/fonts/UniversLTStd-Light.ttf") format("truetype"),
    url("../assets/fonts/UniversLTStd-Light.svg#UniversLTStd-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  color: #343535;
  font-family: "Univers LT Std", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}