.f24-inps > .header {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  -ms-grid-row: 1;

  > span {
    display: block;
    margin-right: 5px;
    -ms-grid-row: 1;
  }

  > span:first-child {
    grid-area: sede;
    -ms-grid-column: 1;
  }

  > span:nth-child(2) {
    grid-area: contributo;
    -ms-grid-column: 2;
  }

  > span:nth-child(3) {
    grid-area: matricola;
    -ms-grid-column: 3;
  }

  > span:nth-child(4) {
    grid-area: periodo1;
    -ms-grid-column: 4;
  }

  > span:nth-child(5) {
    grid-area: periodo2;
    -ms-grid-column: 5;
  }

  > span:nth-child(6) {
    grid-area: importoDebiti;
    -ms-grid-column: 6;
  }

  > span:nth-child(7) {
    grid-area: importoCrediti;
    margin: 0px;
    -ms-grid-column: 7;
  }
}

.f24-input-list-motivoPagamento {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  -ms-grid-row: 1;

  > span:first-child {
    grid-area: sezione;
    display: block;
    // margin-right:5px;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  > span:nth-child(2) {
    grid-area: tributo;
    display: block;
    // margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  > span:nth-child(3) {
    grid-area: ente;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  > span:nth-child(4) {
    grid-area: rravvedimento;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 4;
  }

  > span:nth-child(5) {
    grid-area: immobiliareVariati;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 5;
  }

  > span:nth-child(6) {
    grid-area: acconto;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 6;
  }

  > span:nth-child(7) {
    grid-area: saldo;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 7;
  }

  > span:nth-child(8) {
    grid-area: numeroImmobiliari;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 8;
  }

  > span:nth-child(9) {
    grid-area: rate;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 9;
    -ms-grid-column-span: 2;
  }

  > span:nth-child(10) {
    grid-area: anno;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 11;
    -ms-grid-column-span: 2;
  }

  > span:nth-child(11) {
    grid-area: detrazione;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 13;
    -ms-grid-column-span: 2;
  }

  > span:nth-child(12) {
    grid-area: importoDebito;
    display: block;
    //  margin:0 5px;
    -ms-grid-row: 1;
    -ms-grid-column: 15;
    -ms-grid-column-span: 2;
  }

  > span:nth-child(13) {
    grid-area: importoCredito;
    display: block;
    //  margin-left:5px;
    -ms-grid-row: 1;
    -ms-grid-column: 17;
    -ms-grid-column-span: 2;
  }
}
