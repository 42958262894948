.dot-stepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 16px;
  width: 100%;
  margin: 16px 0;
  padding: 0 8px;
}

.dot-step {
  position: relative;
  flex: 1;
  height: 2px;
  padding: 0 8px;
  background-color: $dark-grey-bottom;

  &::before {
    content: "";
    position: absolute;
    top: -7px;
    left: -8px;
    width: 16px;
    height: 16px;
    background-color: $dark-grey-bottom;
    border-radius: 50%;
    transition: background-color 0.5s ease-in-out;
  }

  &.dot-left-filled {
    &::before {
      background-color: $red-monza;
      transition-delay: 0.5s;
    }
  }

  &:last-child:after {
    content: "";
    position: absolute;
    top: -7px;
    right: -8px;
    width: 16px;
    height: 16px;
    background-color: $dark-grey-bottom;
    border-radius: 50%;
    transition: background-color 0.5s ease-in-out;
  }

  &.dot-last-filled {
    &::after {
      background-color: $red-monza;
      transition-delay: 0.5s;
    }
  }

  .dot-step-bar {
    height: 100%;
    background-color: $red-monza;
    transition: width 0.5s ease-in-out;
  }
}
