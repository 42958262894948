.data-scadenza-label {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #9f9f9f;
}

.importo-tributo-label {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: right;
  color: $asphalt;
}

.valuta-importo-tributo {
  font-size: 16px;
}

.row-reverse {
  flex-direction: row-reverse;
}

.chip-tributi {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  min-width: 24px;
  padding: 0 6px;
  background-color: $red-monza;
  border-radius: 12px;
}

.chip-tributi-content {
  color: $white;
  font-size: 16px;
  font-weight: bold;
}