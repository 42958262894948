.patrimonio-view {
  .chartView-widget-patrimonio_container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
    }
  }

  .txtTitle-widget-patrimonio {
    margin: 0 68px 24px 16px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $dark-grey-accessibility;
  }

  .txtAmountChart-widget-patrimonio_value {
    font-size: 16px;
  }

  .widget-patrimonio_details_icon {
    margin-left: 10px;
  }

  .widget-patrimonio-button {
    align-self: flex-start;
  }

  .patrimoniText p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $darkest-grey;
    padding: 12px 17px 32px 18px;
  }

  .patrimonio-totaleInfo {
    margin-bottom: 32px;
    padding: 14px 16px 16px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    background-color: $color-sparkasse-concrete;
    border-top: 1px solid $color-sparkasse-asphalt;

    &_netto {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;
      margin-bottom: 8px;

      &_label {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $darkest-grey;
      }

      &_value {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.45px;
        margin-left: 8px;
      }

      &_currency {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.3px;
        color: $darkest-grey;
        margin-left: 3px;
        align-self: center;
      }
    }

    &_values {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: $breakpoint-mobile-landscape) {
        flex-direction: row;

        &_lordo {
          &:not(:first-child) {
            justify-content: flex-end;
          }
        }
      }

      &_lordo {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        margin-bottom: 0px;

        &_label {
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: $black;

          &:first-child {
            margin-right: 8px;
          }
        }

        &_value {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.4px;
          color: $darkest-grey;

          &:not(:first-child) {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .accordion-summary_value {
    align-self: center;
    margin-right: 16px;
    margin-bottom: 0;
  }

  .accordion-value-tab {
    display: flex;

    & span {
      align-self: center;
      margin-right: 17px;
    }
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #979797;
    padding-left: 15px;

    &_text {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: $darkest-grey;
      margin-right: 77px;

      &_mr {
        margin-right: 16px;
      }
    }
  }

  .accordion-footer {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px #979797;
    padding: 10px 17px 8px 15px;
    background-color: $light-grey-alternative;
    margin-bottom: 45px;

    &_text {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $black;
      margin-bottom: 0;

      &-value {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.45px;
        text-align: right;
        color: $positiveAmount;
        margin-bottom: 0;

        &_negative {
          color: $bright-red;
        }
      }
    }
  }

  .accordion-title-tab {
    padding-top: 16px;
    padding-left: 16px;
    margin-bottom: 12px;

    p {
      align-self: center;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
    }
  }

  .patrimonio-accordion {
    margin-top: 10px;

    .accordion-body>.accordion-row {
      padding: 0;
    }

    .accordion-container {
      margin-top: 0;
    }

    table {
      white-space: pre-line;
    }

    .custom-table {
      background-color: $color-sparkasse-concrete;

      .table tbody tr,
      .table thead tr {
        border: none;
      }
    }

    .accordion-row {
      .custom-table {
        margin: 0;
        width: 100%;
      }
    }
  }

  .custom-table-header {
    display: flex;
    justify-content: space-between;
    padding: 6px 12px 8px;
    background-color: $light-grey-alternative;

    &_title {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: $darkest-grey;
      margin-bottom: 0;
    }

    &_value {
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
      margin-left: 5px;
    }
  }
}

.assicurazioniTable {
  display: flex;
  justify-content: space-between;
  margin: 12px 16px;

  &-text {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $darkest-grey;
    margin-bottom: 0;
  }
}