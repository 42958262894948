  .menu-profilo-container {
    display: flex;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;

    .parent-items-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      >h3 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: var(--color-sparkasse-asphalt);
        padding: 0 15px;
      }

      .child-container {
        display: flex;
        align-items: flex-start;
        background-color: transparent;
        flex-direction: column;
        width: 100%;

        .item-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          padding: 0 10px;

          >h4 {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: $darkest-grey;
            margin-bottom: 0;
          }

          .caret-right {
            color: $dark-grey-bottom;
            fill: $dark-grey-bottom;
          }

          .ml-5 {
            margin-left: 5px;
          }
        }

        .item-container:hover {
          background-color: $white;
          cursor: pointer;

          >h4 {
            font-weight: bold;
          }

          .caret-right {
            color: $red-monza;
            fill: $red-monza;
          }
        }

      }

      .active-child {
        background-color: $white !important;

        .item-container {

          >h4 {
            font-weight: bold !important;
          }

          .caret-right {
            color: $red-monza !important;
            fill: $red-monza !important;
          }
        }
      }

    }
  }