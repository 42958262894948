.txtWidgetView-item-quickOpr {
  margin: 8px 0;
  overflow: hidden;

  font-size: 16px;
  line-height: 20px;
  color: $darkest-grey;
}

.iconView-item-quickOpr {
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}