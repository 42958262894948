.align-data-column {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.bold {
  font-weight: 700;
}

.card-view--body-view--import {
  font-size: 18px;
}

.sparkasse-grey {
  color: $dark-grey-accessibility
}

.data-text-spacing {
  line-height: 20px;
}