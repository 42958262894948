.container-listino {
  display: block;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 2%;
}

.radio-card-box {
  input {
    position: absolute;
    visibility: hidden;
    width: 0;
  }

  .radio-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px 64px;
  }

  .radio-button-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .radio-button-dot {
    position: relative;
    margin: 0 12px 20px 0;
    height: 24px;
    width: 24px;
    border: 1px solid $black;
    border-radius: 50%;
    background-color: $white;

    &::after {
      content: "";
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: $red-monza;
      transform: scale(0.65);
    }
  }

  input:hover~.radio-button-dot {
    background-color: #ccc;
  }

  input:checked~.radio-button-dot {
    background-color: $white;
    border: 2px solid $red-monza;

    &::after {
      display: block;
    }
  }
}

/* Hide the browser's default radio button */
.container-listino input {
  position: absolute;
  visibility: hidden;
  width: 0;
}

input[type="radio"].visually-hidden {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.radiobutton-listino.checked {
  background-color: $white;
  border: 2px solid $red-monza;
}

.radiobutton-listino.checked::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  /* Cerchio interno bianco */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Create a custom radio button */
.radiobutton-listino {
  position: absolute;
  margin-left: auto;
  margin-top: auto;
  height: 24px;
  width: 24px;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $black;
}

/* On mouse-over, add a grey background color */
.container-listino:hover input~.radiobutton-listino {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-listino input:checked~.radiobutton-listino {
  background-color: $white;
  border: 2px solid $red-monza;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radiobutton-listino:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the indicator (dot/circle) */
.container-listino .radiobutton-listino::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: $red-monza;
  transform: scale(0.65);
}

/* Style the indicator (dot/circle) */
.container-radio-autocomplete .radiobutton-listino::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: $red-monza;
  transform: scale(0.65);
}

/* Show the indicator (dot/circle) when checked */
.container-listino input:checked~.radiobutton-listino:after {
  display: block;
}

.card-plastic {
  display: grid;

  img {
    width: 200px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  span {
    font-size: 16px;
    line-height: 20px;
  }
}

.card-listino {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}

.description-listino {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $black;
}

.radiobutton-listino-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 24px 0 32px;
  height: 24px;

  &.maxHeight {
    height: fit-content;
  }
}

/* margin left 6%*/
.ml-6 {
  margin-left: 6%;
}

/* padding top 3%*/
.pt-3 {
  padding-top: 3%;
}

/* flex direction column */
.direction-column {
  flex-direction: column;
}

/* flex direction row*/
.direction-row {
  flex-direction: row;
}

/* flex 2 */

.flex-2 {
  flex: 2;
}