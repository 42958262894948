.top-page-notification {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 44px;
  min-height: 166px;
  opacity: 0.94;
  background-color: $asphalt;

  &-close-icon {
    z-index: 6;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .carousel-wrapper {
    margin: 0;
    width: 100%;

    .carousel-stepper {
      margin-top: 8px;
    }

    .carousel-view {
      display: block;

      .carousel-item-view {
        background-color: transparent;
        box-shadow: none;

        &::after {
          content: none;
        }

        .item-carousel-superscript {
          margin-bottom: 16px;

          &>span {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            color: $white;
          }
        }

        .carousel-alias-container {
          max-width: none;
          font-size: 16px;
          line-height: 24px;

          &>span {
            color: $white;
          }
        }
      }

      .scopri-di-piu {
        display: table;

        &-button {
          margin-left: 0;
          margin-right: auto;

          label {
            color: $white;
          }
        }
      }
    }

    .carousel-stepper {
      .stepper-step {
        &:not(.stepper-selected-step) {
          &::after {
            background-color: $darker-grey !important;
          }
        }
      }
    }

    .BrainhubCarousel__custom-arrowRight,
    .BrainhubCarousel__custom-arrowLeft {
      .carousel-button-arrow {
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
      }
    }

    .BrainhubCarousel__arrow--disable {
      .carousel-button-arrow {
        opacity: 0.3;
        cursor: inherit;
      }
    }
  }

  .stepper-unselected-step {
    background-color: $light-grey !important;
  }

  .txtAlias-item-carousel {
    font-weight: normal !important;
    color: $white !important;
  }
}