.titolo-denuncia {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin-top: 20px;
  color: var(--color-sparkasse-asphalt);
}

.text-denuncia {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $darkest-grey;
  margin-top: 8px;
}

.acordion-polizze {
  .accordion-title-tab {
    margin-left: 4px;
  }

  .accordion-body>.accordion-row {
    padding: 0;
  }

  .central-step2 {
    margin-bottom: 18px;
  }
}

.gestioni-button-label {
  .button-text {
    label {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
    }
  }
}

.link-gestione {
  text-decoration: none;
  color: $black;

  &:hover {
    text-decoration: none;
    color: $black;
    cursor: pointer;
  }
}

.messagio-gestione {
  text-align: center;
  margin-top: 50px;
}

.margin-motivi-box {
  margin-bottom: 16px;
  margin-left: 2px;
}

.margin-icon-box {
  margin-left: 8px;
}

.margin-left-side-text {
  margin-top: 16px;
}