.action-box {
  color: $white;
  background-color: $white;
  margin-left: 10px;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 8px;
  -webkit-box-shadow: $medium-shadow;
  -moz-box-shadow: $medium-shadow;
  box-shadow: $medium-shadow;
  transition: box-shadow background-color 0.2s ease-in-out;
  cursor: pointer;

  div {
    flex-grow: 0 !important;
    height: 100%;
    min-width: 52px;

    img {
      height: 44px !important; // per sovrascrivere tutti gli stili. TODO Eliminare quando eliminati gli stili
    }
  }
}

.action-box:hover {
  box-shadow: 0 6px 16px 2px rgba(0, 0, 0, 0.16);
  background-color: $light-grey;
}