.modal-personalizza-home {
  &-superwrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    max-height: calc(100vh - 138px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 853px;
    height: 100%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

    &-row {
      display: flex;
      flex-direction: row;

      &-logo {
        min-width: 54px;
        width: 54px;
        min-height: 46px;
        background-color: $red-monza;
      }

      &-menu {
        min-width: 54px;
        width: 54px;
        background-color: $asphalt;
      }

      &-widgets-area {
        background-color: $light-grey;
        width: 100%;
        padding: 26px;
      }
    }
  }

  .modal-dialog {
    max-width: 1260px;
  }

  .modal-content {
    border-radius: 0;

    .modal-title {
      font-family: inherit;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $darkest-grey;
      margin-left: 26px;
      margin-top: 8px;
    }

    .modal-body {
      padding: 0;
      font-family: inherit;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $darkest-grey;

      .lista-widgets-container {
        height: 100%;
        width: 100%;
        max-width: 343px;
        padding: 8px 0 8px 12px;

        &-section-title {
          font-size: 20px;
          font-weight: bold;
          line-height: 1.2;
          max-width: 343px;
          margin-left: 8px;
        }

        &-section-description {
          font-size: 16px;
          line-height: 1.43;
          margin-top: 8px;
          max-width: 343px;
          margin-left: 8px;
        }

        .lista-widgets {
          margin-top: 16px;
          margin-left: 8px;

          .home-widget {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
          }
        }
      }

      .home-widgets-grid {
        min-height: 814px;
      }

      .home-widget {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 124px;
        max-height: 124px;
        max-width: 360px;
        overflow: hidden;
        text-align: left;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);

        &:hover {
          &.react-draggable {
            cursor: grab;

            &.react-draggable-dragging {
              cursor: grabbing;
            }
          }
        }

        .desc-item-home-widget {
          flex: 1;
          margin-top: 16px;
          height: 100%;
        }

        .text-item-home-widget {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin: 4px 0 0 0;
          max-height: 80px;
          font-weight: normal;
          font-size: 16px;
          line-height: 1.14;
          color: $dark-grey-accessibility;
          width: 100%;
          word-break: break-word
        }

        .title-item-home-widget {
          margin: 0 0 4px 0;
          font-size: 16px;
          max-height: 35px;
          max-width: 216px;
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .icon-item-home-widget {
          margin: 8px;
        }

        .icon-draggable {
          width: 24px;
          color: $light-grey;
        }

        .cta-item-home-widget {
          width: 44px;
          min-width: 44px;
          background-color: $light-grey;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &-clickable:hover {
            cursor: pointer;
          }

          svg {
            fill: $red-monza;
            width: 44px;
            height: 44px;
          }
        }
      }

      .home-widget.home-widget-disabled {
        background-color: $light-grey;

        .title-item-home-widget {
          color: $dark-grey-bottom;
        }

        .icon-draggable {
          visibility: hidden;
        }

        svg {
          color: $dark-grey-bottom;
          fill: $dark-grey-bottom;
        }
      }
    }

    .modal-footer {
      border: none;

      &.justify {
        justify-content: flex-end;
      }

      .modal-preferiti-modal__close-button {
        width: 220px;
        height: 48px;
        padding: 8px 0;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.4px;
        font-family: inherit;
        background: inherit;
        outline-style: none;

        &.left {
          color: $asphalt;
          border: none;
        }

        &.right {
          color: #e2001a;
          border: 2px solid #e2001a;
        }

        &.button-disable {
          opacity: 0.5;
        }
      }
    }
  }
}

.modal-personalizza-preferiti {
  height: 44px;
  display: flex;
  justify-content: space-between;

  &-item {
    width: 118px;
    height: 44px;
    background-color: $asphalt;
    opacity: 0.4;
  }
}

/*
  Comportamento CTA
*/
.no-drag-n-drop,
.lista-widgets {
  .icon-draggable {
    visibility: hidden;
  }
}

.home-widget:hover {
  .icon-draggable {
    color: $red-monza;
    fill: $red-monza;
  }
}

.lista-widgets-container-disabilita-cta {
  .cta-item-home-widget {
    display: none !important;
  }
}

/* 
  Placeholders 
*/
.modal-personalizza-bar-slim {
  margin-left: 16px;
  margin-top: 8px;
  min-height: 10px;
  max-height: 10px;
}

.modal-personalizza-bar-medium {
  margin-left: 16px;
  margin-top: 8px;
  min-height: 27px;
  max-height: 27px;
  background-color: $asphalt;
  opacity: 0.2;
  min-width: 288px;
  max-width: 288px;
}

.modal-personalizza-bar-fat {
  margin-top: 16px;
  min-height: 79px;
  max-height: 79px;
}

.modal-personalizza-bar-shortest {
  background-color: $asphalt;
  opacity: 0.2;
  min-width: 127px;
  max-width: 127px;
}

.modal-personalizza-bar-shorter {
  background-color: $asphalt;
  opacity: 0.4;
  min-width: 150px;
  max-width: 150px;
}

.modal-personalizza-bar-short {
  background-color: $asphalt;
  opacity: 0.2;
  min-width: 181px;
  max-width: 181px;
}

.modal-personalizza-bar-long {
  background-color: $asphalt;
  opacity: 0.2;
  min-width: 352px;
  max-width: 352px;
}

.modal-personalizza-bar-longest {
  background-color: $asphalt;
  opacity: 0.2;
  min-width: 502px;
  max-width: 502px;
}

.modal-personalizza-bar-auto {
  background-color: $mercury;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

#static-placeholder-1 {
  min-height: 124px;
  max-height: 124px;
  min-width: 343px;
  max-width: 343px;
  background-color: $light-grey;
}

#static-placeholder-2 {
  min-height: 260px;
  max-height: 260px;
  min-width: 343px;
  max-width: 343px;
  background-color: $mercury;
}

#static-placeholder-3 {
  min-height: 124px;
  max-height: 124px;
  min-width: 714px;
  max-width: 714px;
  background-color: $light-grey;
  display: flex;
  flex-direction: row;
}

// Show responsive image for mobile width
.responsive-modal-image-blocker {
  display: none;
}

// Hide responsive image for desktop width 1280
@media (min-width: $breakpoint-desktop) {
  .responsive-modal-image-blocker {
    display: block;
  }
}

// Show personalizzaHome for desktop width
.change-modalCustom-responsive {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

// Show personalizzaHome for desktop width 1280
@media (min-width: $breakpoint-desktop) {
  .change-modalCustom-responsive {
    display: none;
  }
}

.responsive-image {
  max-width: 400px;
  width: 100%;
}

.responsive-text-header {
  margin: 16px 24px 0 24px;
  max-width: 336px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: $color-sparkasse-asphalt;
}

.responsive-text-subtitle {
  margin: 16px 24px 0 24px;
  max-width: 336px;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  color: $darkest-grey;
}

////////////////////////////////////////////////////////

// Show responsive image for mobile width
.responsive-640-image-blocker {
  display: none;
}

// Hide responsive image for desktop width
@media (min-width: $breakpoint-mobile-landscape) {
  .responsive-640-image-blocker {
    display: block;
  }
}

// Show personalizzaHome for desktop width
.change-modal-640-responsive {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// Show personalizzaHome for desktop width
@media (min-width: $breakpoint-mobile-landscape) {
  .change-modal-640-responsive {
    display: none;
  }
}