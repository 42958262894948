// import Colors from './generated/colors'

.generalView-snackbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  margin-top: 64px;
  right: 0;
  z-index: 9999999;
}

.viewBody-item-snackbar {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
}

.modalView-snackbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.containerView-snackbar {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  right: 0;
}

.view-counter {
  background-color: #df0025;
  height: 4px;
}

.view-item-snackbar {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  margin-bottom: 7px;
  width: 303px;
  min-height: 64px;
  background-color: #3f3f3f;
  box-shadow: $medium-shadow;
}

.txt-item-snackbar {
  flex: 1;
  color: #ffffff;
  margin: 20px 6px 18px 16px;
  font-size: 16px;
  line-height: 24px;
}

.iconView-item-snackbar {
  margin-top: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .button-close-snackbar {
    border: none;
    background-color: transparent;
  }
}