.oneClickView-item-oneClick {
  color: $black;
  background-color: $white;
  border-radius: 2px;
  margin: 7px;
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-content: center;
  align-items: center;
  // borderLeft: 2px solid red;
  // boxShadow: 1px 1px 1px 1px #9d9d9d;
}

.txtOneClick-item-oneClick {
  flex: 1;
  vertical-align: center;
  color: #343535;
  // fontWeight: bold;
  margin: 10px;
  text-align: start;
}

.iconView-item-oneClick {
  // flex: 0.1;
  border: 1px solid $red-monza;
  margin-top: 0;
  // marginLeft: 15;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: $red-monza;
}

.oneClickView-item-oneClick:hover {
  .iconView-item-oneClick {
    background-color: $bright-red;
  }
}