.input-control {
  // min-height: 100px;
  // margin-bottom: 16px;

  .input-control-error {
    color: $bright-red;
    font-size: 16px;
    margin-top: -15px;
    padding: 8px;
    line-height: 16px;
  }
}

.input-control.input-control-currency,
.input-control.input-control-selection {
  img {
    height: 40px;
    position: absolute;
    top: 15px;
    right: 0;
  }

  svg.selection-icon {
    position: absolute;
    right: 0;
  }

  img.form-field__icon {
    top: 0px;
  }
}

.input-control.input-control-multiselect,
.input-control.input-control-selection {
  select {
    text-overflow: ellipsis;
    border-radius: 0;
  }

  img,
  svg.selection-icon {
    pointer-events: none;
    top: 0px;
  }
}

.input-control.input-control-checkbox {
  display: flex;
  flex-direction: row;
  flex: 1;

  &--box {
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    border: 0.5px solid $darkest-grey;
    background-color: $red-monza;

    img {
      height: 25px;
      margin-left: -3px;
      margin-top: -5px;
      flex: 0.5;
    }
  }

  &--box.disabled {
    background-color: $dark-grey-bottom;
  }

  label {
    margin-left: 10px;
    color: $black;
  }

  label.disabled {
    color: $dark-grey-accessibility;
  }
}

.input-control.input-control-iban {
  .info-tooltip-trigger {
    border: none;
    background-color: transparent;
    cursor: default;
  }

  &--image-info {
    height: 35px;
    margin-top: 15px;
    cursor: pointer;
  }

  &--image {
    height: 40px;
    position: absolute;
    top: 15px;
    right: 0;
  }
}

.small-option-button-unselected {
  -webkit-box-shadow: $light-shadow;
  -moz-box-shadow: $light-shadow;
  box-shadow: $light-shadow;
}

.small-option-button-selected {
  background-color: #f3f3f3;
}

/* formatta option nell'input selection*/
.format-select:after {
  display: block;
  height: 15px;
  width: 25px;
  color: #464a4c;
  position: absolute;
  right: 23px;
  bottom: 15px;
  text-align: center;
  pointer-events: none;
}

.bg-color-white {
  background-color: $white;
}