.body-widget-bilancio {
  width: 100%;
  // height: 60;
  display: flex;
  flex-direction: column; // justify-content: center;
}

.txtView-widget-bilancio {
  flex-grow: 1;
  // margin: 15;
  // alignItems: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.titleChartView-widget-bilancio {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.txtAmountChart-widget-bilancio {
  margin-bottom: 8px;
  font-size: $secondary-font-size;
  font-weight: $section-font-weight;
}

.txtTitleChart-widget-bilancio {
  margin-bottom: 16px;
  font-size: $section-font-size;
}

.txtDescription-widget-bilancio {
  // flex: 1;
  margin-left: 10px;
  margin-top: -10px;
}

.legendView1-widget-bilancio {
  display: flex;
  flex-direction: column;

  div+div {
    margin-top: 8px;
  }

  @media (min-width: $breakpoint-mobile-landscape) {
    flex-direction: row;

    div+div {
      margin-top: 0;
    }

    .legendView1col2-widget-bilancio {
      justify-content: flex-end;
    }
  }

  p {
    margin: 0;
  }
}

.legendView1col1-widget-bilancio {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.legendView1col2-widget-bilancio {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.txtTitleLegend-widget-bilancio {
  display: flex;
  margin-left: 8px !important;
  font-size: $section-font-size;
}

.txtAmountLegend-widget-bilancio {
  display: flex;
  margin-bottom: 0;
  font-size: $section-font-size;
  font-weight: $section-font-weight;
  text-align: end;
}

.barView-widget-bilancio {
  flex-direction: row;
  display: flex;
  margin: 8px 0;
  max-height: 50px;
  background-color: $white;

  &.is-chart-empty {
    background-color: $light-grey;
  }
}

.movements-wrapper {
  position: relative;
  max-height: 390px;
  overflow-y: scroll;
  margin-top: 10px;
}

.movements-wrapper::-webkit-scrollbar {
  display: none;
}