.carta-credito-rightBar-button {
  align-items: flex-start;
  display: flex;
  margin: 10px;
}

.nexi-list {
  .card-view--body-view--account {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    word-break: break-word;
  }
}

.cobadge-rightBar-container {
  display: flex;
  flex-direction: column;
  margin: 10px;

  .cobadge-rightBar-box {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    -webkit-box-shadow: $light-shadow-bottom;
    -moz-box-shadow: $light-shadow-bottom;
    box-shadow: $light-shadow-bottom;

    .image-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0.5rem 0;
      background-color: $light-grey;
      overflow: hidden;
    }

    img {
      width: 120px;
      rotate: -10deg;
      margin: 10px 0 0 10px;
    }
  }

}
