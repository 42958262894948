.mifid-accordion {
  .accordion-title-text {
    color: $dark-grey-accessibility;
    transition: all 0.2s;
  }

  &:not(.accordion-open) {
    .accordion-title-text {
      margin-left: 8px;
    }
  }

  .accordion-row {
    .riepilogo {
      margin: -32px -10px 16px -14px;
    }
  }
}

.mifid-graphs {
  margin: 24px 0 32px 0;

  .mifid-dash-graph {
    display: flex;
    flex-direction: row;
    margin: auto 0 4px 0;
    padding: 8px 0;
    height: 4px;

    .mifid-dash-graph-element {
      width: 20px;
      height: 5px;
      background-color: $red-monza;
    }

    .mifid-dash-graph-element:not(:last-child) {
      margin-right: 4px;
    }

    .mifid-dash-graph-element-off {
      opacity: 0.4;
    }
  }
}

.question-wrapper {
  margin: 24px 0 32px 0;
  padding: 16px;

  box-shadow: $light-shadow-bottom;
}

.question-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &> :first-child {
    display: flex;
    flex-direction: column;
  }

  .page-subtitle {
    margin-bottom: 0;
  }

  .page-note {
    margin-top: 8px;
  }

  .question-help {
    margin-left: 16px;
  }
}

.question-form {
  display: flex;
  margin-top: 24px;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
  }

  &>* {
    flex: 1;
  }

  .question-form-container {
    display: flex;
    flex-direction: column;

    &+.question-form-container {
      margin-top: 24px;

      @media (min-width: $breakpoint-tablet) {
        margin-top: 0;
      }
    }

    .question-form-title {
      margin-bottom: 16px;
    }
  }

  .img-wrapper {
    margin: 16px 0;

    img {
      width: 100%;

      @media (min-width: $breakpoint-mobile-landscape) {
        padding-left: 70px;
      }
    }
  }

  .radio-button-box {
    margin: 0;

    .riepilogo-value {
      margin: 8px -16px;
      padding: 0 16px 8px 48px;
      width: calc(100% + 32px);
      background: $light-grey-alternative;

      @media (min-width: $breakpoint-mobile-landscape) {
        display: none;
      }
    }

    .riepilogo-column-value {
      display: none;
      flex: 1;
      text-align: right;

      @media (min-width: $breakpoint-mobile-landscape) {
        display: block;
      }
    }

    .pt-2 {
      padding-top: 0 !important;
    }

    .description-listino {
      line-height: 24px;
      vertical-align: middle;
    }

    .container-listino {
      position: relative;

      &.profilo::after {
        content: "";
        position: absolute;
        top: 11px;
        right: 0px;
        width: 16px;
        height: 2px;
        background-color: $darker-grey;
      }

      &.profilo-a::after {
        background-color: $graph-profile-a;
      }

      &.profilo-b::after {
        background-color: $graph-profile-b;
      }

      &.profilo-c::after {
        background-color: $graph-profile-c;
      }

      &.profilo-d::after {
        background-color: $graph-profile-d;
      }

      &.profilo-e::after {
        background-color: $graph-profile-e;
      }
    }
  }

  .input-box {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .perc-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $breakpoint-mobile-landscape) {
      flex-direction: row;
      align-items: center;
    }

    &>span {
      margin-right: 16px;
    }
  }
}