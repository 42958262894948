.pct-messaggio {
  margin-top: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $darkest-grey;
}

.pct-margin {
  margin-left: 15px
}

.card-margin-bottom {
  margin-bottom: 20px;
}

.button-pct {
  margin: 0;
}

.pct-icon {
  margin-left: 0 !important;
  padding-left: 0 !important;

}