.align-dashboard-buttons {
  //display: flex;
  //flex-direction: row;
  //justify-content: flex-end;

  div {
    display: flex;
    flex-direction: row;

    &+div {
      position: relative;
      margin-left: 16px;

      &::before {
        content: "";
        position: absolute;
        top: 6px;
        left: -8px;
        width: 1px;
        height: 32px;
        background-color: $dark-grey-bottom;
      }
    }
  }
}

.accordion-title-tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  min-height: 50px;
  margin: 16px 0;

  .button-1 {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  .button-2 {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: flex-end;
  }
}

.chart-container {
  margin-bottom: 15px;

  h3 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $dark-grey-accessibility;
    text-transform: uppercase;
    padding: 15px;
  }

  .widget-mutui {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: auto;
    margin-right: auto;
  }

  .pie-chart-container {
    margin-left: -15px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .bar-chart-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .spaced-text {
      padding: 0 20px;
    }
  }

  .space-between {
    justify-content: space-between;
  }

  .table-container {
    margin-top: 20px;

    .divisa {
      font-size: 1rem;
      font-weight: normal;
      align-self: flex-end;
      padding: 2px 5px;
    }
  }

  .custom-tooltip {
    display: flex;
    /* establish flex container */
    flex-direction: column;
    /* make main axis vertical */
    justify-content: center;
    /* center items vertically, in this case */
    align-items: left;
    background: $asphalt;
    padding: 10px;
  }

  .custom-tooltip>span {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $white;
  }
}

.sspk-red-icon {
  color: $red-not-active;
  fill: $red-not-active;
}

.prossima-rata {
  display: flex;
  flex-direction: column;

  span {
    &:nth-child(1) {
      font-size: 16px;
      font-weight: 300;
    }

    &:nth-child(2) {
      font-size: 24px;
      font-weight: bold;
    }

    &:nth-child(3) {
      span {
        &:nth-child(1) {
          font-size: 18px;
          font-weight: normal;
        }

        &:nth-child(2) {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }
}