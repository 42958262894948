.card-view--body-view--beneficiario {
  display: flex;
  flex-direction: column;
  flex: 3;
}

.card-view--body-view--iban {
  display: flex;
  flex-direction: column;
  flex: 5 4;
}

.card-view--body-view--spaced {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.filtra-bozze-button {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: column;
    flex-direction: row;
    justify-content: space-between;
  }

  .general-button {
    background-color: inherit !important;
    min-height: 50px;

    p {
      color: inherit !important;
    }
  }

  .filtra-bozze-sort {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;
  }

  .image-filtri-bozze {
    fill: $red;
    color: $red;
  }

  .reset-filtri-bozze {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;

    @media (min-width: $breakpoint-tablet) {
      margin-right: 16px;
    }
  }

  .reset-filtri-bozze-disable {
    color: $dark-grey-bottom;
  }
}

.card-bozze-wrapper {
  // overflow: hidden;
  box-shadow: $medium-shadow;
  max-width: calc(100vw - 32px);
}

.no-deleted-item {
  display: none !important;
}

.card-bozze-viewer {
  display: flex;
  width: calc(200% + 48px);

  flex: 0 0 100%;

  &>div:nth-child(2) {
    color: $white;
    font-weight: 400;
    display: flex;
    justify-content: center;

    align-items: center;
    width: 50%;

    background: rgba(0, 0, 0, 0.85) !important;
    flex-direction: row;
    flex-wrap: wrap;

    &>span {
      flex-grow: 1;
      padding-left: 48px;
    }

    &>div {
      text-align: center;
      text-transform: uppercase;
      padding-right: 24px;

      &>span {
        margin-left: 30px;

        &:hover {
          cursor: pointer;
          color: $red-monza;
        }
      }
    }
  }

  &>.deletedItem {
    width: 50% !important;
    transition: all 0.3s;
    margin-left: -50% !important;
    position: relative;
    background: rgba(0, 0, 0, 0.85) !important;
    flex-direction: row;
    flex-wrap: wrap;
    display: none;

    &>span {
      flex-grow: 1;
      padding-left: 48px;
    }

    &>div {
      text-align: center;
      text-transform: uppercase;
      padding-right: 24px;

      &>span,
      &>button {
        margin-left: 30px;
        color: white;

        &:hover,
        &:focus {
          cursor: pointer;
          color: $red-monza;
        }
      }
    }
  }

  &>div {
    flex-grow: 0;
    transition: background-color 0.3s ease-in-out;
    width: calc(50% - 24px);
  }
}

.movements-list-home {
  .card-bozze-wrapper {
    box-shadow: $light-shadow;

    &:not(:first-child) {
      margin-top: 4px;
    }
  }
}

.movements-clickable {
  .card-bozze-wrapper {
    cursor: pointer;
  }

  .card-bozze-wrapper {
    transition: box-shadow, background-color 0.2s ease-in-out;
  }

  .card-bozze-wrapper:hover {
    background-color: $light-grey;
    -webkit-box-shadow: $action-box-hover;
    -moz-box-shadow: $action-box-hover;
    box-shadow: $action-box-hover;
  }
}