.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  background: $black;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-top: 8px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 11px;
  border-radius: 50%;
  background: $color-sparkasse-monza;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 12px;
  height: 11px;
  border-radius: 50%;
  background: $color-sparkasse-monza;
  cursor: pointer;
}

.valuta-range {
  margin-left: auto;
  margin-right: 1%;
  padding-top: 2%;
  font-size: 16px;
}

.valuta-range-pct {
  margin-left: auto;
  margin-right: 1%;
  // padding-top: 6%;
  padding-top: 2%;
  font-size: 16px;
  padding-bottom: 15%;
}

.rangeFido {
  .valuta-range {
    margin-left: auto;
    margin-right: 0%;
    padding-top: 6%;
    font-size: 16px;
  }
}