.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.f-grow-3 {
  display: flex;
  flex-direction: column;
  flex: 3 1;
}

.f-grow-large {
  display: flex;
  flex-direction: column;
  flex: 5 1;
}

.f-grow-2 {
  display: flex;
  flex-direction: column;
  flex: 2 1;
}

.f-grow-1 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.align-left {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.flex-dir-col {
  flex-direction: column;
}

.header-bar {
  border-bottom: solid 1px #979797;
  padding: 12px 0;
}

.information-bar {
  border-bottom: solid 1px $medium-dark-grey;
  padding: 12px 0;
  align-items: center;
}

.header-text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: $darkest-grey;
}

.iban-text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $darkest-grey;
}

.generic-info-text {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $darkest-grey;
}

.flex-align-center {
  align-items: center;
}

.codice-categoria-text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $dark-grey-accessibility;
}

.line3Col3-step1 {
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 50%;

  @media (max-width: 760px) {
    flex-direction: column;
    min-width: 100%;
  }
}

.rapporti-bank-img {
  width: 44px !important;
  height: 44px !important;
  margin: 9px 8px 8px 16px;
}

.rapporti-alert-row {
  background-color: $light-grey;
}

.rapporti-utente-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 8px;

  @media (min-width: $breakpoint-mobile-landscape) {
    flex-direction: row;
  }
}

.alert-box {
  display: flex;
  flex-direction: row;
  margin: 16px;
  background-color: $white;
  box-shadow: $medium-shadow;

  &.clickable {
    &:hover {
      cursor: pointer;
    }
  }

  .alert-box-icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 14px;
    background-color: $mercury;
  }

  .alert-box-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 44px 8px 16px;

    .alert-box-description-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: $darkest-grey;
    }

    .alert-box-description-subtitle {
      font-size: 16px;
      line-height: 20px;
      color: $darkest-grey;
    }
  }

  .alert-box-arrow-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 8px 0 0;
  }
}

.help-text {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: $darkest-grey;
  margin-bottom: 16px;
}