.tour-contestuale-modal {
  padding: 0 !important;

  .modal-dialog {
    margin: 8px auto;
    width: calc(100% - 16px);
    max-width: 816px;

    .modal-content {
      border-radius: 0;
      border: none;

      .modal-header {
        border: none;
        background-color: $asphalt;
        color: $white;
        border-radius: 0;

        .modal-title {
          color: $white;
          font-family: inherit;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          margin: auto;
        }
      }

      .modal-body {
        .tour-slide {
          width: 100%;

          .tour-slide-container-image-wrapper {
            .tour-slide-container-image {
              width: 100%;
            }
          }

          .tour-slide-container-video-wrapper {
            .tour-slide-container-video {
              width: 100%;
              height: 370px;
            }
          }

          .tour-slide-container-text {
            margin-top: 16px;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: $darkest-grey;
          }
        }
      }

      .modal-footer {
        display: flex;
        flex-direction: column;
        padding: 16px;
        border: none;
        background-color: $light-grey;

        &>* {
          margin: 0;
        }

        .checkBox {
          margin-left: 8px;
          align-self: flex-start;

          &>.input-box {
            margin: 0;
          }
        }

        .pulsanti {
          display: flex;
          flex-direction: column;
          align-self: flex-end;
          margin-top: 16px;
          width: 100%;

          &>button {
            min-width: 100%;
          }
        }

        @media (min-width: $breakpoint-mobile-landscape) {
          .pulsanti {
            flex-direction: row;

            &>button {
              min-width: 50%;
            }
          }
        }

        @media (min-width: $breakpoint-tablet) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          align-self: unset;

          .checkBox {
            align-self: unset;
          }

          .pulsanti {
            margin-top: unset;
            width: unset;

            &>button {
              min-width: inherit;
            }
          }
        }

        .modal-preferiti-modal__close-button {
          width: 220px;
          height: 48px;
          padding: 8px 0;
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.4px;
          font-family: inherit;
          background: inherit;
          outline-style: none;

          &.left {
            color: $asphalt;
            border: none;
          }

          &.right {
            color: $white;
            border: none;
            background-color: $color-sparkasse-monza;
          }

          &.end {
            color: $white;
            border: none;
            background-color: $color-sparkasse-asphalt;
          }

          &.button-disable {
            opacity: 0.5;
          }
        }
      }
    }
  }
}