.content-main-section {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  &--core {
    display: flex;
    flex-direction: column;
    padding: 16px 16px; // (*) usato in tutte le pagine, non solo home
    max-width: 100%;
  }

  &--right-bar {
    &-small-title {
      color: #b0b0b0;
      font-size: 20px;
      font-weight: bold;
      margin: 16px 0;
    }

    &-small-title-black {
      color: $darkest-grey;
      font-size: 20px;
      font-weight: bold;
      margin: 16px 0;
    }

    &-small-title.small-title-highlight {
      color: $darkest-grey;
    }

    &-small-description {
      margin: 10px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $darkest-grey;
    }

    &-small-caption {
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
    }

    &-small-button {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
    }

    &-small-value {
      // color: #525252;
      margin-left: 10px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: $darkest-grey;
    }

    &-value {
      color: #525252; //Styles.secondary.color;
      font-size: 16px; //Styles.section.fontSize;
      font-weight: bold;
      margin-left: 10px;
    }

    &-small-description-bold {
      font-weight: 500;
    }
  }
}

.one-click {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 0 solid $dark-grey-bottom;
  }
}

.ultimo-accesso {
  display: flex;
  position: relative;
  justify-content: end;
  padding-bottom: 10px;
}

.home-top-section {
  .one-click {
    border-bottom-width: 1px;
  }
}

.content-main-banner-view {
  display: flex;
  flex-direction: column;
  box-shadow: $heavy-shadow;
  margin-top: 24px;

  &.home-banner-empty {
    display: none;
    margin-bottom: 0;
  }

  .banner-icon-view {
    min-width: 75px;
    background-color: rgba(246, 246, 246, 1) !important;
  }
}

.widgets-grid-container {
  margin-top: 24px;

  .widgets-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: 100%;
    padding-bottom: 16px;

    .widget-wrapper {
      border: 0 solid transparent;
      background-clip: padding-box;
      border-width: 0 0 16px 0;

      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          grid-row: $i;
          -ms-grid-row: $i;
        }
      }
    }

    @media (min-width: $breakpoint-tablet-landscape) {
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;

      .widget-wrapper {
        &:nth-child(odd) {
          -ms-grid-column: 1;
          grid-column: 1;
          border-width: 0 8px 16px 0;
        }

        &:nth-child(even) {
          -ms-grid-column: 2;
          grid-column: 2;
          border-width: 0 0 16px 8px;
        }

        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            grid-row: ceil($i/2);
            -ms-grid-row: ceil($i/2);
          }
        }
      }
    }
  }
}

.one-click-switch-button {
  position: relative;
  margin-left: auto;
  height: 0;
  top: -10px;

  @media (min-width: $breakpoint-desktop) {
    display: none;
  }
}

.btn-edit-widgets {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &>button {
    position: relative;
    width: 100%;
    padding: 10px;
    margin: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border: dashed 2px $asphalt;
    background-color: transparent;

    @media (min-width: $breakpoint-tablet) {
      width: 50%;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.home-top-section {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: 100%;
  min-height: 644px;

  .spalla-destra-inside-core {
    display: none;
  }

  .home-balance-and-widget {
    display: flex;
    flex-direction: column;
    -ms-grid-column: 1;
    grid-column: 1;
    border: 0 solid transparent;
    background-clip: padding-box;

    .widget-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    -ms-grid-columns: calc(100% - 368px) 368px;
    grid-template-columns: calc(100% - 368px) 368px;

    .spalla-destra-inside-core {
      display: inherit;
    }

    .home-balance-and-widget {
      border-width: 0 32px 0 0;
    }
  }
}

.widget {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $white;
  box-shadow: $heavy-shadow;
  padding: 16px;

  .widget-title {
    max-height: 30px;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: bold;
  }

  .form-field__control_selection {
    width: auto;

    select.form-field__input {
      border-bottom: none;
      font-weight: bold;
      padding: 12px 42px 8px 8px;
    }
  }

  .card-view--body-view--account {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.saldo-complessivo-value {
  font-size: $font-size-valore-saldo-complessivo;
  font-weight: bold;
  text-align: center;
  display: flex;
  vertical-align: center;
  align-items: baseline;
  justify-content: flex-end;
  line-height: 32px;
}

.saldo-positive-color {
  color: $color-saldo-complessivo-value;
}

.saldo-negative-color {
  color: $bright-red;
}

.saldo-disponibile-value {
  font-size: 18px; // variable TODO
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  align-items: baseline;
  line-height: 32px;
  display: flex;
}

.currency {
  font-size: 16px;
  font-weight: normal;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 10px;
  color: #505050;
}

.blur {
  filter: blur(6px);
  text-shadow: 5px 1px 1px;
  text-decoration: line-through;
  color: $black;
}

.nascondi-saldo {
  .is-saldo {
    filter: blur(6px);

    color: $black !important;
    text-decoration: line-through;
    text-shadow: 6px 0 6px;

    * {
      color: $black !important;
      text-decoration: line-through;
      text-shadow: 6px 0 6px;
    }
  }
}

.text-one-click {
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.text-one-click-description {
  color: $darkest-grey;
  font-size: 16px;
  font-weight: normal;
}

.oneclick-aggiungi {
  margin-top: 16px;
  justify-content: flex-end;
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  padding: 14px 64px;
  background-color: $light-grey;

  &-text {
    color: #525252;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.underline-short {
  display: block;
  width: 32px;
  height: 2px;
  background-color: $red-monza;
  margin-bottom: 16px;
}

.widget-vai-a-dettaglio {
  padding-top: 16px;
  margin-top: auto;
}

.altri-movimenti {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  label,
  img {
    cursor: pointer;
    display: flex;
    margin: auto;
  }
}

.altri-movimenti-disabled.altri-movimenti {
  display: none; // la richiesta e' di non vederlo
  cursor: default;
  pointer-events: none;
  color: $grey;
  opacity: 0.8;
}

/**************/

.header-home {
  display: flex;
  height: 70px;
  flex-direction: row;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: $white;
  box-shadow: 1px 1px 1px 1px #a9a9a9;
  z-index: 1; // justify-content: center;
}

.logoView-home {
  display: flex;
  flex: 3;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
}

.headerButton-home {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
}

.search-home {
  display: flex;
  flex-direction: row;
  height: 90px;
  // background-color: #000;
  align-items: center;
  // position: fixed;
  width: 100%;
  margin-left: 180px;
}

.menu-home {
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 100vh;
  background-color: #4a4a4a;
  position: fixed;
  // paddingTop: 15;
  // overflowY: 'scroll'
}

.menuExpanded-home {
  width: 200px;
}

.quick-operations-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;

  .quick-operations {
    display: none;
    width: 100%;

    @media (min-width: $breakpoint-desktop) {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
    }

    .quick-operation {
      @extend .action-box;

      border-left: 2px solid #343535;
      padding: 8px 12px 8px 6px;
      margin: 0;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }

    .quick-operation.quick-op-stile-alternativo {
      border-left: none;
      background-color: $light-grey-alternative;

      @media (min-width: $breakpoint-mobile-landscape) {
        display: inherit;
      }

      .txtWidgetView-item-quickOpr {
        font-weight: bold;
      }
    }
  }
}

.trigger-spalla-destra {
  display: block;
  margin-left: 24px;

  @media (min-width: $breakpoint-desktop) {
    display: none;
  }
}

.spalla-destra .quick-operations-container {
  justify-content: flex-start;

  .quick-operations {
    display: flex;
    flex-direction: column;

    .quick-operation {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

.bannerOnTop {
  flex-direction: column !important;

  .iconView {
    background: #f1f1f1;
  }
}

.iconView>img {
  background-color: #f1f1f1 !important;
}

.isDraggable {
  &:hover {
    cursor: grab !important;
  }

  &:active {
    cursor: grabbing !important;
  }
}

.uppercase {
  text-transform: uppercase;
}

// Remove default arrow on IE11
select::-ms-expand {
  display: none;
}

.tooltip-help-home {
  margin-left: 10px;
}

.info-tooltip-trigger {
  cursor: default !important;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
}