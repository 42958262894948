.storico-accordion {
  .accordion-header_text {
    margin-right: 0;
  }

  .accordion-header_text:last-child {
    margin-right: 60px;
  }

  .accordion-title-tab p {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $darkest-grey;
  }

}

.storico-riepilogo {
  .accordion-row {
    padding: 14px 10px 0px 14px;
    transition: all .2s;

    tr {
      border-bottom: none !important;
    }

    label {
      height: 16px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: $dark-grey-bottom;
      text-transform: uppercase;
    }

    td {
      padding: 0;
    }

    .input-control,
    .form-field {
      margin-bottom: 0;

    }
  }

  .accordion-row-resize {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    visibility: collapse;
    display: none;
  }

  .custom-table .table td>div {
    width: 100%;
    height: auto;
  }

  .custom-table .table thead th.tableHeadText:first-child {
    text-align: left;
  }

  .custom-table .table thead th.tableHeadText:last-child {
    text-align: center;
  }

  .custom-table .table thead th.tableHeadText {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $darkest-grey;
    text-align: right;
  }

  .tableInfoText td {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: right;
    color: $darkest-grey;

    p {
      margin-bottom: 0;
    }

    .infoTime {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $darkest-grey;
    }
  }

  .tableInfoText td:first-child {
    text-align: left;
  }
}