.movements-list {
  margin: 24px 16px 124px 16px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .card-bozze-wrapper:not(:first-child) {
    margin-top: 24px;
  }

  div:not(:first-child) {
    .card-bozze-wrapper {
      margin-top: 24px;
    }
  }
}

.card-view--body-minicontainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  padding-right: 8px;
  flex: auto;
}

.card-view--body-minibox {
  display: flex;
  flex-direction: row;
  flex: auto;

  @media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
    flex-direction: column;
  }
}

.card-view--body-minicontainer .spaced {
  padding: 5px 0;
}

.card-view-container {
  display: flex;
  flex-direction: column;
  min-height: 72px;

  &-corner {
    position: absolute;

    .movements-list {
      white-space: nowrap;
    }

    .card-view-container {
      display: flex;
      flex-direction: column;
      min-height: 72px;

      &-corner {
        position: absolute;
      }
    }

    .card-view--body-view--vertical-cta {
      background-color: $light-grey;
      margin-top: -4px !important;
      justify-content: center;
      max-width: 44px;
      height: 100%;

      .vertical-cta-items {
        justify-content: space-evenly;
        height: 100%;
        display: flex;
        flex-direction: column;

        .vertical-cta-item {
          cursor: pointer;
          flex: 1 1;
          display: flex;
          justify-content: center;
          width: 44px;

          img {
            width: 32px;
            height: 32px;
            margin: auto;
          }

          svg {
            margin: auto;
          }
        }

        .vertical-cta-item.vertical-cta-item-not-clickable {
          cursor: default;
        }

        .vertical-cta-item.vertical-cta-item-disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    .card-view-cta.card-view {
      width: auto;
      background-color: $light-grey;
      margin-bottom: 0;
      padding-left: 5px;
      padding-right: 5px;
      justify-content: space-between;

      @media (min-width: $breakpoint-tablet) {
        height: 44px;
      }

      img {
        width: 40px;
        height: 40px;
      }

      .card-view-cta--button {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;
        color: $black;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .card-view {
      margin-top: 13px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (min-width: $breakpoint-tablet) {
        height: 40px;
      }

      .icon-view {
        margin: 16px 4px 0 0;
        height: 44px;
        width: 44px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 0 0 44px;

        @media (min-width: $breakpoint-tablet) {
          margin: 0 0 0 16px;
        }

        img {
          fill: $black;
          color: $white;
        }
      }

      .icon-view-non-cont {
        margin: 70px 4px 0 0;
        height: 44px;
        width: 44px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 0 0 44px;

        @media (min-width: $breakpoint-tablet) {
          margin: 0 0 0 16px;
        }

        img {
          fill: $black;
          color: $white;
        }
      }

      .checkbox-view {
        margin: 16px 4px 0 0;
        height: 44px;
        width: 44px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 0 0 44px;

        @media (min-width: $breakpoint-tablet) {
          margin: 16px;
        }

        img {
          fill: $black;
          color: $white;
        }
      }

      &--body-view {
        display: flex;
        flex-direction: column;

        &--date {
          color: $dark-grey-accessibility;
          margin: 0;
          font-size: 16px;
          font-weight: normal;
          line-height: 20px;
          word-break: break-word;
        }

        &--additional-info-label {
          color: $dark-grey-accessibility;
          margin: 0;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.25;
          word-break: break-word;
        }

        &--date-on-right {
          color: $dark-grey-accessibility;
          margin: 0;
          font-size: 16px;
          font-weight: normal;
          margin-right: 10px;
          text-align: right;

          @media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
            display: none;
          }
        }

        &--description {
          color: $darkest-grey;
          font-size: 16px;
          font-weight: normal;
          line-height: 1;
          word-break: break-word;
        }

        &--additional-info-value {
          color: $black;
          font-size: 16px;
          font-weight: normal;
          word-break: break-word;

          @media (min-width: $breakpoint-tablet) {
            font-size: 16px;
          }
        }

        &--additional-info-value-on-right {
          color: $black;
          margin: 0;
          font-size: 16px;
          font-weight: normal;
          margin-right: 10px;
          text-align: right;
          line-height: 1.25;
        }

        &--account {
          color: $dark-grey-accessibility;
          font-size: 16px;
          line-height: 16px;
          font-weight: 300;
          white-space: pre-line;
        }

        &--import-view {
          flex-grow: 0.7;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end;
          margin-right: 10px;

          &--import {
            color: $black;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            display: flex;
            vertical-align: center;
            line-height: 1.33;
            letter-spacing: 0.45px;
          }

          &--currency {
            font-size: 16px;
            line-height: 20px;
            font-weight: normal;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-left: 10px;
            color: $bright-red;
            letter-spacing: 0.3px;
          }

          .outcoming {
            color: $bright-red;
          }

          .neutral {
            color: "#9d9d9d";
          }

          .incoming {
            color: $color-saldo-complessivo-value;
          }
        }

        &--import-estero-view {
          flex-grow: 0.7;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end;
          margin-right: 10px;

          &--import {
            color: #979797;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            display: flex;
            vertical-align: center;
            line-height: 1.33;
            letter-spacing: 0.45px;
          }

          &--currency {
            color: #979797;
            font-size: 16px;
            line-height: 20px;
            font-weight: normal;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-left: 10px;
            letter-spacing: 0.3px;
          }
        }
      }
    }

    .card-view-home {
      margin-top: 4px;
    }
  }
}

.txtAccount-item-card {
  text-align: right;
  margin-right: 10px;
}

.card-view--body-view--vertical-cta {
  background-color: $light-grey;
  margin-top: -4px !important;
  justify-content: center;
  max-width: 44px;

  .vertical-cta-items {
    justify-content: space-evenly;
    height: 100%;
    display: flex;
    flex-direction: column;

    .vertical-cta-item {
      cursor: pointer;
      flex: 1 1 50%;
      display: flex;
      justify-content: center;
      width: 44px;

      img {
        width: 32px;
        height: 32px;
        margin: auto;
      }

      svg {
        margin: auto;
      }
    }

    .vertical-cta-item.vertical-cta-item-not-clickable {
      cursor: default;
    }

    .vertical-cta-item.vertical-cta-item-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.card-view-cta.card-view {
  width: auto;
  background-color: $light-grey;
  margin-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-between;

  @media (min-width: $breakpoint-tablet) {
    height: 44px;
  }

  img {
    width: 40px;
    height: 40px;
  }

  .card-view-cta--button {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: $black;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.card-view-detail.card-view.card-view-detail-expanded {
  max-height: 500px;
  margin-top: 0;
}

.card-view-detail.card-view.card-view-detail-collapsed {
  max-height: 0;
  margin-top: -20px;

  @media (min-width: $breakpoint-tablet) {
    max-height: 500px;
    margin-top: 0;
  }
}

.card-view {
  margin-top: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: centercard-view--body-view;

  .icon-view {
    margin: 16px 4px 0 0;
    height: 44px;
    width: 44px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 0 0 44px;

    @media (min-width: $breakpoint-tablet) {
      margin: 16px;
    }

    img {
      fill: $black;
      color: $white;
    }
  }



  .checkbox-view {
    margin: 16px 4px 0 0;
    height: 44px;
    width: 44px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 0 0 44px;

    @media (min-width: $breakpoint-tablet) {
      margin: 0 0 0 16px;
    }

    img {
      fill: $black;
      color: $white;
    }
  }

  &--body-view--full-col {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &--body-view--full-row {
    display: flex;
    width: 100%;
    flex-direction: row;

    @media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
      flex-direction: column;
    }

    &--inner {
      display: flex;
      width: 100%;
      flex-direction: column;

      @media (min-width: $breakpoint-tablet) {
        flex-direction: row;
      }
    }
  }

  .flex11 {
    flex: 1 1;
  }

  &--body-view {
    display: flex;
    flex-direction: column;
    flex: 1;

    &--date {
      color: $dark-grey-accessibility;
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      word-break: break-word;
    }

    &--additional-info-label {
      color: $dark-grey-accessibility;
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      word-break: break-word;
    }

    &--date-on-right {
      color: $dark-grey-accessibility;
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      margin-right: 10px;
      text-align: right;

      @media (max-width: calc(#{$breakpoint-tablet} - 1px)) {
        display: none;
      }
    }

    &--description {
      color: $darkest-grey;
      font-size: 16px;
      font-weight: normal;
      line-height: 1;
      word-break: break-word;
    }

    &--additional-info-value {
      color: $black;
      font-size: 16px;
      font-weight: normal;
      word-break: break-word;

      @media (min-width: $breakpoint-tablet) {
        font-size: 16px;
      }
    }

    &--additional-info-value-on-right {
      color: $black;
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      margin-right: 10px;
      text-align: right;
      line-height: 1.25;
    }

    &--account {
      color: $dark-grey-accessibility;
      font-size: 16px;
      line-height: 16px;
      font-weight: 300;
      white-space: pre-line;
    }

    &--import-view {
      flex-grow: 0.7;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      margin-right: 10px;

      label {
        margin-bottom: 0;
      }

      &--import {
        color: $black;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        display: flex;
        vertical-align: center;
        line-height: 1.33;
        letter-spacing: 0.45px;
      }

      &--currency {
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-left: 10px;
        color: $black;
        letter-spacing: 0.3px;
      }

      .outcoming {
        color: $bright-red;
      }

      .neutral {
        color: #9d9d9d;
      }

      .incoming {
        color: $color-saldo-complessivo-value;
      }
    }

    &--import-estero-view {
      flex-grow: 0.7;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      margin-right: 10px;

      &--import {
        color: #979797;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        display: flex;
        vertical-align: center;
        line-height: 1.33;
        letter-spacing: 0.45px;
      }

      &--currency {
        color: #979797;
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-left: 10px;
        letter-spacing: 0.3px;
      }
    }
  }
}

.card-view-home {
  margin-top: 4px;
}

.outcoming {
  color: $bright-red;
}

.neutral {
  color: #9d9d9d;
}

.incoming {
  color: $color-saldo-complessivo-value;
}