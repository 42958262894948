.elimina-banner-container {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  height: 50px;

  margin-bottom: 40px;
  margin-top: 40px;

  background-color: #3f3f3f;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.elimina-banner-azioni {
  display: flex;
  flex: 1 1;
  align-items: flex-end;
  justify-content: flex-end;
  & > :first-child {
    padding-right: 5%;
    cursor: pointer;
  }
  & > :last-child {
    cursor: pointer;
    padding-left: 5%;
    padding-right: 10%;
  }

  button {
    border: none;
    background-color: transparent;
  }
}
