.date-picker-popover {
  width: 268px;
  border: 0;
  border-radius: 0 !important;
  box-shadow: $heavy-shadow;

  .arrow {
    display: none;
  }
}

.hide-day-picker {

  .rdp-head,
  .rdp-tbody,
  .DayPicker-Footer {
    display: none;
  }
}

.rdp-day_selected {
  color: $white;
  background-color: $red-monza !important;
}

.custom-day-picker {
  &.rdp {
    margin: unset;
    // .DayPicker-wrapper {
    position: relative;
    width: 268px;
    height: 100%;
    padding: 0 0 14px 0;
    --rdp-cell-size: 35px;

    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 52px;
      background-color: $red-monza;
    }

    .rdp-nav {
      padding: 0px !important;

      .rdp-nav_button {
        margin: 0;
        height: 24px;

        &.rdp-nav_button_previous {
          position: absolute;
          left: 16px;
          top: 12px;

          svg {
            color: #fff;
          }

          svg:hover {
            color: #ffffff89;
          }
        }

        &.rdp-nav_button_previous:hover {
          background-color: unset;
        }

        &.rdp-nav_button_next {
          position: absolute;
          right: 16px;
          top: 12px;

          svg {
            color: #fff;
          }

          svg:hover {
            color: #ffffff89;
          }
        }

        &.rdp-nav_button_next:hover {
          background-color: unset;
        }
      }
    }

    .DayPicker-MonthSelection-Header {
      display: table-header-group;

      .DayPicker-MonthSelection-Titles {
        display: table-row;

        .DayPicker-MonthSelection-Title {
          display: table-cell;
          text-align: center;
          padding: 0 0 4px 0;
          width: 32px;
          font-family: "Univers LT Std";
          color: $dark-grey-accessibility;
          font-size: 16px;
          line-height: 32px;
          font-weight: 300;

          abbr[title] {
            cursor: inherit;
            border-bottom: none;
            text-decoration: none;
          }
        }
      }
    }

    .DayPicker-MonthSelection-Body {
      display: table-row-group;

      .DayPicker-MonthSelection-MonthRow {
        display: table-row;

        .DayPicker-MonthSelection-Month {
          position: relative;
          display: table-cell;
          font-family: "Univers LT Std";
          color: $darkest-grey;
          font-size: 16px;
          line-height: 36px;
          font-weight: normal;
          border-radius: 0;
          vertical-align: middle;
          text-align: center;
          width: 68px;
          cursor: pointer;

          &:not(:last-child):not(:first-child) {
            width: 96px;
            border-left: 16px solid $white;
            border-right: 16px solid $white;
          }

          &:hover {
            background-color: $light-grey;
          }

          &.DayPicker-MonthSelection-Month--selected {
            color: $white;
            background-color: $red-monza !important;
          }

          &.DayPicker-MonthSelection-Month--disabled {
            cursor: not-allowed;
          }

          &.DayPicker-MonthSelection-Month--disabled:not(.DayPicker-MonthSelection-Month--nearSelected) {
            color: $dark-grey-accessibility;
          }

          &.DayPicker-MonthSelection-Month--today {
            color: $red-monza;
            font-weight: bold;
          }
        }

        &:not(:last-child) {
          .DayPicker-MonthSelection-Month {
            border-bottom: 22px solid $white;
          }
        }
      }

      &.DayPicker-MonthSelection-Body-WideRange {
        .DayPicker-MonthSelection-MonthRow {
          .DayPicker-MonthSelection-Month {
            &.DayPicker-MonthSelection-Month--selected {
              &:not(:first-child) {
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: -16px;
                  bottom: 0;
                  width: 16px;
                  background-color: rgba(223, 0, 37, 0.6);
                }
              }

              &:not(:last-child) {
                &::after {
                  content: "";
                  z-index: 1;
                  position: absolute;
                  top: 0;
                  right: -16px;
                  bottom: 0;
                  width: 16px;
                  background-color: rgba(223, 0, 37, 0.6);
                }
              }
            }

            &.DayPicker-MonthSelection-Month--nearSelected {
              color: $white;
              background-color: rgba(223, 0, 37, 0.6) !important;
            }
          }
        }
      }
    }

    .DayPicker-YearSelection-Header {
      display: table-header-group;

      .DayPicker-YearSelection-Titles {
        display: table-row;

        .DayPicker-YearSelection-Title {
          display: table-cell;
          text-align: center;
          padding: 0 0 4px 0;
          width: 32px;
          font-family: "Univers LT Std";
          color: $dark-grey-accessibility;
          font-size: 16px;
          line-height: 32px;
          font-weight: 300;

          abbr[title] {
            cursor: inherit;
            border-bottom: none;
            text-decoration: none;
          }
        }
      }
    }

    .DayPicker-YearSelection-Body {
      display: table-row-group;

      .DayPicker-YearSelection-YearRow {
        display: table-row;

        .DayPicker-YearSelection-Year {
          display: table-cell;
          font-family: "Univers LT Std";
          color: $darkest-grey;
          font-size: 16px;
          line-height: 36px;
          font-weight: normal;
          border-radius: 0;
          vertical-align: middle;
          text-align: center;
          width: 68px;
          cursor: pointer;

          &:hover {
            background-color: $light-grey;
          }

          &.DayPicker-YearSelection-Year--selected {
            color: $white;
            background-color: $red-monza !important;
          }

          &.DayPicker-YearSelection-Year--today {
            color: $red-monza;
            font-weight: bold;
          }
        }

        &:not(:last-child) {
          .DayPicker-YearSelection-Year {
            border-bottom: 4px solid $white;
            width: 250px;
          }
        }
      }
    }

    .rdp-months {
      width: 100%;
      justify-content: flex-start;

      .rdp-month {
        margin: 0 12px;
        flex: 1;
        border-collapse: separate;
        border-spacing: 0;
        justify-items: center;

        .rdp-caption {
          display: contents;
          margin: 0 0 8px 0;
          padding: 0 52px 0 46px;
          text-align: center;

          &>div {
            padding: 14px 28px;
            font-family: "Univers LT Std";
            color: $white;
            font-size: 16px;
            line-height: 20px;
            // background-color: $red-monza;

            // .DayPicker-NavButton {
            //   margin: 0;
            //   top: 14px;
            //   width: 24px;
            //   height: 30px;
            //   background-size: auto;

            // .DayPicker-NavButton--prev {
            //   position: absolute;
            //   width: 30px;
            //   height: 30px;
            //   right: auto;
            //   left: 16px;
            //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FFF'%3E%3Cg%3E%3Cpath d='M18 15.246L25.145 22.372 18.019 29.519 19.265 30.761 27.634 22.369 19.243 14z' transform='translate(-2 -2) translate(2 2) rotate(180 22.817 22.38)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            // }

            // .DayPicker-NavButton--next {
            //   left: auto;
            //   right: 16px;
            //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FFF'%3E%3Cg%3E%3Cpath d='M18.226 15.246L25.371 22.372 18.244 29.519 19.49 30.761 27.86 22.369 19.469 14z' transform='translate(-216 -2) translate(216 2)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            // }

            // // }

            .DayPicker-Caption-Month {
              font-weight: normal;

              &:focus-visible {
                outline: none;
              }

              &.general-button:hover {
                text-shadow: 0 1px 4px rgba(255, 255, 255, 0.5);
              }
            }

            .DayPicker-Caption-Year {
              font-weight: bold;

              &:focus-visible {
                outline: none;
              }

              &.general-button:hover {
                text-shadow: 0 1px 4px rgba(255, 255, 255, 0.5);
              }
            }

            .rdp-caption-label {
              font-weight: normal;

              &.general-button:hover {
                text-shadow: 0 1px 4px rgba(255, 255, 255, 0.5);
              }
            }

            .DayPicker-Caption-Year {
              font-weight: bold;

              &.general-button:hover {
                text-shadow: 0 1px 4px rgba(255, 255, 255, 0.5);
              }
            }
          }
        }

        .rdp-head {
          margin: 0;

          .rdp-head_row {
            .rdp-head_cell {
              padding: 1px;
              font-family: "Univers LT Std";
              color: $dark-grey-accessibility;
              font-size: 16px;
              line-height: 32px;
              font-weight: 300;

              abbr[title] {
                cursor: inherit;
              }
            }
          }
        }

        .rdp-tbody {
          .rdp-row {
            .rdp-day {
              padding: 0;
              width: 34px;
              font-family: "Univers LT Std";
              color: $darkest-grey;
              font-size: 16px;
              line-height: 32px;
              font-weight: normal;
              border-radius: 0;
              // border-top: 2px solid $white;
              // border-right: 2px solid $white;

              &.DayPicker-Day--outside {
                color: $dark-grey-accessibility;
                cursor: not-allowed;
              }

              &.rdp-day_disabled:not(.DayPicker-Day--outside) {
                color: $dark-grey-accessibility;
                cursor: not-allowed;
              }

              &.rdp-day_selected {
                color: $white;
                background-color: $red-monza !important;
              }

              &.rdp-day_today:not(.rdp-day_selected) {
                color: $red-monza;
                font-weight: bold;
              }

              &:not(.rdp-day_disabled):not(.rdp-day_selected):not(.DayPicker-Day--outside):hover {
                background-color: $light-grey;
              }
            }
          }
        }
      }
    }

    .DayPicker-Footer {
      padding: 8px 16px;
      text-align: right;

      .DayPicker-TodayButton {
        padding: 0;
        font-family: "Univers LT Std";
        color: $red-monza;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        letter-spacing: 0.4px;
      }
    }

    // }

    &.SelectingYears {
      // .DayPicker-wrapper {
      padding: 52px 0 0 0;

      .rdp-months {
        max-height: 260px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 0 14px 0;

        .rdp-month {
          .DayPicker-Caption {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
          }
        }
      }

      // }
    }
  }

  &.date-picker-hide-week-days {
    .rdp-head {
      display: none;
    }
  }

  &.date-picker-freezed-month {

    // .DayPicker-wrapper {
    .rdp-months {
      .rdp-month {
        .rdp-tbody {
          .rdp-row {
            .rdp-day.rdp-day_today:not(.rdp-day_selected) {
              color: #181818;
              font-weight: normal;
            }
          }
        }
      }
    }

    // }
  }
}

.sticky-calendar {
  .rdp {
    .date-picker-popover {
      &::before {
        background-color: #f3f3f3;
      }

      .rdp-months {
        .rdp-month {
          .DayPicker-Caption {
            .DayPicker-Caption-Wrapper {
              color: $darkest-grey;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  &.hide-days {
    .rdp {

      // .DayPicker-wrapper {
      .rdp-months {
        &:hover {
          box-shadow: 0 6px 16px 2px rgba(0, 0, 0, 0.16);
          cursor: pointer;
        }

        .rdp-month {
          .DayPicker-Caption {
            margin: 0;

            .DayPicker-Caption-Wrapper {
              color: $darkest-grey;
              background-color: transparent;
            }
          }

          .rdp-head,
          .rdp-tbody {
            display: none;
          }
        }
      }

      // }
    }

    .month-selected {
      .rdp {

        // .DayPicker-wrapper {
        &::before {
          background-color: $red-monza;
        }

        .rdp-months {
          .rdp-month {
            .DayPicker-Caption {
              .DayPicker-Caption-Wrapper {
                color: $white;
              }
            }
          }
        }

        // }
      }
    }
  }

  .month-disabled {
    pointer-events: none;
  }

  .month-unselected {
    .rdp {

      // .DayPicker-wrapper {
      .rdp-months {
        .rdp-month {
          .rdp-tbody {
            .rdp-day {
              &.rdp-day_selected {
                color: unset;
                background-color: unset !important;
              }

              &.DayPicker-Day--outside {
                cursor: default;
              }
            }
          }
        }
      }

      // }
    }
  }
}

.calendarWrapper {
  width: 100%;
  min-width: 316px;
  z-index: 999;
  position: relative;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.calendarNavBar {
  background-color: $red-monza !important;
  height: 48px !important;
  width: 100% !important;
}

.yearsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    background-color: inherit !important;
    border: none !important;
    height: fit-content;

    img {
      position: initial !important;
    }
  }
}

.arrow {
  position: absolute !important;
  top: 5px !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 24px;

  &.left {
    left: 12px !important;
  }

  &.right {
    right: 12px !important;
  }
}

// .arrow.left {
//   left: 12px !important;
// }

// .arrow.right {
//   right: 12px !important;
// }

.navbarYears {
  text-align: center;
  width: 33%;
  margin-top: 12px;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  color: white;
}

.calendarSeparator {
  margin-top: -10px;
  background-color: black;
}

.calendarColumnsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
}

.calendarColumn {
  padding: 1rem 0;
}

.calendarColumn:first-child {
  padding-right: 0.5rem;
}

.calendarColumn:last-child {
  padding-left: 0.5rem;
}

.monthWrapper {
  flex-wrap: wrap;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.month {
  width: 33%;
  min-height: 30px;
}

.monthText {
  text-align: center;
  margin: 5px;
}

.month.primary {
  background-color: $red-monza !important;
  color: $white;
}

.month.secondary {
  background-color: rgba(223, 0, 37, 0.6);
  color: $white;
}

.month.primary-over {
  background-color: $grey;
}

.month.secondary-over {
  background-color: $light-grey;
}

.month.disabled {
  color: $grey;
}

.date-picker-popover {
  width: 268px;
  border: 0;
  border-radius: 0 !important;
  box-shadow: $heavy-shadow;
}