.home-section-sca {
  display: flex;
  flex-direction: column;
}

.sca-waiting-icon {
  height: 44px;
  width: 44px;
  margin-left: auto;
  margin-right: auto;
}

.txtTitle-section-sca {
  color: $dark-grey-bottom;
  margin: 10px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.txtTitle-section-sca-step2 {
  color: $black;
  margin: 10px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal
}

.itemView-item-sca {
  border-radius: 2px;
  // margin: 7px;
  width: 100%;
  // height: 40px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin-top: 36px;
}

.itemView-item-sca-step2 {
  align-items: flex-start;
  padding-top: 30px;
}

.txtView-item-sca {
  display: flex;
  flex-direction: column;

}

.txtItem-item-sca {
  flex: 1;
  vertical-align: center;
  margin: 10px 0px 0px 10px;
  color: $dark-grey-bottom;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.txtItem-item-sca-step2 {
  flex: 1;
  vertical-align: center;
  margin: 10px 0px 0px 10px;
  color: $black;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.txtItem-item-sca-step2 {
  flex: 1;
  vertical-align: center;
  margin: 0px 0px 0px 10px;
  color: $black;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.titleItem-item-sca-step2 {
  font-weight: bold;
  margin: 0px 0px 10px 10px;
}

.iconView-item-sca {
  align-items: center;
  display: flex;
  flex-direction: column;
}