.spalla-destra-background {
  z-index: 1;
  position: fixed;
  top: $menu-topbar-height;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(63, 63, 63, 0.5);
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: $breakpoint-desktop) {
    z-index: 0;
  }

  @media (min-width: $breakpoint-tablet-landscape) {
    left: $menu-sidebar-width;
  }

  @media (min-width: $breakpoint-desktop) {
    display: none;
  }

  &.spalla-destra-chiusa {
    display: none;
  }
}

.spalla-destra-inside-core {
  -ms-grid-column: 2;
  grid-column: 2;
  padding: 16px;
  background-color: $color-sparkasse-concrete;
}

.spalla-destra {
  z-index: 1;
  position: fixed;
  top: $menu-topbar-height;
  right: 0;
  height: 100%;
  max-height: calc(100% - #{$menu-topbar-height});
  width: 100%;
  max-width: 368px;
  background-color: $color-sparkasse-concrete;
  overflow: hidden;
  -ms-grid-column: 2;
  grid-column: 2;

  @media (min-width: $breakpoint-desktop) {
    z-index: 0;
    position: relative;
    top: auto;
    right: auto;
    min-height: 100%;
  }

  &.spalla-destra-chiusa {
    display: none;

    @media (min-width: $breakpoint-desktop) {
      display: inherit;
    }
  }

  .close-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    @media (min-width: $breakpoint-desktop) {
      display: none;
    }

    .close-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

  }

  .general-button:focus {
    outline: none;
  }

  .general-button:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

}

.wide .spalla-destra {
  @media (min-width: $breakpoint-desktop) {
    display: none;
  }
}

.chiudi-spalla-destra {
  position: absolute;
  top: 24px;
  right: 16px;
  background-color: rgba($color: $color-sparkasse-concrete, $alpha: 0.85);
  border-radius: 24px;
  box-shadow: 0 2px 6px 0 $color-sparkasse-concrete;

  @media (min-width: $breakpoint-desktop) {
    display: none;
  }
}

.spalla-destra-content {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 68px 16px 32px 16px;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: $breakpoint-desktop) {
    padding: 32px 16px 32px 16px;
  }
}