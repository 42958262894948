.f-grow-4 {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.ml-36 {
  margin-left: 36px;
}

.f-grow-5 {
  display: flex;
  flex-direction: column;
  flex: 2 1;
}

.f-grow-6 {
  display: flex;
  flex-direction: column;
}

.revoca-button {
  margin-left: 40px;
  margin-top: 10px;
}

.psd2-list-container {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.psd2-dash {
  width: 8px;
  height: 3px;
  background-color: red;
  margin-right: 8px;
  margin-top: 2px;
}