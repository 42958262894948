.central-step2 {
  // border: 1px solid black;
  display: flex;
  // flex: 1;
  flex-direction: row;
  flex-grow: 1;
}

.main-step2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.captionView-step2 {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.txtCaption-step2 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $dark-grey-accessibility;
}

.line1-step2 {
  display: flex;
  flex-direction: row;
  // flex: 1;
  background-color: $light-grey;
  padding-bottom: 8px;
  // height: 80px;
  // padding-right: 12;
  // border: 1px solid black;
}

@media screen and (max-device-width: 768px) {
  .line1-step2 {
    flex-direction: column;
  }
}

.line1Col1-step2 {
  display: flex;
  flex-direction: column;
  // flex: 1;
  height: 45px;
  margin-right: 10px;
  // border: 1px solid black;
}

.line1Col1-step2-cutom {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 10px;
}

.line1Col1-step2-spaced {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 0px;
}

.line1Col1-step2-no-space {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 5px;
}

.line1Col1-step3-cutom {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 10px;
}

.line1Col1-step3-spaced {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 0px;
}

.line1Col1-step3-no-space {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 5px;
}

.line1Col2-step2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 45px;
  margin-left: 10px;
}

@media screen and (max-device-width: 768px) {
  .line1Col2-step2 {
    margin-left: 0;
  }
}

.section-step2 {
  display: flex;
  flex-direction: column;
  // flex: 1;
  background-color: $light-grey;
  padding-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
}


.line2-step2 {
  display: flex;
  flex-direction: row;
  // flex: 1;
  background-color: $light-grey;
  padding-bottom: 8px;
  // height: 80px;
  // border: 1px solid black;
}

@media screen and (max-device-width: 768px) {
  .line2-step2 {
    flex-direction: column;
  }
}

.line2Col1-step2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 45px;
  // margin-top: 16px;
  // height: 72;
  // border: 1px solid black;
}

.line1Col3-step2 {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 10px;
  height: 45px;
}

@media screen and (max-device-width: 768px) {
  .line1Col3-step2 {
    flex-direction: column;
    margin-left: 0;
  }
}

@media screen and (max-device-width: 768px) {
  .input-fido {
    padding-left: 0px !important;
  }
}


@media screen and (min-width: 1024px) {
  .input-fido {
    padding-left: 8px !important;
  }
}

@media screen and (min-width: 1024px) {
  .input-fido.accredito-banca {
    margin-top: 0;
  }
}

@media screen and (max-device-width: 768px) {
  .input-fido.accredito-banca {
    margin-top: 20px !important;
  }
}

@media screen and (max-device-width: 768px) {
  .half-width {
    width: 50% !important;
  }
}

@media screen and (min-width: 1024px) {
  .fido-input-row {
    flex-direction: row !important;
  }
}